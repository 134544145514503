import { BaseUser } from '@common/react/objects/BaseUser';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { pureEnum } from '@common/typescript/utils/enums';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Crematory, CrematoryListInfo } from '@app/objects/Crematory';
import { Clinic } from '@app/objects/Clinic';
import { LocaleUserPreferences } from '@app/objects/UserPreferences/LocaleUserPreferences';
import { DashboardViewType } from '@app/objects/Dashboard';
import { Permission } from '@app/objects/Permission';
import { CrematoryUserSpecialService, CrematoryUserStatus } from '@app/objects/CrematoryUser';

export enum UserRole {
	SystemService = -2,
	IntegrationService = -1,
	Admin = 1,
	Crematory = 2,
	ClinicUser = 3,
	CrematoryUser = 4,
	ClinicManager = 5,
}

export interface ClinicUserPreferences {
	dashboardType: Nullable<DashboardViewType>,
	id: number,
	user: null,
	userId: number,
	_type: string,
}

export interface UserCrematory extends WithDeleted {
	id: number;

	userId: number;
	user: Nullable<User>;

	crematoryId: number;
	crematory: Nullable<CrematoryListInfo>;
}

export interface UserPermission extends WithId, WithDeleted {
	permission: Permission;
	userId: number;
}

export interface User extends BaseUser
{
	/* --- General Section --- */
	role: UserRole;

	avatar: string;
	originalAvatar: string;
	/* --- End General Section --- */

	/* --- Preferences Section --- */
	localeUserPreferences: Nullable<LocaleUserPreferences>;
	/* --- End Preferences Section --- */

	/* --- Clinic Section --- */
	clinicId: Nullable<number>;
	clinic: Nullable<Clinic>;
	/* --- End Clinic Section --- */

	/* --- Crematory Section --- */
	crematories: Array<UserCrematory>;
	inventoryNotifications: boolean;

	clinicUserPreferences: ClinicUserPreferences | null;

	activeCrematory: Nullable<Crematory>;
	activeCrematoryId: number;

	statuses: Array<CrematoryUserStatus>;
	specialServices: Array<CrematoryUserSpecialService>;
	/* --- End Crematory Section --- */

	/* --- Permission Section --- */
	permissions: Array<UserPermission>;
	/* --- End Permission Section --- */
}

export interface UserListInfo extends WithId
{
	name: string;
	email: string;
	crematories: Array<UserCrematory>;
	clinicId: number | null;
	clinicName: string;
	role: UserRole;
}

pureEnum(UserRole);
export function userRoleName(role: UserRole): string {
	switch (role) {
	case UserRole.SystemService:
		return 'System Service';

	case UserRole.IntegrationService:
		return 'Integration Service';

	case UserRole.Admin:
		return 'Admin';

	case UserRole.ClinicUser:
		return 'Clinic User';

	case UserRole.ClinicManager:
		return 'Clinic Manager';

	case UserRole.Crematory:
		return 'Crematory';

	case UserRole.CrematoryUser:
		return 'Crematory User';

	default: return 'Unknown User Type';
	}
}

export function getUserName(user: User | undefined | null): string {
	if (!user || (!user.firstName && !user.lastName && !user.email)) return 'Wings User';
	if (!user.firstName && !user.lastName) return user.email;

	return `${user.firstName} ${user.lastName}`;
}

export function getDefaultUser(role: UserRole): User {
	return {
		id: -1,

		avatar: '',
		originalAvatar: '',

		role,
		firstName: '',
		lastName: '',
		email: '',

		localeUserPreferences: null,

		status: 0,
		transmuted: false,
		banned: false,

		clinicId: null,
		clinic: null,

		crematories: [],
		inventoryNotifications: false,

		unviewedMessagesCount: 0,
		unviewedNotificationsCount: 0,

		clinicUserPreferences: null,

		activeCrematory: null,
		activeCrematoryId: -1,

		permissions: [],

		specialServices: [],
		statuses: [],
	};
}
