import {
	bindActionCreators,
	Dispatch,
	Reducer,
} from 'redux';

import {
	getActionCreators as baseGetActionCreators, getReducer,
	IActionCreators,
	IMappedActionCreators, ItemsState, KnownPageAction,
} from '@common/react/store/ItemList';
import { WithId } from '@common/typescript/objects/WithId';

import { User } from '@app/objects/User';

import { ApplicationState } from '@app/store/index';

export type ItemsActionCreators<T extends WithId> = IActionCreators<T, User, ApplicationState>;
export type MappedItemsActionCreators<T extends WithId> = IMappedActionCreators<T, User, ApplicationState>;
export type KnownItemsActions<T extends WithId> = KnownPageAction<T, User, ApplicationState>;

export function getItemsActionCreators<T extends WithId>(): ItemsActionCreators<T> {
	return baseGetActionCreators<T, User, ApplicationState>();
}

export function bindItemsActionCreators<T extends WithId>(dispatch: Dispatch): MappedItemsActionCreators<T> {
	return bindActionCreators<ItemsActionCreators<T>, MappedItemsActionCreators<T>>(getItemsActionCreators(), dispatch);
}

export function getItemsReducer<T extends WithId>(storageName: keyof ApplicationState): Reducer<ItemsState<T>> {
	return getReducer<T, User, ApplicationState>(storageName);
}
