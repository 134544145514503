import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import { ClinicInfoHeader } from '@app/components/UI/Header/HeaderRoleDetails/ClinicInfoHeader';

import { UserRole } from '@app/objects/User';

export type DetailsList = Record<UserRole, Nullable<React.ComponentType>>;

export const detailsList: DetailsList = {
	[UserRole.SystemService]: null,
	[UserRole.IntegrationService]: null,
	[UserRole.Admin]: null,
	[UserRole.Crematory]: null,
	[UserRole.CrematoryUser]: null,
	[UserRole.ClinicUser]: ClinicInfoHeader,
	[UserRole.ClinicManager]: ClinicInfoHeader,
};
