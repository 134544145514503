import React from 'react';

import { useFormikContext } from 'formik';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';

interface ModalButtonsProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	onClose: () => void;
	okText?: string;
}

export const ModalButtons: React.FC<ModalButtonsProps> = ({ onClose, okText, ...props }: ModalButtonsProps) => {
	const { isSubmitting } = useFormikContext();

	return (
		<Space
			style={{
				justifyContent: 'flex-end',
				margin: '16px 0 0 0',
			}}
		>
			<Button
				danger
				size="small"
				onClick={onClose}
			>
				Cancel
			</Button>
			<Button
				{...props}
				type="primary"
				htmlType="submit"
				size="small"
				loading={isSubmitting}
			>
				{okText ?? 'Save'}
			</Button>
		</Space>
	);
};
