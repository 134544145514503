import * as React from 'react';

import CoreButton from 'antd/lib/button';
import { ButtonProps as CoreButtonProps } from 'antd/lib/button/button';
export interface ButtonProps extends CoreButtonProps {
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
}

const Button: React.FC<ButtonProps> = 
	({
		type,
		className, 
		loading, 
		onClick, 
		children, 
		title, 
		disabled = false, 
		// other = undefined
		...props
	}) => {
		return <CoreButton 
			{...props} 
			type={type} 
			disabled={Boolean(loading || disabled)} 
			className={className} 
			onClick={onClick}
			title={title}
		>
			{children}
		</CoreButton>;
	};

interface PreventDefaultButtonProps extends ButtonProps {
	onClick:() => void;
}

export const PreventDefaultButton: React.FC<PreventDefaultButtonProps> = ({onClick, children, ...rest}) => 
	<Button {...rest} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onClick(); }}>
		{children}
	</Button>;

export default Button;
