import React from 'react';

import Typography from 'antd/lib/typography';

interface ValueDisplayProps {
	title: string;
	value?: string;
	className?: string;
}

export const ValueDisplay: React.FC<ValueDisplayProps> = ({ title, value, className }: ValueDisplayProps) => (
	<>
		<Typography.Text strong>{title}</Typography.Text>
		<div className={className}>{value}</div>
	</>
);

ValueDisplay.defaultProps = {
	value: '-',
};
