import * as React from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Upload from 'antd/lib/upload';
import Progress from 'antd/lib/progress';

import Button from '@common/react/components/Forms/Button';
import { Nullable } from '@common/typescript/objects/Nullable';
import {
	TransformData,
	upload,
	UploadRequestOption
} from '@common/react/components/Forms/Files/Upload';
import { member } from '@common/react/utils/decorators';
import {
	FileType,
	FileInterface,
} from '@common/typescript/objects/FileInterface';

interface FileWallState {
	loading: boolean;
	error: Nullable<string>;
	progress: number,
}

interface FileWallProps {
	type: string;
	objectId: number;
	fileType?: FileType;

	onUpdate: (file: FileInterface) => void;
	onError?: (error: string) => void;

	infoMessage?: string;
	showError?: boolean;
	asButton?: boolean;
	accept?: string;
	buttonCaption?: string | JSX.Element;
	buttonClassName?: string;
	withProgressBar?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	noRelation?: boolean; // Whether to create relation for uploaded file or not
	data?: object;
	transformData?: TransformData; // Transform data before sending to server

	count: number;
	maxCount?: number;
}

export class FileWall extends React.Component<FileWallProps, FileWallState> {
	public static defaultProps: Partial<FileWallProps> = {
		infoMessage: 'Image size must not exceed 1 Mb',
		asButton: false,
		accept: '',
		buttonCaption: 'Upload',
		showError: true,
		maxCount: 8,
		withProgressBar: false,
	};

	public state: FileWallState = {
		loading: false,
		error: null,
		progress: -1,
	};

	@member
	customRequest(argument: UploadRequestOption<null>) {
		const {
			file,
			headers,
		} = argument;

		const {
			type,
			objectId,
			fileType = FileType.Avatar,
			data,
			noRelation,
			transformData,
		} = this.props;

		if (noRelation && headers) {
			headers['No-Relation'] = 'true';
		}

		this.setState({
			loading: true,
			error: null,
			progress: 0,
		});

		const onProgress = (progress) => this.setState({ progress: Math.round(progress * 100) });

		upload({
			file,
			fileType,
			objectId,
			type,
			data,
			headers: headers as HeadersInit,
		}, transformData, onProgress)
			.then((file: FileInterface) => {
				this.setState({ loading: false });
				this.props.onUpdate(file);
				setTimeout(() => this.setState({ progress: -1 }), 600);
			}).catch((error) => {
				this.setState({
					loading: false,
					error: error.message,
				});

				this.props.onError?.(error.message);
			});
	}

	private getUploadComponent(): React.ReactElement {
		const {
			asButton,
			infoMessage,
			buttonCaption,
			buttonClassName,
			withProgressBar,
		} = this.props;

		if (asButton) {
			return (
				<>
					<Button
						type="text"
						className={buttonClassName}
						loading={this.state.loading}
						disabled={this.props.disabled}
					>
						{buttonCaption}
					</Button>
					{withProgressBar && this.state.progress > -1 && <Progress percent={this.state.progress} size="small" />}
					{this.props.showError !== false && <div className="file-upload-error">{this.state.error}</div>}
				</>
			);
		}

		return (
			<>
				{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
				<div className="ant-upload-text">{buttonCaption}</div>
				<div className="ant-upload-info">{this.state.error || infoMessage}</div>
			</>
		);
	}

	render(): JSX.Element {
		const {
			accept,
			count,
			maxCount,
		} = this.props;

		const uploadButton = this.getUploadComponent();

		return (
			<Upload
				className={`avatar-component avatar-uploader ${this.state.error ? 'avatar-uploader_error' : ''}`}
				listType={this.props.asButton ? 'text' : 'picture-card'}
				showUploadList={false}
				accept={accept}
				customRequest={this.customRequest}
				disabled={this.props.disabled}
				multiple={this.props.multiple}
			>
				{count >= maxCount! ? null : uploadButton}
			</Upload>
		);
	}
}
