import * as React from 'react';

export type Value = number | string;
export type FilterOptionHook = (query: string, value: number) => boolean;

export enum SelectMode {
	Single = 0,
	Multiple = 1,
}

export enum SelectSize {
	Large = 0,
	Medium = 1,
	Small = 2,
}

export interface OptionProps<T extends Value = Value> {
	value: T | null;
	title?: string;
	disabled?: boolean;

	// Style
	className?: string;
	style?: React.CSSProperties;

	children?: React.ReactNode;
}

type OptionElement = React.ReactElement<OptionProps>;
type OptionFragment = never | OptionElement | Array<OptionFragment>;

export interface SelectProps<T extends Value> {
	// Optional Props
	placeholder?: string;
	title?: string;
	mode?: SelectMode;
	disabled?: boolean;
	loading?: boolean;
	showSearch?: boolean;
	showClear?: boolean;

	// Style
	className?: string;
	style?: React.CSSProperties;
	size?: SelectSize;

	// Main
	value: T | null | undefined;
	onChange: (value: T) => void;

	// Handlers
	onSelect?: (value) => void;
	onDeselect?: (value) => void;
	onSearch?: (query: string) => void;
	onBlur?: React.FocusEventHandler<HTMLElement> | undefined;
	onFocus?: () => void;
	onPopupScroll?: React.UIEventHandler<HTMLDivElement>;
	onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void;
	onMouseLeave?: (e: React.MouseEvent<HTMLInputElement>) => void;

	// Hooks
	filterOption?: boolean | FilterOptionHook;

	// Icons
	suffixIcon?: React.ReactNode;
	removeIcon?: React.ReactNode;
	clearIcon?: React.ReactNode;
	menuItemSelectedIcon?: React.ReactNode;

	children: OptionFragment;
}
