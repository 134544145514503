import * as React from 'react';
import { useSelector } from 'react-redux';

import { Optional } from '@common/typescript/objects/Nullable';

import { RequestData, useRequest } from '@app/hooks/useRequest';
import { StoreEntry, StoreEntryAttribute } from '@app/objects/StoreEntry';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { RoleManager } from '@app/services/RoleManager';

interface Config {
	entryId: Optional<number>;
}

interface RequestParams {
	storeEntryId: number;
}

function getData(data: Array<StoreEntryAttribute>, role: Optional<UserRole>): Array<StoreEntryAttribute> {
	if (!role) return [];
	if (!RoleManager.for(role).isClinicGroup()) return data;

	return data.filter((item: StoreEntryAttribute) => item.clinicCanPick);
}

export function useStoreEntry(config: Config): RequestData<RequestParams, StoreEntry> {
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const request = useRequest<StoreEntry, RequestParams>('getStoreEntry', undefined, { requestOnMount: false });

	React.useEffect(() => {
		if (!config.entryId) return;

		request.reload({ storeEntryId: config.entryId });
	}, [config.entryId]);

	return React.useMemo(() => ({
		...request,
		item: request.item
			? {
				...request.item,
				attributes: getData(request.item?.attributes, role),
			}
			: null,
	}), [request.item, request.loading]);
}
