import * as React from 'react';
import CoreTextArea from 'antd/lib/input/TextArea';
import TextArea, { TextareaAutosizeProps } from 'react-textarea-autosize';

interface ComponentProps {
	value: string;
	name: string;

	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => void;

	style?: TextareaAutosizeProps['style'];
}

type TextAreaComponentProps = Omit<ComponentProps, 'style'>
export const TextAreaComponent: React.FC<TextAreaComponentProps> = (props: TextAreaComponentProps) => (
	<CoreTextArea
		className="form-control"
		id={props.name}
		name={props.name}

		value={props.value}
		onChange={props.onChange}
		onBlur={props.onBlur}
	/>
);

export const TextAreaResizeComponent: React.FC<ComponentProps> = (props: ComponentProps) => {
	return (
		<TextArea
			className="form-control textarea"

			id={props.name}
			name={props.name}
			style={props.style}

			value={props.value}

			onChange={props.onChange}
			onBlur={props.onBlur}
		/>
	);
};
