import {
	bindActionCreators,
	Dispatch,
	Reducer,
} from 'redux';

import {
	LoginState as BaseLoginState,
	LoginActionCreators as BaseLoginActionCreators,
	getActionCreators as baseGetActionCreators,
	getReducer as baseGetReducer, KnownUserAction,
} from '@common/react/store/Login';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store/index';

export { TypeKeys } from '@common/react/store/Login';

export type LoginState = BaseLoginState<User>;
export type LoginActionCreators = BaseLoginActionCreators<User, ApplicationState>;
export type KnownLoginActions = KnownUserAction<User>;

export function getLoginActionCreators(): LoginActionCreators {
	return baseGetActionCreators<User, ApplicationState>();
}

export function bindLoginActionCreators(dispatch: Dispatch): LoginActionCreators {
	return bindActionCreators<LoginActionCreators, LoginActionCreators>(getLoginActionCreators(), dispatch);
}

export function getLoginReducer(): Reducer<LoginState> {
	return baseGetReducer<User>();
}
