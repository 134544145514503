import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, FieldProps, useFormikContext } from 'formik';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';

import { Col, Row } from 'antd/lib/grid';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';

import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { PetSpecieSelect } from '@app/components/UI/Inputs/PetSpecieSelect';
import { BreedSelect } from '@app/components/UI/Inputs/PetBreedSelect';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { GenderSelect } from '@app/components/UI/Inputs/GenderSelect';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import {
	PetEngraving,
	ServiceType,
	updateEngraving as getUpdatedEngraving,
	needUpdateEngraving,
} from '@app/objects/Pet';
import { EngravingList } from '@app/components/Various/Pet/EngravingList';
import { Price } from '@app/objects/Price';
import { ApplicationState } from '@app/store';
import { useCrematory } from '@app/hooks/useCrematory';

export const PetInfoSection: React.FC = () => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory(values.crematoryId);
	const [availableEngravingPrices, setAvailableEngravingPrices] = React.useState<Array<Price>>([]);
	const needUpdate = needUpdateEngraving(values.name, values.engraving, availableEngravingPrices, values.serviceType, crematory?.prefillEngraving);

	React.useEffect(() => {
		if (needUpdate && values.id < 0 && values.name) {
			setFieldValue('engraving', getUpdatedEngraving(values.name, values.engraving));
		}
	}, [availableEngravingPrices, values.serviceType, values.name, values.id]);

	return (
		<SectionContainer titleLocalizationKey="sections.pet">
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="name">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={`${t(iKey('labels.pet-name'))} *`}
								render={({ field, form }: FieldProps<string, PetFormValues>) => (
									<Input
										type="text"
										id={field.name}
										{...field}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											field.onChange(e);
											const engraving = form.values.engraving;

											if (needUpdate) {
												form.setFieldValue('engraving', getUpdatedEngraving(e.target.value, engraving));
											}
										}}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="petSpecieId">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={`${t(iKey('labels.pet-species'))} *`}
								render={({ field }: FieldProps<number, PetFormValues>) => (
									<PetSpecieSelect
										value={field.value}
										onChange={(value: number) => {
											fieldProps.form.setFieldValue('petSpecieId', value);
											fieldProps.form.setFieldValue('petBreedId', -1);
											fieldProps.form.setFieldValue('breedName', '');
										}}
										crematoryId={crematory?.id}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="petBreedId">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<>
								<FormikInput
									fieldProps={fieldProps}
									containerClassName=""
									title={`${t(iKey('labels.pet-breed'))} *`}
									render={({ field, form }: FieldProps<number, PetFormValues>) => (
										<>
											<BreedSelect
												petSpecieId={form.values.petSpecieId}
												crematoryId={crematory?.id}

												onChange={(id: number | undefined) => {
													form.setFieldValue('petBreedId', id, false);
												}}
												value={field.value}
											/>
											<Field name="isMixed">
												{({ field: mixedField, form }: FieldProps<boolean, PetFormValues>) => (
													<Checkbox
														onChange={(e) => form.setFieldValue(mixedField.name, e.target.checked)}
														checked={mixedField.value}
													>
														Mixed
													</Checkbox>
												)}
											</Field>
										</>
									)}
								/>
							</>
						)}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="genderId">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={`${t(iKey('labels.pet-gender'))} *`}
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<GenderSelect
										handleChange={(name, value) => form.setFieldValue(name, value)}
										value={field.value}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="color">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.pet-color'))}
								containerClassName=""
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="receivedDate">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={t(iKey('labels.date-received'))}
								render={({ field, form }) => (
									<Datepicker
										maxDateToday
										format={user?.localeUserPreferences?.dateFormat}

										value={field.value}
										onChange={(date) => form.setFieldValue(field.name, date)}
									/>
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>

			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="reportedWeight">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={`${t(iKey('labels.pet-reported-weight'))} *`}
								containerClassName=""
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="actualWeight">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.pet-actual-weight'))}
								containerClassName=""
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="ashesWeight">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.pet-ashes-weight'))}
								containerClassName=""
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>

			<Field name="engraving">
				{
					(fieldProps: FieldProps<Array<PetEngraving>>) => {
						if (fieldProps.form.values.serviceType === ServiceType.Communal) return null;

						return (
							<Row>
								<Col xs={12} sm={12}>
									<EngravingList
										crematoryId={fieldProps.form.values.crematoryId}
										clinicId={fieldProps.form.values.clinicId}
										priceType={fieldProps.form.values.priceType}
										serviceType={fieldProps.form.values.serviceType}
										value={fieldProps.field.value}
										onChange={(value: Array<PetEngraving>) => {
											fieldProps.form.setFieldValue(fieldProps.field.name, value);
										}}
										onChangePrice={setAvailableEngravingPrices}
										prefillEngraving={crematory?.prefillEngraving}
									/>
								</Col>
							</Row>
						);
					}
				}
			</Field>
		</SectionContainer>
	);
};
