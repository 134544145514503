/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import Popover from 'antd/lib/popover';
import Button from 'antd/lib/button';
import {
	BankOutlined,
	CompassOutlined,
	DollarOutlined,
	PercentageOutlined,
	PlusOutlined,
	RestOutlined,
	ShoppingCartOutlined,
	SignatureOutlined,
	ThunderboltOutlined,
	TruckOutlined,
} from '@ant-design/icons';

import { Nullable, Optional } from '@common/typescript/objects/Nullable';

import { getUrnFullName, iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { PetPrice } from '@app/objects/Pet';
import { DiscountType, PriceKind } from '@app/objects/Price';
import { Translator } from '@app/internationalization/init';
import { addressService } from '@app/services/AddressService';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { ApplicationState } from '@app/store';

import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { getOrderSuffix } from '@app/utilities/naturalLanguage';
import { SpecialServiceType } from '@app/objects/SpecialService';

interface TotalContentProps {
	value: IPriceStack;
	formValues: PetFormValues;
	onRecalculate: () => void;
}

function isMobileView(): boolean {
	return typeof window !== 'undefined' && window.innerWidth < 400;
}

function getDiscountLabel(discount: PetPrice, t: Translator): string {
	const price = discount.price;

	if (!price || price.unit !== DiscountType.Percentage) return `${t('discount')}`;

	return `${t('discount')} (${price.value}%)`;
}

function getProductValue(value: number) {
	if (value >= 0) return `$${value.toFixed(2)}`;

	return `-$${(value * -1).toFixed(2)}`;
}

function getServiceLabel(service: PetPrice) {
	if (service.price?.priceKind === PriceKind.PickupPrice) return service.name;

	return `${service.name} x ${service.count}`;
}

interface TaxesProps {
	serviceTax: Nullable<number>;
	serviceTaxPercentage: number;
	productTax: Nullable<number>;
	productTaxPercentage: number;
	formValues: PetFormValues;
}

function formatPercentage(value: Optional<number>): string {
	if (!value) return '0';

	return (value * 100).toFixed(0);
}

const Taxes: React.FC<TaxesProps> = (props: TaxesProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'editors.pet-editor.price-tag' });
	const crematories = useSelector((state: ApplicationState) => state.crematories.items);
	const crematory = crematories.find((item) => item.id === props.formValues.crematoryId);

	if (!props.serviceTax && !props.productTax) return null;

	const details = addressService.provide(crematory.country);
	const taxName = t(details.taxKey);

	if (props.serviceTaxPercentage === props.productTaxPercentage) {
		const value = (props.serviceTax ?? 0) + (props.productTax ?? 0);

		return (
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>{taxName} ({formatPercentage(props.productTaxPercentage)}%):</td>
				<td className="text-right">
					${value.toFixed(2)}
				</td>
			</tr>
		);
	}

	return (
		<>
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>Service {taxName} ({formatPercentage(props.serviceTaxPercentage)}%):</td>
				<td className="text-right">
					${(props.serviceTax ?? 0).toFixed(2)}
				</td>
			</tr>
			<tr>
				<td className="icon-container"><BankOutlined /></td>
				<td>Product {taxName} ({formatPercentage(props.productTaxPercentage)}%):</td>
				<td className="text-right">
					${(props.productTax ?? 0).toFixed(2)}
				</td>
			</tr>
		</>
	);
};

function filterServices(services: Array<PetPrice>): Array<PetPrice> {
	return services.filter((q: PetPrice) => {
		const type = q.price?.specialService?.specialServiceType;

		return type === SpecialServiceType.Kit || type === SpecialServiceType.Service || q.price?.priceKind === PriceKind.PickupPrice;
	});
}

const TotalContent: React.FC<TotalContentProps> = (props: TotalContentProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'editors.pet-editor.price-tag' });

	const price = props.value.base;
	const engraving = props.value.engravings;
	const priceExtra = price && price.extra > 0 ? ` + $${price.extra.toFixed(2)}` : '';
	const services = filterServices(props.value.services).filter((service: PetPrice) => !service.removed);
	const delivery = props.value.delivery;
	const total = props.value.total;
	const subtotal = props.value.subtotal;
	const urns = props.value.urns;
	const products = props.value.products;
	const discount = props.value.discount;
	const rush = props.value.rush;

	return (
		<table className="table price-widget-table">
			<thead>
				<tr>
					<td className="icon-container" />
					<td />
					<td className="pull-right">
						<Button
							danger
							size="small"
							onClick={props.onRecalculate}
						>
							Recalculate
						</Button>
					</td>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td className="icon-container">
						<DollarOutlined />
					</td>
					<td>
						{t('price')}:
					</td>
					<td className="text-right">
						{price
							? `$${price.value.toFixed(2)}${(priceExtra)}`
							: <span className="color-red">{t('price-not-found')}</span>}
					</td>
				</tr>
				{
					engraving.map((item: PetPrice, index: number) => (
						<tr key={item.clientId}>
							<td className="icon-container"><SignatureOutlined /></td>
							<td>Engraving - {index + 1}{getOrderSuffix(index + 1)} line</td>
							<td className="text-right">
								{item.value ? (`$${item.value.toFixed(2)}`) : t('free')}
							</td>
						</tr>
					))
				}
				{
					services.map((service: PetPrice) => (
						<tr className="special-service" key={service.clientId}>
							<td className="icon-container">
								{service.price?.priceKind === PriceKind.PickupPrice ? <CompassOutlined /> : <PlusOutlined />}
							</td>
							<td>{getServiceLabel(service)}:</td>
							<td className="text-right">
								{service.value ? (`$${service.value.toFixed(2)}`) : t('free')}
							</td>
						</tr>
					))
				}
				{
					urns.map((urn: PetPrice) => (
						<tr key={urn?.clientId}>
							<td className="icon-container"><RestOutlined /></td>
							<td>{getUrnFullName(urn)} x {urn.count}: </td>
							<td className="text-right">
								{getProductValue(urn.value)}
							</td>
						</tr>
					))
				}
				{
					products.map((product) => (
						<tr key={product?.clientId}>
							<td className="icon-container"><ShoppingCartOutlined /></td>
							<td>{product.name} x {product.count}: </td>
							<td className="text-right">
								{getProductValue(product.value)}
							</td>
						</tr>
					))
				}
				{
					discount
						? (
							<tr>
								<td className="icon-container"><PercentageOutlined /></td>
								<td>{getDiscountLabel(discount, t)}:</td>
								<td className="text-right">
									${discount.value.toFixed(2)}
								</td>
							</tr>
						) : null
				}
				{
					delivery
						?	(
							<tr>
								<td className="icon-container"><TruckOutlined /></td>
								<td>Delivery:</td>
								<td className="text-right">
									{`$${delivery.value.toFixed(2)}`}
								</td>
							</tr>
						) : null
				}
				{
					rush && props.formValues.rush
						?	(
							<tr>
								<td className="icon-container"><ThunderboltOutlined /></td>
								<td>Rush:</td>
								<td className="text-right">
									{`$${rush.value.toFixed(2)}`}
								</td>
							</tr>
						) : null
				}
				{
					subtotal
						? (
							<tr>
								<td className="icon-container" />
								<td><strong>{t('subtotal')}:</strong></td>
								<td className="text-right">
									<strong>
										${subtotal.toFixed(2)}
									</strong>
								</td>
							</tr>
						) : null
				}

				<Taxes
					serviceTax={props.value.serviceTaxTotal}
					serviceTaxPercentage={props.value.serviceTaxPercentage}
					productTax={props.value.productTaxTotal}
					productTaxPercentage={props.value.productTaxPercentage}
					formValues={props.formValues}
				/>
			</tbody>

			<tfoot>
				<tr>
					<td className="icon-container" />
					<td><strong>{t('total')}:</strong></td>
					<td className="text-right">
						<strong>
							${total.toFixed(2)}
						</strong>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

export const PriceTagDisplay: React.FC<TotalContentProps> = (props: TotalContentProps) => {
	const { t } = useTranslation();

	const isMobile = isMobileView();
	const classes = clsx('pet-editor-page__total', !props.value.base && 'pet-editor-page__total_not-found');

	return (
		<Popover
			trigger={isMobile ? 'click' : 'hover'}
			placement={isMobile ? 'bottomRight' : 'leftTop'}
			content={<TotalContent value={props.value} onRecalculate={props.onRecalculate} formValues={props.formValues} />}
		>
			<div
				className={classes}
			>
				{t(iKey('price-tag.total'))}: $
				{props.value.total.toFixed(2)}
			</div>
		</Popover>
	);
};
