import React from 'react';

import {
	Field,
	FieldProps,
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
} from 'formik';
import Alert from 'antd/lib/alert';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Space from 'antd/lib/space';

import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';

import { PriceKind } from '@app/objects/Price';
import { Pet, PetPrice } from '@app/objects/Pet';
import { useRequest } from '@app/hooks/useRequest';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import {
	ExtraProps,
	getNewServicesArray,
	restorePrices,
} from '@app/utilities/services';
import { DefaultInfo } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/DefaultInfo';

interface ProductFormProps {
	item: Pet;
	onChange: (item: Partial<Pet>) => void;
	onClose: () => void;
}

interface FormValues {
	services: Array<PetPrice>;
}

interface UpdateMessage {
	services: Array<ExtraProps>;
}

const columns = TableBuilder.shape<PetPrice>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (name: string) => (
			<MobileCell caption="Name">
				{name}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Count',
		dataIndex: 'count',
		align: 'right',
		width: '10ch',
		render: (count: number, _, index) => (
			<Field name={`services.${index}`}>
				{({ form }: FieldProps<PetPrice, FormValues>) => (
					<MobileCell caption="Count">
						{form.initialValues.services[index].count}
					</MobileCell>
				)}
			</Field>
		),
	})
	.addColumn({
		title: '',
		dataIndex: 'id',
		align: 'center',
		width: '15ch',
		render: (_, record: PetPrice, index: number) => (
			<Field name={`services.${index}`}>
				{({ field, form }: FieldProps<PetPrice, FormValues>) => (
					<MobileCell caption="">
						<Checkbox
							checked={field.value?.count === field.value?.completedCount}
							onChange={(event: CheckboxChangeEvent) => {
								const checked = event.target.checked;
								if (checked) {
									form.setFieldValue(field.name, { ...field.value, completedCount: field.value.count });
								} else {
									form.setFieldValue(field.name, { ...field.value, completedCount: form.initialValues.services[index].completedCount });
								}
							}}
						/>
					</MobileCell>
				)}
			</Field>
		),
	})
	.build();

export const ProductForm: React.FC<ProductFormProps> = (props: ProductFormProps) => {
	const item = props.item;
	const request = useRequest<Array<PetPrice>, UpdateMessage>('updateServices', undefined, { requestOnMount: false });
	const services = React.useMemo(() => item.services?.filter((i: PetPrice) => i.price?.priceKind === PriceKind.ProductPrice) ?? [], [item]);

	const submit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
		const allArray = getNewServicesArray(services, values);

		request.reload({ services: allArray.changed })
			.then((items: Array<PetPrice> | void) => {
				if (!items) return;

				props.onChange({
					id: item.id,
					services: restorePrices(services, allArray.unchanged.concat(items)),
				});
			})
			.finally(() => actions.setSubmitting(false));
	};

	return (
		<Formik
			initialValues={{ services }}
			onSubmit={submit}
			enableReinitialize
		>
			{
				(formik: FormikProps<FormValues>) => (
					<Form>
						<StatusModalController width="60%" />

						<Space direction="vertical" size="middle">
							<DefaultInfo item={item} />

							<Typography.Text strong>Products:</Typography.Text>
							<Table
								columns={columns}
								dataSource={formik.values.services}
								pagination={{ hideOnSinglePage: true, position: ['bottomCenter'] }}
								rowKey="id"
								onRow={(record: PetPrice, index?: number) => ({
									onClick: () => {
										const checked = record.count === record.completedCount;
										const product = checked
											? { ...record, completedCount: formik.initialValues.services[index ?? 0].completedCount }
											: { ...record, completedCount: record.count };

										formik.setFieldValue(`services.${index}`, product);
									},
								})}
							/>
						</Space>
						<Stack
							direction={StackDirection.Vertical}
							gap={1}
							style={{
								margin: '8px 0',
							}}
						>
							<Alert
								type="warning"
								message="Please, select the products you want to confirm."
								closable
								onClose={(event: React.MouseEvent) => event.stopPropagation()}
							/>
							{request.error && (
								<Alert
									type="error"
									message={request.error}
									closable
									onClose={(event: React.MouseEvent) => event.stopPropagation()}
								/>
							)}
						</Stack>

						<ModalButtons onClose={props.onClose} okText="Confirm" />
					</Form>
				)
			}
		</Formik>
	);
};
