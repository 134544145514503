import React from 'react';

interface ControlLabelProps {
	text: string;
	required?: boolean;
}

export const ControlLabel: React.FC<ControlLabelProps> = ({ text, required }: ControlLabelProps) => (
	<>
		{text}{required ? '*' : ''}
	</>
);
