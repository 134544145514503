import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Alert from 'antd/lib/alert';
import { EditOutlined } from '@ant-design/icons';

import { Formik, FormikProps, useFormikContext } from 'formik';

import Button from '@common/react/components/Forms/Button';

import { Pet, PetPrice } from '@app/objects/Pet';
import { CurrentStatus } from '@app/objects/CurrentStatus';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';

import { StatusComponent } from '@app/components/UI/Status/StatusComponent';
import { SpecialServicesList } from '@app/components/UI/Status/SpecialServicesList';
import { StatusChangeComponent, StatusResponse } from '@app/components/UI/Status/StatusChangeComponent';
import { PromoteDirection, PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';

import { alertMessage, MessageType } from '@app/utilities/alert';
import {
	getNewServicesArray,
	getCurrentServices,
	restorePrices,
	ExtraProps,
} from '@app/utilities/services';
import { ModalControls, useModal } from '@app/hooks/Editors/useModal';
import { ForceStatusForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/ForceStatusForm';
import { HistoryChangeList } from '@app/components/Pages/PetEditor/HistoryChangeList';
import { activePermission, Permission } from '@app/objects/Permission';
import { TriggerAction, TriggerEvent } from '@app/objects/TriggerStatus';
import { gePetStatusTriggerActionCreators } from '@app/store/PetTriggers';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { allowAdd } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/Services';
import {
	ConfirmMessage,
	toUpdateMessage,
	UpdatePetMessage,
} from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/UrnForm';
import { useRequest } from '@app/hooks/useRequest';

interface ComponentProps {
	onChangeStatus: (response: CurrentStatus) => void;
	onRefresh: (petId: number, services: Array<PetPrice>) => void;
	onUrnRefresh: (services: Array<PetPrice>) => void;
	disabled?: boolean;
}

interface CurrentStatusComponentProps {
	pet: Pet,
	onChangeStatus: (response: CurrentStatus) => void,
	isConfirm?: boolean,
	modal: ModalControls,
	disabled?: boolean,
}

interface ServiceComponentProps {
	pet: Pet;
	onRefresh: (petId: number, services: Array<PetPrice>) => void;
	onUrnRefresh: (services: Array<PetPrice>) => void;
	services: Array<PetPrice>;
	fieldName?: string;
}

interface FormValues {
	services: Array<PetPrice>;
}

const CurrentStatusComponent: React.FC<CurrentStatusComponentProps> = (props: CurrentStatusComponentProps) => {
	const {
		pet, onChangeStatus, isConfirm, modal, disabled,
	} = props;
	const { currentStatus } = pet;

	const login = useSelector((state: ApplicationState) => state.login);
	const withControls = login.user?.role === UserRole.Admin || login.user?.role === UserRole.Crematory;
	const withNext = Boolean(currentStatus?.nextStatusId);
	const withPrevious = Boolean(currentStatus?.previousStatusId);
	const canChangeStatus = activePermission(login.user, Permission.ChangeStatus);

	return (
		<div className="form-group">
			<div className="site-subheadline">
				<h2>Current status</h2>
			</div>

			<StatusChangeComponent>
				{(promote) => (
					<StatusComponent
						pet={{
							id: pet.id,
							status: pet.currentStatus?.status?.status?.name ?? 'Unknown',
							date: pet.currentStatus?.time,
						}}
						onClick={(direction: PromoteDirection) => {
							promote.request(direction, pet.id, { confirmChanges: isConfirm })
								.then((response: StatusResponse | void) => {
									if (!response) return;

									if (response.currentStatus && (response.code === PromoteResponseCode.Success
										|| pet.currentStatus?.statusId !== response.currentStatus?.statusId)) {
										onChangeStatus(response.currentStatus);
									} else {
										alertMessage(MessageType.warning, response.message);
									}
								})
								.catch((error: string) => alertMessage(MessageType.error, error));
						}}
						loading={promote.isLoading}
						withControls={withControls && canChangeStatus}
						withNext={withNext}
						withPrevious={withPrevious}
						disabled={disabled || !isConfirm}
						action={login.user?.role === UserRole.Admin && canChangeStatus
							? (
								<EditOutlined
									style={{ cursor: 'pointer' }}
									title="Edit"
									onClick={!disabled ? () => modal.open() : undefined}
								/>
							) : null}
					/>
				)}
			</StatusChangeComponent>
		</div>
	);
};
interface TValues {
	toConfirm: Array<ExtraProps>,
	id: number;

	toSelect: Array<PetPrice>;
}

const submit = (
	values: TValues,
	setSubmitting: (isSubmitting: boolean) => void,
	updatePet,
	updateServices,
	onChange: (services: Array<PetPrice>) => void,
) => {
	function update(list: Array<PetPrice>): Promise<unknown> {
		const data = list.map(toUpdateMessage);

		return updatePet.reload({ id: values.id, updateServices: data });
	}

	function confirm(list: Array<ExtraProps>): Promise<unknown> {
		return updateServices.reload({ services: list })
			.then((res) => onChange(res as Array<PetPrice>));
	}

	setSubmitting(true);
	let task: Promise<unknown> = Promise.resolve();

	if (values.toSelect.length) {
		task = update(values.toSelect)
			.then(() => confirm(values.toConfirm));
	} else if (values.toConfirm.length) {
		task = confirm(values.toConfirm);
	}

	task
		.finally(() => setSubmitting(false));
};

const ServiceComponent = (props: ServiceComponentProps) => {
	const { pet, services, onRefresh } = props;
	const updatePet = useRequest<Pet, UpdatePetMessage>('updatePet', undefined, { requestOnMount: false });
	const updateServices = useRequest<Array<PetPrice>, ConfirmMessage>('updateServices', undefined, { requestOnMount: false });

	return (
		<Formik
			initialValues={{ services }}
			onSubmit={(values: FormValues, { setSubmitting }) => {
				const allArray = getNewServicesArray(services, values);
				if (props.fieldName?.toLowerCase() !== 'urns') {
					updateServices.reload({ services: allArray.changed })
						.then((items: Array<PetPrice> | void) => {
							if (!items) return;

							onRefresh(pet.id, restorePrices(services, allArray.unchanged.concat(items)));
						})
						.finally(() => setSubmitting(false));
				} else {
					const toSelect = values.services.filter((i) => i.toSelect);
					const data = {
						id: pet.id,
						toSelect,
						toConfirm: allArray.changed,
					};
					submit(data, setSubmitting, updatePet, updateServices, props.onUrnRefresh);
				}
			}}
			enableReinitialize
		>
			{(formik: FormikProps<FormValues>) => (
				<>
					<SpecialServicesList
						services={formik.values.services}
						onChange={(service: PetPrice) => {
							const newValues = formik.values.services.map((item: PetPrice) => {
								if (item.id === service.id) return service;

								return item;
							});
							formik.setFieldValue('services', newValues);
						}}
						fieldName={props.fieldName}
					/>
					<div className="form-group">
						{
							updatePet.error ?? updateServices.error ? (
								<Alert
									type="error"
									message={updatePet.error ?? updateServices.error}
									closable
									onClose={(event: React.MouseEvent) => event.stopPropagation()}
								/>
							) : null
						}
					</div>
					<div className="text-right" style={{ marginTop: '8px', paddingTop: '8px', borderTop: '1px solid #e8e8e8' }}>
						<Button
							size="small"
							type="primary"
							onClick={() => formik.submitForm()}
							loading={formik.isSubmitting}
							disabled={!formik.values.services.some((i) => i.completedCount === i.count)}
						>
							Save
						</Button>
					</div>
				</>
			)}
		</Formik>
	);
};

export const OldPetStatusHistory: React.FC<ComponentProps> = ({
	onRefresh, onChangeStatus, disabled, onUrnRefresh,
}) => {
	const modal = useModal(false);
	const { values } = useFormikContext<PetFormValues>();
	const {
		currentStatus, crematoryId, serviceType, priceType,
	} = values;
	const dispatch = useDispatch();
	const action = bindActionCreators(gePetStatusTriggerActionCreators(), dispatch);
	const statusOrders = useSelector((state: ApplicationState) => state.statusOrders.items);
	const needUrnsConfirmed = React.useMemo(() => Boolean(statusOrders.list.find((i) =>
		(currentStatus?.statusId === i.id && i.triggers.find((t) => t.event === TriggerEvent.OnWillLeave))
		|| (currentStatus?.nextStatusId === i.id && i.triggers.find((t) => t.event === TriggerEvent.OnWillEnter)))), [statusOrders, currentStatus]);

	const params = React.useMemo(() => ({
		filters: {
			crematoryId,
			serviceType,
			priceType,
			trigger: [TriggerAction.UrnPopup],
		},
	}), [serviceType, priceType, crematoryId]);

	React.useEffect(() => {
		action.request(params);
	}, [params]);

	const [isConfirm, setIsConfirm] = React.useState<boolean | undefined>(true);

	const services = getCurrentServices(values);
	const urns = React.useMemo(() => values.urns.filter((i) => i.done !== true && allowAdd(i.entry, i.node, i.nodeId) && !i.removed && i.id > 0), [values.urns]);

	return (
		<div>
			{
				currentStatus
					? (
						<CurrentStatusComponent
							pet={values}
							disabled={disabled}
							onChangeStatus={onChangeStatus}
							isConfirm={isConfirm}
							modal={modal}
						/>
					) : null
			}
			{
				services.length
					? (
						<ServiceComponent
							pet={values}
							services={services}
							onRefresh={onRefresh}
							onUrnRefresh={onUrnRefresh}
						/>
					) : null
			}
			{
				needUrnsConfirmed && urns.length
					? (
						<ServiceComponent
							pet={values}
							services={urns}
							onRefresh={onRefresh}
							onUrnRefresh={onUrnRefresh}
							fieldName="Urns"
						/>
					) : null
			}
			<div style={{ margin: '20px 0' }}>
				<HistoryChangeList
					item={values}
					onChange={(isCheck: boolean, isItems: boolean) => {
						setIsConfirm((isCheck && isItems) || !isItems);
					}}
				/>
			</div>

			{modal.state ? (<ForceStatusForm pet={values} modal={modal} onChangeStatus={onChangeStatus} />) : null }
		</div>
	);
};
