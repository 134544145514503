import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { Clinic } from '@app/objects/Clinic';
import { State } from '@app/objects/State';
import { formatAddress } from '@app/utilities/address';

export interface Location extends WithDeleted {
	clinicId: number;
	clinic?: Clinic;

	streetAddress: string;
	streetAddress2: string;
	city: string;
	zip: string;

	state: Nullable<State>;
	stateId: Nullable<number>;

	name: string;
	note: string;

	externalId: number | null;

	disabled: boolean;
}

export interface LocationInfo extends WithId {
	name: string;

	streetAddress: string;
	streetAddress2: string;
	city: string;
	zip: string;

	state: Nullable<State>;
	stateId: Nullable<number>;
}

export function locationName(info: LocationInfo): string {
	const address = formatAddress(info);

	return info.name ? `${info.name} (${address})` : address;
}
