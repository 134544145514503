import React from 'react';

import * as Yup from 'yup';
import {
	FieldProps,
	Form,
	Formik,
} from 'formik';
import Checkbox from 'antd/lib/checkbox';
import Space from 'antd/lib/space';

import { Pet } from '@app/objects/Pet';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { useRequest } from '@app/hooks/useRequest';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { FormikField } from '@app/components/Forms/FormikField';

interface FormValues {
	id: number;
	trackingNumber?: string;
	sendTrackingEmail: boolean;
}

const validationSchema = Yup.object().shape({
	trackingNumber: Yup.string().required('The Tracking ID is required'),
});

interface TrackingFormProps {
	item: Pet;
	onChange: (item: Partial<Pet>, shouldClose?: boolean, params?: PromoteParams) => void;
	onClose: () => void;
}

export const TrackingForm: React.FC<TrackingFormProps> = (props: TrackingFormProps) => {
	const item = props.item;
	const request = useRequest<Pet, Partial<FormValues>>('updatePet', undefined, { requestOnMount: false });

	return (
		<Formik
			initialValues={{
				id: item.id,
				trackingNumber: item.trackingNumber,
				sendTrackingEmail: item.sendTrackingEmail ?? true,
			}}
			onSubmit={(values: FormValues, { setSubmitting }) => {
				request.reload({
					id: item.id,
					trackingNumber: values.trackingNumber,
					sendTrackingEmail: values.sendTrackingEmail,
				})
					.then((item: Pet | void) => {
						if (!item) return;

						props.onChange({
							id: item.id,
							trackingNumber: item.trackingNumber,
							sendTrackingEmail: values.sendTrackingEmail,
						});
					})
					.finally(() => setSubmitting(false));
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{() => (
				<Form>
					<StatusModalController width={400} />

					<Space direction="vertical" size="middle">
						<FormikField fieldName="trackingNumber" title="Tracking ID" />
						<FormikField
							fieldName="sendTrackingEmail"
							render={({ field, form }: FieldProps<boolean, FormValues>) => (
								<Checkbox
									checked={field.value}
									onClick={() => form.setFieldValue(field.name, !field.value)}
									style={{ fontWeight: 'normal' }}
								>
									Send Email
								</Checkbox>
							)}
						/>
						<ModalButtons onClose={props.onClose} />
					</Space>
				</Form>
			)}
		</Formik>
	);
};
