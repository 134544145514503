import * as React from 'react';
import { generatePath, RouteComponentProps } from 'react-router-dom';

import Table, { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import Button from 'antd/lib/button';

import { Extend } from '@common/typescript/utils/types';
import { WithId } from '@common/typescript/objects/WithId';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { ItemsState, IMappedActionCreators } from '@common/react/store/ItemList';
import { BaseParams } from '@common/react/objects/BaseParams';
import SimpleSearchInput from '@common/react/components/Forms/SimpleSearchInput/SimpleSearchInput';
import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { parseQuery } from '@common/typescript/utils/url';
import { Nullable } from '@common/typescript/objects/Nullable';

export interface ItemsPageReduxProps<T extends WithId> {
	items: ItemsState<T>;
}

export interface ItemsPageReduxActions<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	actions: IMappedActionCreators<T, TUser, TApplicationState>
}

export type ItemsPageRouteProps = RouteComponentProps<{ page: string }>;

export type PageProps<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> =
	ItemsPageReduxProps<T>
	& ItemsPageReduxActions<T, TUser, TApplicationState>
	& ItemsPageRouteProps;

export enum SortingDirection {
	Default = 0,
	Ascending = 1,
	Descending = 2
}

type StringBoolNumber = string | boolean | number;

export interface HandleChangeEventElement {
	currentTarget: {
		name: string;
		value: Nullable<StringBoolNumber | StringBoolNumber[]>;
	};
}

export interface MobileCellProps {
	caption: string;
	children?: React.ReactNode;
	fullWidth?: boolean;
}

export const MobileCell: React.FC<MobileCellProps> = ({ caption, children = null, fullWidth = false }: MobileCellProps) => (
	<>
		<div className={`table-mobile__caption ${fullWidth ? 'table-mobile__caption_full' : ''}`}>{caption}</div>
		<div className={`table-mobile__content ${fullWidth ? 'table-mobile__content_full' : ''}`}>{children}</div>
	</>
);

type rowKey = (record) => string;

type ComponentProps<
	TEntity extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>,
	TPropsExtension = never
	> = Extend<PageProps<TEntity, TUser, TApplicationState>, TPropsExtension>;

export abstract class ExtendableItemsPage<
	TEntity extends WithId,
	TPropsExtension = never,
	TState = Record<string, unknown>,
	TUser extends BaseUser = BaseUser,
	TApplicationState extends BaseApplicationState<TUser> = BaseApplicationState<TUser>,
> extends React.Component<ComponentProps<TEntity, TUser, TApplicationState, TPropsExtension>, TState> {
	abstract store: keyof TApplicationState;
	abstract type: string;
	abstract path: string;
	abstract listPath: string;

	editorPath: string = '';
	editorCaption: string = '';
	listTemplatePath: string = '';
	caption: string = '';
	text: string = '';
	isFilterText: boolean = true;
	textInput: any;
	tableClassName: string = '';
	additionalParams: BaseParams = {};
	rowKey: string | rowKey = 'id';
	withoutUrlPagination: boolean = false;
	count: number = 10;
	withUrlHandling: boolean = false;

	baseAction = {
		title: '',
		render: (text: never, record: TEntity) => (
			<div className="text-right table-actions">
				{this.renderActionButtons(record)}
			</div>
		),
	};

	idColumn = {
		title: '#',
		dataIndex: 'id',
		render: (id: number): JSX.Element | null => (
			this.renderMobileCell('#', id > 0 ? <span style={{ whiteSpace: 'nowrap' }}>{id}</span> : null)
		),
	};

	columns: ColumnProps<TEntity>[] = [
		this.idColumn,
	];

	constructor(props: ComponentProps<TEntity, TUser, TApplicationState, TPropsExtension>) {
		super(props);

		this.handleTableChange = this.handleTableChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilter = this.handleFilter.bind(this);
		this.handleUrl = this.handleUrl.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeAndRefresh = this.handleChangeAndRefresh.bind(this);
		this.updateParams = this.updateParams.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.onItemsLoaded = this.onItemsLoaded.bind(this);
		this.onItemsError = this.onItemsError.bind(this);
		this.onTableRow = this.onTableRow.bind(this);
	}

	componentDidMount(): void {
		const page = this.withoutUrlPagination ? 1 : (this.currentPage);

		if (this.withUrlHandling) {
			const searchObj: BaseParams = parseQuery(this.props.location.search);

			Object.keys(searchObj).forEach((key: string) => {
				const value: string = searchObj[key];

				if (value === 'true') {
					searchObj[key] = true;
				} else if (value === 'false') {
					searchObj[key] = false;
				} else if (value[0] === '[' && value[value.length - 1] === ']') {
					searchObj[key] = value.slice(1, value.length - 1).split(',');
				}
			});

			this.additionalParams = {
				...this.additionalParams,
				...this.customUrlHandler(searchObj),
			};
		}

		const params = {
			page,
			count: this.count,
			...this.additionalParams,
		};
		this.props.actions.reqPages(this.store, this.path, this.type, params)
			.then(this.onItemsLoaded)
			.catch(this.onItemsError);
	}

	componentDidUpdate(prevProps): void {
		const page = prevProps.match ? parseInt(prevProps.match.params.page, 10) || 1 : 1;
		const current = this.currentPage;

		if (!this.props.items.isLoading && !this.withoutUrlPagination && page !== current) {
			const params = {
				page: current,
				count: this.count,
				...this.additionalParams,
			};

			this.props.actions.reqPages(this.store, this.path, this.type, params);
		}
	}

	handleDelete(event: React.MouseEvent<HTMLButtonElement>, item: TEntity) {
		event.preventDefault();

		deleteConfirmation(() => {
			this.removeItem(item);
		});
	}

	handleAdd() {
		this.props.history.push(`/${this.editorPath}/-1`);
	}

	handleTableChange(pagination: TablePaginationConfig, filters, sorter) {
		const page = this.currentPage;

		this.additionalParams.filters = filters;

		if (page !== pagination.current) {
			if (this.withoutUrlPagination) {
				this.props.actions.refreshPages(this.store, this.path, { page: pagination.current, count: this.count, ...this.additionalParams });
			} else {
				if (this.listTemplatePath) {
					const params = { ...this.props.match.params, page: pagination.current };
					const url = generatePath(this.listTemplatePath, params);

					this.props.history.push(url);
				} else {
					this.props.history.push(`/${this.listPath}/${pagination.current}${this.props.location.search}`);
				}
			}
		} else {
			if (sorter.column) {
				this.additionalParams.column = [{
					caption: sorter.field,
					direction: sorter.order === 'descend' ? SortingDirection.Descending : SortingDirection.Ascending,
				}];
			} else {
				this.additionalParams.column = [];
			}

			this.props.actions.refreshPages(this.store, this.path, { page: 1, count: this.count, ...this.additionalParams });
		}
	}

	onItemsLoaded() {
	}

	onItemsError(error: string) {
	}

	customUrlHandler(obj: BaseParams): BaseParams {
		return obj;
	}

	get currentPage(): number {
		return this.props.match ? parseInt(this.props.match.params.page, 10) || 1 : 1;
	}

	removeItem(item: TEntity): Promise<TEntity> {
		const page = this.props.items.params.page || 1;
		const isLast = this.props.items.items.length === 1 && page > 1;
		const value: TEntity = {
			...item,
			children: null,
			files: null,
		};

		return this.props.actions.removeItem(
			this.store,
			this.path,
			this.type,
			value,
			isLast ? { page: page - 1 } : null,
		).then(() => {
			if (isLast && !this.withoutUrlPagination) this.props.history.replace(`/${this.listPath}/${page - 1}`);
		}).then(() => item);
	}

	updateItem(item: Partial<TEntity>) {
		this.props.actions.updateItem(this.store, item);
	}

	updateParams(params: BaseParams): void {
		this.additionalParams = {
			...this.additionalParams,
			...params,
		};
	}

	handleUrl(evt: HandleChangeEventElement | Array<HandleChangeEventElement>) {
		const searchObj = parseQuery(this.props.location.search);


		if (Array.isArray(evt)) {
			evt.forEach(({ currentTarget: { name, value } }: HandleChangeEventElement) => {
				searchObj[name] = value instanceof Array ? `[${value}]` : `${value}`;
			});
		} else {
			const { name, value } = evt.currentTarget;
			searchObj[name] = value instanceof Array ? `[${value}]` : `${value}`;
		}

		const emptyValues = ['', 'null', 'undefined', '-1', '[]'];

		const search = Object.keys(searchObj)
			.filter((key: string) => emptyValues.indexOf(searchObj[key]) === -1)
			.map((key: string) => `${key}=${searchObj[key]}`)
			.join('&');

		this.props.history.replace(`${this.props.location.pathname.replace(/\/\d+/, '/1')}?${search}`);
	}

	handleChange(evt: HandleChangeEventElement | Array<HandleChangeEventElement>) {
		if (Array.isArray(evt)) {
			const params = {};
			evt.forEach((event: HandleChangeEventElement) => params[event.currentTarget.name] = event.currentTarget.value);
			this.updateParams(params);
		} else {
			this.updateParams({ [evt.currentTarget.name]: evt.currentTarget.value });
		}

		if (this.withUrlHandling) {
			this.handleUrl(evt);
		}
	}

	handleChangeAndRefresh(evt: HandleChangeEventElement| Array<HandleChangeEventElement>) {
		this.handleChange(evt);
		this.handleFilter(null);
	}

	handleFilter(event: React.FormEvent<HTMLFormElement> | null, nPage?: number) {
		event?.preventDefault();

		this.props.actions.refreshPages(this.store, this.path, { page: nPage || 1, ...this.additionalParams });
	}

	protected onTableRow(record: TEntity, index?: number): React.HTMLAttributes<any> {
		return {
		};
	}

	getFiltersRender(): JSX.Element {
		return (
			<div className="list-filters clearfix">
				<div className="list-filters__search">
					<form className="clearfix" onSubmit={this.handleFilter}>
						<SimpleSearchInput onChange={this.handleChange} name="text" />
					</form>
				</div>
			</div>
		);
	}

	renderMobileCell(caption: string, text: string | JSX.Element | null, fullWidth: boolean = false): JSX.Element {
		return <MobileCell caption={caption} fullWidth={fullWidth}>{text}</MobileCell>;
	}

	renderTable(): JSX.Element {
		const { items, pagination, isLoading } = this.props.items;
		return (
			<Table
				columns={this.columns}
				dataSource={items}
				pagination={pagination}
				loading={isLoading}
				onChange={this.handleTableChange}
				childrenColumnName="child"
				rowKey={this.rowKey}
				className={this.tableClassName}
				onRow={this.onTableRow}
			/>
		);
	}

	renderButtons(): JSX.Element {
		return <Button type="primary" onClick={this.handleAdd}>{this.editorCaption}</Button>;
	}

	renderActionButtons(record: TEntity): JSX.Element {
		return (
			<>
				<LinkWithPrevLocation
					className="btn btn-sm btn-default"
					title="Edit"
					to={`/${this.editorPath}/${record.id}`}
				>
					<i className="fa fa-pencil" />
				</LinkWithPrevLocation>
				<button
					type="button"
					className="btn btn-sm btn-danger"
					title="Delete"
					onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleDelete(e, record)}
				>
					<i className="fa fa-trash" />
				</button>
			</>
		);
	}

	renderCaption() {
		return 	<h1 className="pull-left">{this.caption}</h1>;
	}

	public render(): JSX.Element {
		const editor: JSX.Element | null = this.editorCaption && this.editorPath
			? <div className="pull-right">{this.renderButtons()}</div>
			: null;

		return (
			<>
				<div className="site-headline site-headline_with-button clearfix">
					{this.renderCaption()}
					{editor}
				</div>
				{this.isFilterText && this.getFiltersRender()}
				{this.renderTable()}
			</>
		);
	}
}

export abstract class ItemsPage<
	T extends WithId,
	TState = Record<string, unknown>,
	TUser extends BaseUser = BaseUser,
	TApplicationState extends BaseApplicationState<TUser> = BaseApplicationState<TUser>
> extends ExtendableItemsPage<T, never, TState, TUser, TApplicationState> {}
