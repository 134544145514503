import { Action, Reducer } from 'redux';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import { Nullable } from '@common/typescript/objects/Nullable';
import { List } from '@common/typescript/objects/List';
import { equal } from '@common/typescript/Utils';

import { AppThunkAction, ApplicationState } from '@app/store';
import { request } from '@app/components/Api';
import { DashboardEntity, DashboardCounters } from '@app/objects/Dashboard';

type Dispatch = (action: KnownDashboardCountersActions) => void;
export interface DashboardCountersState<T extends DashboardCounters> {
	isLoading: boolean;
	items: T;
	pagination: {
		total: number;
		current: number;
		offset: number;
		pageSize?: number;
	};
	type: string;
	params: BaseParams;
	error: Nullable<string>;
}

const defaultDashboardCounter = {
	count: 0,
	execution: 0,
	offset: 0,
	list: [],
};

function getDefaultDashboardCountersState<T extends DashboardCounters>(): DashboardCountersState<T> {
	return {
		isLoading: false,
		items: {
			services: defaultDashboardCounter,
			statuses: defaultDashboardCounter,
			reports: defaultDashboardCounter,
		} as unknown as T,
		pagination: {
			total: 0,
			current: 0,
			offset: 0,
			pageSize: 0,
		},
		type: '',
		params: {},
		error: null,
	};
}

export enum TypeKeys {
	REQUESTDASHBOARDCOUNTERS = 'REQUESTDASHBOARDCOUNTERS',
	RECEIVEDASHBOARDCOUNTERS = 'RECEIVEDASHBOARDCOUNTERS',
	UPDATEDASHBOARDCOUNTERS = 'UPDATEDASHBOARDCOUNTERS',
	REQUESTDASHBOARDCOUNTERSERROR = 'REQUESTDASHBOARDCOUNTERSERROR',
	REQUESTDASHBOARDCOUNTERSMORE = 'REQUESTDASHBOARDCOUNTERSMORE',
	RECEIVEDASHBOARDCOUNTERSMORE = 'RECEIVEDASHBOARDCOUNTERSMORE',
}

interface RequestDashboardCountersAction {
	type: TypeKeys.REQUESTDASHBOARDCOUNTERS;
}

interface ReceiveDashboardCountersAction {
	type: TypeKeys.RECEIVEDASHBOARDCOUNTERS;
	items: DashboardCounters | null;
}

interface UpdateDashboardCountersAction {
	type: TypeKeys.UPDATEDASHBOARDCOUNTERS;
	items: Array<DashboardEntity>
	itemName: string;
	paramName: string;
}

interface RequestDashboardCountersErrorAction {
	type: TypeKeys.REQUESTDASHBOARDCOUNTERSERROR;
	error: string;
}

interface RequestDashboardCountersMoreAction {
	type: TypeKeys.REQUESTDASHBOARDCOUNTERSMORE;
}

interface ReceiveDashboardCountersMoreAction {
	type: TypeKeys.RECEIVEDASHBOARDCOUNTERSMORE;
	items: List<DashboardEntity>;
	itemName: string;
}

type KnownDashboardCountersActions = RequestDashboardCountersAction
	| ReceiveDashboardCountersAction
	| UpdateDashboardCountersAction
	| RequestDashboardCountersErrorAction
	| RequestDashboardCountersMoreAction
	| ReceiveDashboardCountersMoreAction;

function loadPage(
	dispatch: Dispatch,
	getState: () => ApplicationState,
	params: BaseParams,
) {
	const fetchTask = request<DashboardCounters>('dashboardCountersList', params, getState())
		.then((data: DashboardCounters) => {
			dispatch({
				type: TypeKeys.RECEIVEDASHBOARDCOUNTERS,
				items: data,
			});

			return data;
		})
		.catch((error: string) => {
			dispatch({
				type: TypeKeys.REQUESTDASHBOARDCOUNTERSERROR,
				error,
			});
		});

	dispatch({ type: TypeKeys.REQUESTDASHBOARDCOUNTERS });

	return fetchTask;
}

const currentCount = 30;
const baseParams = { count: currentCount, offset: 0 };
export function getDashboardActionCreators() {
	return {
		request: (
			params?: BaseParams,
		): AppThunkAction<KnownDashboardCountersActions> => (dispatch, getState) => {
			const storeState = getState().dashboardCounters;

			if (!equal(storeState.params, params)) {
				return loadPage(dispatch, getState, params ?? baseParams);
			}

			return Promise.resolve(storeState.items);
		},
		update: (
			items: Array<DashboardEntity>,
			itemName: string,
			paramName: string = 'order',
		) => (dispatch) => {
			dispatch({
				items,
				itemName,
				paramName,
				type: TypeKeys.UPDATEDASHBOARDCOUNTERS,
			});
		},
		loadMoreItems: (
			endpoint: string,
			itemName: string,
		) => (dispatch, getState) => {
			const storeState = getState().dashboardCounters.items[itemName];

			const params = {
				...storeState.count,
				offset: (storeState.offset ?? 0) + currentCount,
			};

			request(endpoint, params, getState())
				.then((data) => {
					dispatch({
						type: TypeKeys.RECEIVEDASHBOARDCOUNTERSMORE,
						items: data,
						itemName,
					});
				})
				.catch((error: string) => dispatch({
					type: TypeKeys.REQUESTDASHBOARDCOUNTERSERROR,
					error,
				}));

			dispatch({ type: TypeKeys.REQUESTDASHBOARDCOUNTERSMORE });
		},
		refreshPages: (
			params?: BaseParams,
		): AppThunkAction<KnownDashboardCountersActions> => (dispatch, getState) =>
			loadPage(dispatch, getState, params ?? baseParams),
	};
}

export function getDashboardCountersReducer<T extends DashboardCounters>(): Reducer<DashboardCountersState<T>> {
	return (
		state: DashboardCountersState<T> | undefined,
		incomingAction: Action,
	) => {
		const result = state ?? getDefaultDashboardCountersState();
		const action = incomingAction as KnownDashboardCountersActions;

		switch (action.type) {
		case TypeKeys.REQUESTDASHBOARDCOUNTERS: {
			return { ...result, isLoading: true };
		}
		case TypeKeys.RECEIVEDASHBOARDCOUNTERS: {
			return {
				...result,
				isLoading: false,
				items: action.items as T,
			};
		}
		case TypeKeys.UPDATEDASHBOARDCOUNTERS: {
			if (action.items === null) return result;

			return {
				...result,
				[action.itemName]: action.items,
			};
		}
		case TypeKeys.REQUESTDASHBOARDCOUNTERSMORE:
			return { ...result, isLoading: true };
		case TypeKeys.RECEIVEDASHBOARDCOUNTERSMORE:
			return {
				...result,
				items: {
					...result.items,
					[action.itemName]: {
						...action.items,
						list: result.items[action.itemName].list.concat(action.items.list),
					},
				},
				isLoading: false,
			};
		case TypeKeys.REQUESTDASHBOARDCOUNTERSERROR:
			return { ...result, isLoading: false, error: action.error };

		default:
			return result;
		}
	};
}
