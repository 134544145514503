export class OnScrollManager {
	private pause: boolean = false;
	private throttle(callback: () => void, timeout: number) {
		if (this.pause) return;

		this.pause = true;

		setTimeout(() => {
			callback();

			this.pause = false;
		}, timeout);
	}

	public onScroll(target: HTMLElement, isLoading: boolean, hasMore: boolean, onLoad: () => void): void {
		const height = target.offsetHeight;
		const screenHeight = target.scrollHeight;
		const scrolled = Math.floor(target.scrollTop);

		const threshold = height - screenHeight / 4;
		const position = scrolled + screenHeight;

		if (!isLoading && hasMore && position >= threshold) this.throttle(onLoad, 500);
	}
}
