import { Nullable, Optional } from '@common/typescript/objects/Nullable';

import { StoreEntry, StoreEntryNode } from '@app/objects/StoreEntry';

export function allowAdd(entry: Optional<StoreEntry>, selection: Optional<StoreEntryNode>, nodeId: Nullable<number>): boolean {
	if (!entry) return false;
	const id = selection?.id ?? nodeId;

	const query = entry.tree
		.filter((node: StoreEntryNode) => node.parentId === id)
		.filter((node: StoreEntryNode) => !node.disabled)
		.map((node: StoreEntryNode) => node.valueId);

	return !query.length;
}
