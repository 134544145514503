import * as React from 'react';

import { WithFile } from '@common/typescript/objects/WithFile';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { Nullable } from '@common/typescript/objects/Nullable';

import '@common/react/scss/components/upload-list.scss';

export type OnProgress = (value: Nullable<number>) => void;

interface UploadListProps<TEntity, TUser extends BaseUser, T extends WithFile<TEntity, TUser>> {
	list: Array<T>;

	renderItem: (item: T) => React.ReactElement;
	renderProgress?: (progress: number) => React.ReactElement;
	renderUpload?: (onProgress: OnProgress) => React.ReactElement;

	max?: number;
}

export const UploadList = <
	TEntity,
	TUser extends BaseUser,
	T extends WithFile<TEntity, TUser>
>(props: UploadListProps<TEntity, TUser, T>) => {
	const [progress, setProgress] = React.useState<Nullable<number>>(() => null);

	const canAdd = typeof props.max === 'number' ? props.list.length < props.max : true;
	const list = typeof props.max === 'number' ? props.list.slice(0, props.max) : props.list;

	return (
		<ul className="upload-list">
			{
				list.map((item: T) => (
					<li className="upload-list__" key={item.id}>
						{props.renderItem(item)}
					</li>
				))
			}
			{
				props.renderProgress && progress !== null
					? <li className="upload-list__item">{props.renderProgress(progress)}</li>
					: null
			}
			{
				canAdd && progress === null
					? <li className="upload-list__item">{props.renderUpload?.(setProgress)}</li>
					: null
			}
		</ul>
	);
};
