import * as React from 'react';

import { Field, FieldProps } from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import { Nullable } from '@common/typescript/objects/Nullable';
import { ModalPreview } from '@common/react/components/Forms/Upload/ModalPreview';

import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { PetFile } from '@app/objects/PetFile';
import { UploadDocuments } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DocumentsSection/UploadDocuments';

export const DocumentsSection: React.FC = () => {
	const [file, setFile] = React.useState<Nullable<FileInterface>>(null);

	return (
		<SectionContainer titleLocalizationKey="sections.documents">
			<Field name="files">
				{(fieldProps: FieldProps<PetFormValues>) => (
					<FormikInput
						fieldProps={fieldProps}
						title=""
						containerClassName="form-group"
						render={({ field, form } : FieldProps<Array<PetFile>, PetFormValues>) => (
							<UploadDocuments
								petId={form.values.id}
								items={field.value}

								onChange={(items: Array<PetFile>) => form.setFieldValue(field.name, items, false)}
								onChangeFile={setFile}

								type="pet"
								maxSizeCaption="File should not exceed 20 mb"
							/>
						)}
					/>
				)}
			</Field>
			<ModalPreview
				file={file}
				onClose={() => setFile(null)}
			/>
		</SectionContainer>
	);
};
