import React from 'react';

import Table, { ColumnProps } from 'antd/lib/table';

import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';

import { PetPrice } from '@app/objects/Pet';
import { getUserName } from '@app/objects/User';

interface KitServiceTableProps {
	serviceKits: Array<PetPrice>;
}

const columns: Array<ColumnProps<PetPrice>> = TableBuilder.shape<PetPrice>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (text: string) => <MobileCell caption="Name">{text || '-'}</MobileCell>,
	})
	.addColumn({
		title: <b>Status</b>,
		dataIndex: 'done',
		render: (value: boolean) => (value ? 'Completed' : 'Not completed'),
	})
	.addColumn({
		align: 'left',
		title: <b>Changed by</b>,
		render: (_, record: PetPrice) => {
			if (record.editorId === null) return '-';
			if (record.editor === null) return 'Unknown user';

			return getUserName(record.editor);
		},
	})
	.build();

export const KitServiceTable: React.FC<KitServiceTableProps> = ({ serviceKits }: KitServiceTableProps) => {
	return (
		<div className="table-card form-group">
			<Table
				columns={columns}
				dataSource={serviceKits}
				childrenColumnName="child"
				rowKey="id"
				className="table-mobile small-padding-table"
				pagination={{ hideOnSinglePage: true }}
			/>
		</div>
	);
};
