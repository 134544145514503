import * as React from 'react';

import Alert from 'antd/lib/alert';
import Space from 'antd/lib/space';
import { Form, Formik, FormikProps } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import { Pet, PetPrice } from '@app/objects/Pet';
import { SpecialServicesList } from '@app/components/UI/Status/SpecialServicesList';
import { request } from '@app/components/Api';
import {
	getNewServicesArray,
	getCurrentServices,
	restorePrices,
	FormValues,
} from '@app/utilities/services';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { DefaultInfo } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/DefaultInfo';

interface SpecialServicesFormProps {
	item: Pet;
	onChange: (item: Partial<Pet>) => void;
	onClose: () => void;
}

export const SpecialServicesForm: React.FC<SpecialServicesFormProps> = (props: SpecialServicesFormProps) => {
	const item = props.item;
	const [error, setError] = React.useState<Nullable<string>>(() => null);

	const services = getCurrentServices(item);

	return (
		<Formik
			initialValues={{ services }}
			onSubmit={(values: FormValues, { setSubmitting }) => {
				const allArray = getNewServicesArray(services, values);
				request<Array<PetPrice>>('updateServices', { services: allArray.changed })
					.then((items: Array<PetPrice>) =>
						props.onChange({ id: item.id, services: restorePrices(services, allArray.unchanged.concat(items)) }))
					.catch(setError)
					.finally(() => setSubmitting(false));
			}}
			enableReinitialize
		>
			{(formik: FormikProps<FormValues>) => (
				<Form>
					<StatusModalController width={400} />

					<Space direction="vertical" size="middle">
						<DefaultInfo item={item} />

						<SpecialServicesList
							services={formik.values.services}
							onChange={(service: PetPrice) => {
								const newValues = formik.values.services.map((item: PetPrice) => {
									if (item.id === service.id) return service;

									return item;
								});
								formik.setFieldValue('services', newValues);
							}}
						/>
						<Alert
							type="warning"
							message="All special services have to be completed"
							closable
							onClose={(event: React.MouseEvent) => event.stopPropagation()}
						/>
						{
							error ? (
								<Alert
									type="error"
									message={error}
									closable
									onClose={(event: React.MouseEvent) => event.stopPropagation()}
								/>
							) : null
						}
					</Space>
					<ModalButtons onClose={props.onClose} />
				</Form>
			)}
		</Formik>
	);
};
