import {
	ActionCreatorsMapObject,
	bindActionCreators,
	Dispatch,
	Reducer,
} from 'redux';

import { BaseAppThunkAction } from '@common/react/store';

import { User } from '@app/objects/User';
import { ApplicationState, AppThunkAction } from '@app/store/index';
import { request } from '@app/components/Api';

export interface NotLoggedState {
	isLoading: boolean;
	page: PageResponse;
}

const defaultState: NotLoggedState = {
	isLoading: false,
	page: {
		id: -1,
		name: '',
		pagepath: '',
		text: '',
	},
};

export enum TypeKeys {
	RequestPage = 'REQUESTPAGE',
	ReceivePage = 'RECIEVEPAGE',
}

export interface PageResponse {
	id: number;
	name: string;
	pagepath: string;
	text: string;
}

interface RequestPageAction {
	type: TypeKeys.RequestPage;
}

interface ReceivePageAction {
	type: TypeKeys.ReceivePage;
	page: PageResponse;
}

type KnownPageActions = RequestPageAction | ReceivePageAction;

export interface NotLoggedActionCreators extends ActionCreatorsMapObject<BaseAppThunkAction<KnownPageActions, User, ApplicationState>> {
	reqPages: (path: string) => BaseAppThunkAction<KnownPageActions, User, ApplicationState>;
}

export function getNotLoggedActionCreators(): NotLoggedActionCreators {
	function reqPages(path: string): AppThunkAction<KnownPageActions> {
		return (dispatch, getState) => {
			request<PageResponse>('getPage', {
				path,
			}, getState()).then((data: PageResponse) => {
				dispatch({ type: TypeKeys.ReceivePage, page: data });
			});

			dispatch({ type: TypeKeys.RequestPage });
		};
	}

	return { reqPages };
}

export const notLoggedReducer: Reducer<NotLoggedState, KnownPageActions> = (
	state: NotLoggedState = defaultState,
	action: KnownPageActions,
) => {
	switch (action.type) {
	case TypeKeys.RequestPage:
		return { ...state, isLoading: true };

	case TypeKeys.ReceivePage:
		return { ...state, isLoading: false, page: action.page };

	default:
		return state;
	}
};

export function bindNotLoggedActionCreators(dispatch: Dispatch<KnownPageActions>): NotLoggedActionCreators {
	return bindActionCreators<NotLoggedActionCreators, NotLoggedActionCreators>(getNotLoggedActionCreators(), dispatch);
}
