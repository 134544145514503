export interface WithName {
	name: string;
}

export function isWithName(item: unknown): item is WithName {
	if (typeof item !== 'object') return false;
	if (!item) return false;

	return typeof (item as WithName).name === 'string';
}
