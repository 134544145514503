import React from 'react';
import { Col, Row } from 'antd/lib/grid';

import { Pet } from '@app/objects/Pet';
import { ValueDisplay } from '@app/components/UI/ValueDisplay/ValueDisplay';

interface DefaultInfoProps {
	item: Pet;
}

export const DefaultInfo: React.FC<DefaultInfoProps> = ({ item }: DefaultInfoProps) => (
	<Row gutter={[30, 16]}>
		<Col xs={24} sm={24} md={12}>
			<ValueDisplay title="Reported Weight" value={item.reportedWeight.toFixed(1)} />
		</Col>
		<Col xs={24} sm={24} md={12}>
			<ValueDisplay title="Actual Weight" value={item.actualWeight.toFixed(1)} />
		</Col>
	</Row>
);
