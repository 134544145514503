import * as React from 'react';

export interface ModalControls {
	state: boolean; // True - open, false - closed
	setState: (value: boolean) => void;

	open: () => void;
	close: () => void;
}

/**
 * Standard Modal control params - state and change state functions
 * @param {boolean} defaultOpen
 * @returns {ModalControls}
 */
export function useModal(defaultOpen: boolean): ModalControls {
	const [state, setState] = React.useState<boolean>(() => defaultOpen);

	return React.useMemo<ModalControls>(() => ({
		state,
		setState,

		open: () => setState(true),
		close: () => setState(false),
	}), [state]);
}
