import { Nullable } from '@common/typescript/objects/Nullable';

import { Country } from '@app/objects/Country';

type MailKey = 'zip' | 'postal' | 'mail-default';
type RegionKey = 'state' | 'province' | 'region-default';
type TaxKey = 'tax' | 'hst' | 'tax-default' | 'gst';

export interface AddressDetails {
	country: string;

	mailKey: MailKey;
	regionKey: RegionKey;
	taxKey: TaxKey;

	withMail: boolean;
	withRegion: boolean;
}

interface DetailsProvider {
	(country: Nullable<string> | undefined): AddressDetails;
	(country: Nullable<Country> | undefined): AddressDetails;
}

export interface AddressService {
	readonly provide: DetailsProvider;
}

function getCaption(country: Nullable<Country> | Nullable<string> | undefined): string {
	if (!country) return 'unknown';

	return typeof country === 'string' ? country : country.alpha3;
}

function provideAddress(country: Nullable<Country> | Nullable<string> | undefined): AddressDetails {
	const caption = getCaption(country);

	if (caption === 'CAN') {
		return {
			country: caption,

			mailKey: 'postal',
			regionKey: 'province',
			taxKey: 'hst',

			withMail: true,
			withRegion: true,
		};
	}

	if (caption === 'USA') {
		return {
			country: caption,

			mailKey: 'zip',
			regionKey: 'state',
			taxKey: 'tax',

			withMail: true,
			withRegion: true,
		};
	}

	if (caption === 'AUS') {
		return {
			country: caption,

			mailKey: 'zip',
			regionKey: 'state',
			taxKey: 'tax',

			withMail: true,
			withRegion: true,
		};
	}

	if (caption === 'NZL') {
		return {
			country: caption,

			mailKey: 'zip',
			regionKey: 'state',
			taxKey: 'gst',

			withMail: true,
			withRegion: true,
		};
	}

	if (caption === 'GBR') {
		return {
			country: caption,

			mailKey: 'zip',
			regionKey: 'state',
			taxKey: 'tax',

			withMail: true,
			withRegion: false,
		};
	}

	return {
		country: caption,

		mailKey: 'mail-default',
		regionKey: 'region-default',
		taxKey: 'tax-default',

		withMail: false,
		withRegion: false,
	};
}

export const addressService: AddressService = {
	provide: provideAddress,
};
