import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { Pet, PetPrice, PetUrn } from '@app/objects/Pet';
import { StatusRecord } from '@app/objects/StatusRecord';
import { User } from '@app/objects/User';

export enum PromoteStatusAction {
	Manual = 0,
	Batch = 1,
	Force = 2,
}

export enum Status {
	ReadyToPickUp,
	PlannedPickUpDate,
	PickUpInTransit,
	AtCrematory,
	InProcess,
	ReadyForCremation,
	BeingCremated,
	DryingCooling,
	AshesPulvarized,
	UrnEngraved,
	Packed,
	ReadyToDelivery,
	Delivered,
	Invoiced,
	Completed
}

export enum AllStatuses {
	ReadyToPickUp,
	PlannedPickUpDate,
	PickUpInTransit,
	AtCrematory,
	InProcess,
	ReadyForCremation,
	BeingCremated,
	DryingCooling,
	AshesPulvarized,
	UrnEngraved,
	Packed,
	ReadyToDelivery,
	Delivered,
	Invoiced,
	Completed,
	TotalAtCrematory,
	OutOfDelivery,
	ToBeSpread
}

export type History = StatusRecord

// Change History

export enum DiffAction {
	Added = 0,
	Updated = 1,
	Deleted = 2,
}

export interface DiffChange<T> {
	diffRecord: WithDiff<T>;
	diffRecordId: number;
	key: string;
	before: string;
	after: string;
}

interface WithDiff<T> extends WithId {
	object: T;
	objectId: number;
	diff: string;
	changeTime: number;
	user: User;
	userId: number;
	updateTransaction: UpdateTransaction;
	updateTransactionId: number;
	diffAction: DiffAction;
	removed: boolean;
	changes: Array<DiffChange<T>>;
}

export type PetDiffs = WithDiff<Pet>
export type PetPriceDiffs = WithDiff<PetPrice>
export type PetUrnDiffs = WithDiff<PetUrn>

export interface UpdateTransaction extends WithId {
	time: number;
	user: Nullable<User>;
	userId: number;

	pet: Nullable<Pet>;
	petId: number;

	petDiff: PetDiffs;
	petPriceDiffs: Array<PetPriceDiffs>;
	petUrnDiffs: Array<PetUrnDiffs>;
}
