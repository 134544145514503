// entity.ts
/**
 * ## Entity.ts ##
 * This file contains some utility functions for entity objects
 * @packageDocumentation
 */

import { WithId } from '@common/typescript/objects/WithId';

/**
 * This type is used to describe certain object fields
 */
type FieldsType<TEntity extends WithId> = Array<keyof TEntity> | undefined;

/**
 * This function tests item if it has not been saved on server
 * @typeParam TEntity Any WithId entity
 * @param item The item being verified
 * @returns True if item has not been saved yet
 */
export function checkUnsaved<TEntity extends WithId>(item: TEntity): boolean { return item.id < 0; }

/**
 * This function tests item if it has been saved on server
 * @typeParam TEntity Any WithId entity
 * @param item The item being verified
 * @returns True if item has been saved
 */
export function checkSaved<TEntity extends WithId>(item: TEntity): boolean { return item.id > 0; }

/**
 * Makes shallow comparison of original and updated objects and returns their difference.
 * Example:
 *
 * ```typescript
 *  interface IExample {
 *      intact: string;
 *      field: string;
 *  }
 *  const a: IExample = {intact: 'Intact field', field: 'Original field'};
 *  const b: IExample = {intact: 'Intact field', field: 'Updated field'};
 *
 *  const result: Partial<IExample> = getShallowChanged(a, b);
 *
 * ```
 *
 * Result:
 * ```typescript
 *
 *  {field: 'Updated field'}
 *
 *  ```
 *
 * Pay attention: comparison is shallow meaning inlaid objects/arrays are compared by reference, not by value.
 *
 * @typeParam TEntity Any WithId entities which instances are being compared
 *
 * @param original This is original item (before updated)
 * @param updated This is updated item (some of its values have been changed)
 *
 * @returns Object containing only changed fields (those that differ in original and updated, values are taken from updated)
 */
export function getShallowChanges<TEntity extends WithId>(original: TEntity, updated: TEntity): Partial<TEntity> & WithId {
	const keys = Object.keys(original);
	const result: Partial<TEntity> & WithId = { id: original.id } as Partial<TEntity> & WithId;

	keys.forEach((key: string) => {
		if (updated[key] !== original[key]) {
			result[key] = updated[key];
		}
	});

	return result;
}

/**
 * Shallow comparison of two objects
 * returns true if they are equal and false if they are not
 *
 * Examples
 * ```typescript
 * const a = {id: 4, value: 'value'};
 * const b = {id: 4, value: 'value'};
 * const c = {id: 5, value: 'value'};
 *
 * const res1 = shallowCompare(a, b); // returns true
 * const res2 = shallowCompare(a, c); // returns false
 *
 * const obj1 = {id: 1, value: {message: 'value'}};
 * const obj2 = {id: 1, value: {message: 'value'}};
 *
 * const res3 = shallowCompare(obj1, obj2); // returns false as values have different REFERENCES
 * ```
 *
 * @param {TEntity} a - first entity
 * @param {TEntity} b - second entity
 * @return {boolean} - result of comparison
 */
export function shallowCompare<TEntity extends object>(a: TEntity, b: TEntity): boolean {
	return Object.keys(a).reduce<boolean>((acc, key) => acc && (a[key] === b[key]), true);
}

/**
 * Returns a list of items from `updated` that are not found in `original`
 * This should represent all added items, updated items and removed items, if they are marked as 'deleted'
 * @param {Array<TEntity>} original - this is an original array (before update)
 * @param {Array<TEntity>} updated - this is an updated array
 * @returns {Array<TEntity>} - the difference between `original` and `updated` arrays
 */
export function getArrayChanges<TEntity extends WithId>(original: Array<TEntity>, updated: Array<TEntity>): Array<TEntity> {
	return updated.filter((item: TEntity) => !original.some((source: TEntity) => shallowCompare<TEntity>(item, source)));
}

/**
 * Clears inlaid objects of entity (sets them to null). If fields is specified - clears only specified fields (only if they are of object type).
 * The object is cleared in place, which means that original object is being changed. It is also returned as result of a function (for chaining).
 *
 * Example:
 * ```typescript
 *
 * const a = {id: 'a', obj: {id: 'a-inner'}, count: 1};
 * const resultA = clearEntities(a);
 *
 * const b = {id: 'b', obj: {id: 'b-inner'}, count: 2};
 * const resultB = clearEntities(b, ['id', 'obj']);
 *
 * ```
 *
 * Result:
 * ```typescript
 *
 * resultA = a = {id: 'a', obj: null, count: 1};
 * resultB = b = {id: 'b': obj: null, count: 2};
 *
 * ```
 * @typeParam TEntity Any WithId entity
 *
 * @param entity Entity that needs to have (some) fields cleared (reset to null)
 * @param fields (optional) Array of TEntity fields that need to be set to null.
 * Only works for fields that reference objects. Clears all object fields of TEntity if no 'fields' is specified
 *
 * @returns Cleared entity. It does change original entity as well.
 */
export function clearEntities<TEntity extends WithId>(entity: TEntity, fields: FieldsType<TEntity> = undefined): TEntity {
	const result = entity; // Same as assigning to entity, but without eslint warnings
	const keys = fields === undefined ? Object.keys(entity) : fields;
	keys.forEach((key) => {
		if (typeof entity[key] === 'object') {
			result[key] = null;
		}
	});

	return result;
}

/**
 * Creates a new Record from source picking only specified fields
 * @param {TEntity} source - original object to copy data from
 * @param {Array<keyof TEntity>} fields - an array of keys/fields of original object that thould be copied
 * @returns {Partial<TEntity>}
 */
export function maskedShallowCopy<TEntity extends object>(source: TEntity, fields: Array<keyof TEntity>): Partial<TEntity> {
	const keys = Object.keys(source).filter((key: string) => fields.includes(key as keyof TEntity));
	const result: Partial<TEntity> = {};

	keys.forEach((key: string) => { result[key] = source[key]; });

	return result;
}

/**
 * Gets all items of `updated` that are different from same item in `original` or not present in `original`
 *
 * Example:
 * ```typescript
 *
 * const original = [{ id: 0, value: 5 }, { id: 1, value: 4  }];
 * const updated = [{ id: 0, value: 5 }, { id: 1, value: 3 }, { id: 2, value: 4 }];

 * const result = pickDifference(original, updated);)
 *
 * ```
 *
 * Result:
 * ```typescript
 *	result = [{ id: 1, value: 3 }, { id: 2, value: 4 }];
 * ```
 *
 * @param {Array<TEntity>} original - original array of items
 * @param {Array<TEntity>} updated - updated array of items
 * @returns {Array<TEntity>} items from `updated` that are different from `original`
 */
export function pickDifference<TEntity extends WithId>(original: Array<TEntity>, updated: Array<TEntity>): Array<TEntity> {
	const result: Array<TEntity> = [];

	updated.forEach((q: TEntity) => {
		if (q.id <= 0) {
			result.push(q);
		} else {
			const reference = original.find((item: TEntity) => item.id === q.id);

			if (reference === undefined || !shallowCompare(reference, q)) {
				result.push(q);
			}
		}
	});

	return result;
}
