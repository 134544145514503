import { UserRole } from '@app/objects/User';

export class RoleManager {
	private readonly _role: UserRole;

	static for(role: UserRole): RoleManager {
		return new RoleManager(role);
	}

	public constructor(role: UserRole) {
		this._role = role;
	}

	public isClinicGroup(): boolean {
		return this._role === UserRole.ClinicUser || this._role === UserRole.ClinicManager;
	}

	public isCrematoryGroup(): boolean {
		return this._role === UserRole.Crematory || this._role === UserRole.CrematoryUser;
	}

	public isAdmin(): boolean {
		return this._role === UserRole.Admin;
	}

	public isCrematory(): boolean {
		return this._role === UserRole.Crematory;
	}

	public isCrematoryUser(): boolean {
		return this._role === UserRole.CrematoryUser;
	}

	public isClinicManager(): boolean {
		return this._role === UserRole.ClinicManager;
	}

	public isClinicUser(): boolean {
		return this._role === UserRole.ClinicUser;
	}

	public isSystemService(): boolean {
		return this._role === UserRole.SystemService;
	}

	public isIntegrationService(): boolean {
		return this._role === UserRole.IntegrationService;
	}

	public is(oneOf: Array<UserRole>) {
		return oneOf.some((item: UserRole) => this._role === item);
	}
}
