import * as React from 'react';
import {
	Route, RouteProps, RouteComponentProps, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

import { User, UserRole } from '@app/objects/User';
import { Title } from '@app/components/UI/Header/Title';
import { ApplicationState } from '@app/store';

export interface InnerRouteProps extends RouteProps {
	title?: string;
	redirectPath?: string;
	allowRoles?: Array<UserRole>;
}

interface ReduxProps {
	user: User | null;
}

type InjectedInnerRouteProps = InnerRouteProps & ReduxProps;

const InjectedInnerRoute: React.FC<InjectedInnerRouteProps> = ({
	component: Component, user, redirectPath = '/', allowRoles, title, ...rest
}) => {
	const isAllowed = user && (allowRoles === undefined || allowRoles.indexOf(user.role) > -1);

	const Page = React.useCallback(
		(props: RouteComponentProps) =>
			(Component ? <Component {...props} /> : null),
		[Component],
	);

	const RoutedPage = (props: RouteComponentProps) => (isAllowed ? (
		<>
			<Title title={title} />
			<Page {...props} />
		</>
	) : <Redirect to={redirectPath} />);

	return (
		<Route
			{...rest}
			render={RoutedPage}
		/>
	);
};

export const InnerRoute = connect<ReduxProps, never, InnerRouteProps, ApplicationState>(
	(state: ApplicationState) => ({
		user: state.login.user,
	}),
)(InjectedInnerRoute);
