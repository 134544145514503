import * as dayjs from 'dayjs';

import { Nullable } from '@common/typescript/objects/Nullable';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { List, transformArrayToList } from '@common/typescript/objects/List';

import { User } from '@app/objects/User';
import { Crematory } from '@app/objects/Crematory';
import { Clinic } from '@app/objects/Clinic';
import { InvoicePet } from '@app/objects/Pet';
import { InvoiceAdjustment } from '@app/objects/Adjustment';
import { QuickbooksInvoice } from '@app/objects/Quickbooks';

export enum DueDate {
	Net15 = 0,
	Net30 = 1,
}

export enum InvoiceTerms {
	DueOnReceipt,
	Net15,
	Net30,
	Custom
}

export enum InvoiceStatus {
	Open,
	Paid
}

export interface Invoice extends WithDeleted {
	startDate: number | null;
	endDate: number | null;

	userId: number;
	user?: User;

	clinicId: number;
	clinic: Clinic | null;

	crematoryId: number;
	crematory: Crematory | null;

	time: number;

	from: number;
	to: number;

	dueDate: number|null;

	guid: string;
	number: string;

	terms: InvoiceTerms | -1;

	status: InvoiceStatus;

	pets: List<InvoicePet>;

	adjustments: Array<InvoiceAdjustment>;

	dirty: boolean;

	quickbooksInvoice: Nullable<QuickbooksInvoice>;

	invoiceSummary: InvoiceSummary;
}

export interface InvoiceSummary {
	total: number;
	basePriceTotal: number;
	productPriceTotal: number;
	urnPriceTotal: number;
	specialServicePriceTotal: number;
	deliveryPriceTotal: number;
	pickupFeesTotal: number;
	adjustmentPriceTotal: number;
	productTaxTotal: number;
	serviceTaxTotal: number;
}

export function getDateByTerms(terms: InvoiceTerms, start: number): number | null {
	const now = dayjs(start);

	let date: number | null;

	switch (terms) {
	case InvoiceTerms.DueOnReceipt:
		date = +now.endOf('day');
		break;
	case InvoiceTerms.Net15:
		date = +now.add(15, 'days');
		break;
	case InvoiceTerms.Net30:
		date = +now.add(30, 'days');
		break;
	case InvoiceTerms.Custom:
		date = null;
		break;

	default:
		date = null;
	}

	return date;
}

export function getDefaultInvoiceSummary(): InvoiceSummary {
	return {
		total: 0,
		basePriceTotal: 0,
		productPriceTotal: 0,
		urnPriceTotal: 0,
		specialServicePriceTotal: 0,
		deliveryPriceTotal: 0,
		pickupFeesTotal: 0,
		adjustmentPriceTotal: 0,
		productTaxTotal: 0,
		serviceTaxTotal: 0,
	};
}

export function getDefaultInvoice(): Invoice {
	return {
		id: -1,

		clinicId: -1,
		clinic: null,

		crematoryId: -1,
		crematory: null,

		dueDate: null,
		startDate: null,
		endDate: null,

		from: 0,
		to: 0,
		time: new Date().getTime(),

		guid: '',
		number: '',

		pets: transformArrayToList([]),
		status: InvoiceStatus.Open,
		terms: -1,

		userId: 0,

		adjustments: [],

		dirty: false,
		quickbooksInvoice: null,

		invoiceSummary: getDefaultInvoiceSummary(),
	};
}
