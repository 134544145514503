import * as React from 'react';
import {Redirect} from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import {connect} from 'react-redux';

import {BaseApplicationState} from '@common/react/store';
import {BaseUser} from '@common/react/objects/BaseUser';

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
}

interface ReduxProps {
	user: BaseUser | null;
}

const PrivateRoute: React.SFC<Props & ReduxProps> = ({ component: Component, user, redirectPath = '/', ...rest }) => (
	<Route 
		{...rest} 
		render={props => user ? <Component {...props} /> : <Redirect to={redirectPath} />} 
	/>
);

export default connect<ReduxProps, {}, Props, BaseApplicationState<BaseUser>>(
	(state: BaseApplicationState<BaseUser>) => ({
		user: state.login.user
	})
)(PrivateRoute);
