import * as React from 'react';

import Select from 'antd/lib/select';

import { OptionProps } from '@app/components/UI/Select/SelectProps';

export const Option: React.FC<OptionProps> = (props: OptionProps) => {
	return (
		<Select.Option
			value={props.value ?? undefined}
			title={props.title}
			disabled={props.disabled}
			className={props.className}
			style={props.style}
		>
			{props.children}
		</Select.Option>
	);
};
