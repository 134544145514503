import * as React from 'react';
import * as Yup from 'yup';
import {
	Field, FieldProps, Form, Formik,
} from 'formik';
import Checkbox from 'antd/lib/checkbox';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { Pet } from '@app/objects/Pet';
import { OldPetHistoryChange } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';

interface FormValues {
	confirmChanges: boolean;
}

interface HistoryChangeFormProps {
	item: Pet;

	onChange: (item: Partial<Pet>, shouldClose?: boolean, params?: PromoteParams) => void;
	onClose?: () => void;
}

const validationSchema = Yup.object().shape({
	confirmChanges: Yup.boolean().oneOf([true], 'You should confirm changes review'),
});

export const HistoryChangeForm: React.FC<HistoryChangeFormProps> = (props: HistoryChangeFormProps) => {
	const {
		item,
		onChange,
		onClose,
	} = props;

	const [error, setError] = React.useState<string | null>(null);

	return (
		<Formik
			initialValues={{ confirmChanges: false }}
			onSubmit={({ confirmChanges }, { setSubmitting }) => {
				onChange(item, undefined, { confirmChanges });
				setSubmitting(false);
			}}
			validationSchema={validationSchema}
		>
			{() => (
				<Form id="history-change-form">
					<StatusModalController width={400} />
					<OldPetHistoryChange
						pet={item}
						setError={setError}
						pending
					/>
					{!error
						? (
							<Field name="confirmChanges">
								{(fieldProps: FieldProps<FormValues>) => (
									<FormikInput
										fieldProps={fieldProps}
										containerClassName=""
										render={({ field, form }: FieldProps<boolean, FormValues>) => (
											<Checkbox
												checked={field.value}
												onClick={() => form.setFieldValue(field.name, !field.value)}
												style={{ fontWeight: 'normal' }}
											>
												Confirm changes reviewed
											</Checkbox>
										)}
									/>
								)}
							</Field>
						)
						: null}

					<ModalButtons onClose={() => onClose?.()} okText="Ok" disabled={Boolean(error)} />
				</Form>
			)}
		</Formik>
	);
};
