import * as React from 'react';

import { IStatusModalContext, StatusModalContext } from '@app/components/UI/Status/StatusModal/index';

type OwnProps = IStatusModalContext['modal'];

export const StatusModalController: React.FC<OwnProps> = (props: OwnProps) => {
	const context = React.useContext<IStatusModalContext>(StatusModalContext);

	React.useEffect(() => {
		const value = {
			...context,
			modal: {
				...context,
				...props,
			},
		};

		context.update(value);
	}, [props.width]);

	return null;
};
