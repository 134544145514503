import * as React from 'react';

interface VersionPanelProps {
	display?: boolean;
}

export const VersionPanel: React.FC<VersionPanelProps> = ({ display }: VersionPanelProps) => {
	if (!display) return null;

	return (
		<ul className="version-panel">
			<li>
				<span className="version-panel__label">Environment: &nbsp;</span>
				<span className="version-panel__value">{globals.environment}</span>
			</li>
			<li>
				<span className="version-panel__label">Version: &nbsp;</span>
				<span className="version-panel__value">{globals.version}</span>
			</li>
		</ul>
	);
};

VersionPanel.defaultProps = {
	display: false,
};
