import * as React from 'react';

import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';

import { Pet } from '@app/objects/Pet';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { DefaultInfo } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/DefaultInfo';

interface BatchFormProps {
	item: Pet;
	onClose: () => void;
}

export const BatchForm: React.FC<BatchFormProps> = (props: BatchFormProps) => {
	const item = props.item;

	return (
		<>
			<StatusModalController width={400} />

			<Space direction="vertical" size="middle" style={{ width: '100%' }}>
				<DefaultInfo item={item} />

				<Alert
					type="warning"
					message={(
						<span>
							Pet is attached to batch #{item.batchActionId} and its status should not be changed manually
						</span>
					)}
					closable
					onClose={(event: React.MouseEvent) => event.stopPropagation()}
				/>
				<div className="text-right" style={{ marginTop: '8px', paddingTop: '8px', borderTop: '1px solid #e8e8e8' }}>
					<Button
						danger
						size="small"
						onClick={props.onClose}
					>
						Cancel
					</Button>
				</div>

			</Space>
		</>
	);
};
