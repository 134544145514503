import * as Yup from 'yup';

import { phoneFormat } from '@app/components/Utils/Utils';

export function idValidator(field: string): Yup.NumberSchema {
	const validationMessage = `${field} is required`;

	return Yup.number()
		.moreThan(0, validationMessage)
		.required(validationMessage)
		.typeError(validationMessage);
}

export function stringValidator(field: string): Yup.StringSchema {
	const validationMessage = `${field} is required`;

	return Yup.string()
		.required(validationMessage)
		.typeError(validationMessage);
}

export function emailValidator(field: string, required: boolean = true): Yup.StringSchema {
	const validationMessage = `${field} is required`;

	let query = Yup.string()
		.email('Incorrect email format');

	if (required) {
		query = query.required(validationMessage)
			.typeError(validationMessage);
	}

	return query;
}

export function phoneValidator(field: string, required: boolean = true): Yup.StringSchema {
	const validationMessage = `${field} is required`;

	let query = Yup.string()
		.matches(phoneFormat, 'Incorrect phone format');

	if (required) {
		query = query.required(validationMessage)
			.typeError(validationMessage);
	}

	return query;
}

export function positiveNumberValidator(field: string): Yup.NumberSchema<number | null> {
	const validationMessage = `${field} is required`;

	return Yup.number()
		.positive(`${field} should be positive`)
		.required(validationMessage)
		.typeError(validationMessage);
}

export function enumValidator(field: string): Yup.NumberSchema {
	const validationMessage = `${field} is required`;

	return Yup.number()
		.moreThan(-1, validationMessage)
		.required(validationMessage)
		.typeError(validationMessage);
}

export function withZeroNumberValidator(field: string): Yup.NumberSchema {
	const validationMessage = `${field} is required`;

	return Yup.number()
		.integer(`${field} should be an integer`)
		.moreThan(-1, `${field} should not be negative`)
		.required(validationMessage)
		.typeError(validationMessage);
}
