import * as React from 'react';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
	generatePath, Link, RouteComponentProps, withRouter,
} from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { member } from '@common/react/utils/decorators';

import * as LoginState from '@app/store/Login';
import { ApplicationState } from '@app/store';
import { UserComponent } from '@app/components/UI/Header/UserComponent';
import {
	getMatchedKey,
	getSelectedKeys,
	MenuList,
	menuList,
} from '@app/components/UI/SidePanel/MenuItems';
import { detailsList, DetailsList } from '@app/components/UI/Header/HeaderRoleDetails/DetailsList';
import { InfoHeader } from '@app/components/UI/Header/HeaderRoleDetails/InfoHeader';
import { MenuItem } from '@app/objects/MenuItem';
import { UserRole } from '@app/objects/User';
import { VersionPanel } from '@app/components/UI/SidePanel/VersionPanel';

import { CrematoryFeaturesActionCreators, CrematoryFeaturesState, getCrematoryFeaturesActionCreators } from '@app/store/CrematoryFeatures';
import { getPath } from '@app/utilities/RoutePathVars';

import '@app/scss/ui/side-panel.scss';
import '@app/scss/transitions/fades.scss';
import { activePermission, Permission } from '@app/objects/Permission';

interface OwnProps {
	togglePanel: (state?: boolean) => void;
}

interface ReduxProps {
	crematoryFeatures: CrematoryFeaturesState;
}

interface Actions {
	crematoryFeaturesAction: CrematoryFeaturesActionCreators;
}

type HeaderProps = OwnProps & LoginState.LoginState & ReduxProps & Actions & RouteComponentProps;

const params = { page: 0 };
const privateCrematoryMenuItems = [getPath('customerList')];

class HeaderInner extends React.Component<HeaderProps> {
	private readonly menus: MenuList;
	private readonly details: DetailsList;

	constructor(props: HeaderProps) {
		super(props);

		this.menus = menuList;
		this.details = detailsList;
	}

	componentDidMount() {
		const { user } = this.props;
		const store = this.props.crematoryFeatures.item;

		if (user?.role === UserRole.Crematory && !store) this.props.crematoryFeaturesAction.request();
	}

	componentDidUpdate(prevProps) {
		const { user } = this.props;
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}

		const store = this.props.crematoryFeatures.item;
		if (user?.role === UserRole.Crematory
			&& store?.crematoryId
			&& this.props.user?.activeCrematoryId !== store?.crematoryId) this.props.crematoryFeaturesAction.request();
	}

	@member
	private togglePanel(): void {
		this.props.togglePanel();
	}

	@member
	private hideCrematoryMenuItem(itemPath: string): boolean {
		const store = this.props.crematoryFeatures.item;

		return this.props.user?.role === UserRole.Crematory
			&& (!store?.enableQuickbooks || !activePermission(this.props.user, Permission.Quickbooks))
			&& privateCrematoryMenuItems.includes(itemPath);
	}

	render() {
		const { user } = this.props;
		if (!user) return null;
		const Details = this.details[user.role];

		return (
			<header className="site-header">
				<div className="site-header__left-wrap">
					{user.role !== UserRole.Admin
						? (
							<div className="row col-md-3 user-crematory-select__width">
								<InfoHeader />
							</div>
						) : (
							<Link to="/" className="site-header__logo">
								<img src={require('@images/logo.png')} alt="Wings logo" height={35} width={33} />
							</Link>
						)}

					{Details && <Details />}

					<div className="site-header__menu-items">
						{this.menus[user.role].map((item: MenuItem) => {
							const selectedKeys = getSelectedKeys(this.props.location.pathname);
							const itemPathKey = getMatchedKey(item.path);

							const linkClassname = clsx('site-header__link', selectedKeys.includes(itemPathKey) && 'site-header__link--active');
							const path = generatePath(item.path, params);

							if (this.hideCrematoryMenuItem(item.path)) return null;

							return (
								<Link
									to={path}
									className={linkClassname}
									key={path}
								>
									<span>{item.name}</span>
								</Link>
							);
						})}
					</div>

				</div>

				<div className="site-header__right-wrap">
					<VersionPanel display={user?.role === UserRole.Admin} />
					<div className="site-header__user">
						<UserComponent light compact isDesktop />
					</div>
					<div className="side-panel-control side-panel-control_icon" onClick={this.togglePanel}>
						<MenuOutlined />
					</div>
				</div>
			</header>
		);
	}
}

export const Header = withRouter(connect<LoginState.LoginState & ReduxProps, Actions, OwnProps & RouteComponentProps, ApplicationState>(
	(state: ApplicationState) => ({
		...state.login,
		crematoryFeatures: state.crematoryFeatures,
	}),
	(dispatch: Dispatch<Action>) => ({
		crematoryFeaturesAction: bindActionCreators(getCrematoryFeaturesActionCreators(), dispatch),
	}),
)(HeaderInner));
