import * as React from 'react';

import clsx from 'clsx';
import Button from 'antd/lib/button';
import { DeleteOutlined, EyeOutlined, PaperClipOutlined } from '@ant-design/icons';
import Space from 'antd/lib/space';

import { FileInterface } from '@common/typescript/objects/FileInterface';
import { ImageComponent } from '@common/react/components/UI/Image/Image';

interface FilePreviewProps {
	file: FileInterface;
	fallback?: string;

	onDelete?: () => void;
	onPreview?: () => void;

	/**
	 * Allow downloading item by clicking on filename
	 */
	download?: boolean;
}

export const FilePreview: React.FC<FilePreviewProps> = (props: FilePreviewProps) => (
	<div className="file-preview">
		<div className="file-preview__image">
			<ImageComponent
				src={props.file.thumb}
				alt={props.file.name}
				fallback={props.fallback}
			/>
			<div className="file-preview__actions">
				<Space>
					<Button
						type="link"
						style={{ display: props.onPreview ? undefined : 'none' }}
						onClick={props.onPreview}
						title="Preview file"
						icon={<EyeOutlined />}
					/>
					<Button
						type="text"
						danger
						style={{ display: props.onDelete ? undefined : 'none' }}
						onClick={props.onDelete}
						title="Remove file"
						icon={<DeleteOutlined />}
					/>
				</Space>
			</div>
		</div>
		<a
			href={props.file.src}
			download={props.download}
			className={clsx('file-preview__caption', !props.download && 'file-preview__caption--disabled')}
			title={props.file.name}
		>
			<PaperClipOutlined /><span>{props.file.name}</span>
		</a>
	</div>
);

FilePreview.defaultProps = {
	fallback: require('@images/no_image.jpg'),
};
