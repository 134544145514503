import * as React from 'react';

interface FactoryAsyncResponse<T> {
	item: T;
	aborted: boolean;
}

type Handler<T> = () => Promise<FactoryAsyncResponse<T>>;

export function useMemoAsync<T>(initial: T, factory: Handler<T>, deps: Array<unknown>): T {
	const [item, setItem] = React.useState(() => initial);

	React.useEffect(() => {
		factory().then((response: FactoryAsyncResponse<T>) => {
			if (response.aborted) return;

			setItem(response.item);
		});
	}, deps);

	return item;
}
