import { Action, ReducersMapObject } from 'redux';

import { getReducer, LoginState } from '@common/react/store/Login';
import { BaseUser } from '@common/react/objects/BaseUser';
import { ChatsState } from '@common/react/store/Chats';

// The top-level state object
export interface BaseApplicationState<TUser extends BaseUser> {
	login: LoginState<TUser>;
	chats: ChatsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const baseReducers: ReducersMapObject = {
	login: getReducer(),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type BaseAppThunkAction<
	TAction extends Action,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>,
	TResult = void,
> = (dispatch: (action: TAction) => void, getState: () => TApplicationState) => TResult;
