import * as React from 'react';
import { useFormikContext } from 'formik';

import Tag from 'antd/lib/tag';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Nullable } from '@common/typescript/objects/Nullable';

import { PetOnHold } from '@app/objects/Pet';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { WhySpecial } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Displays/WhySpecial';

export const TagStack: React.FC = () => {
	const [petId, setPetId] = React.useState<Nullable<number>>(() => null);
	const { values } = useFormikContext<PetFormValues>();

	const { onHold, isSpecial, id } = values;

	if (onHold === PetOnHold.None && !isSpecial) return null;

	return (
		<>
			<Stack
				direction={StackDirection.Horizontal}
				gap={2}
				className="tag-stack"
			>
				{
					onHold !== PetOnHold.None
						? (
							<Tag color="volcano">On Hold</Tag>
						) : null
				}
				{
					isSpecial
						? (
							<Tag
								color="blue"
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '4px',
									alignItems: 'center',
									cursor: 'pointer',
								}}
								onClick={() => setPetId(id)}
							>
								Special
								<QuestionCircleOutlined />
							</Tag>
						) : null
				}
			</Stack>
			<ModalWrapper
				title="Why is this pet 'Special'?"
				isOpened={Boolean(petId)}
				onOk={() => setPetId(null)}
				onCancel={() => setPetId(null)}
				okText="Ok"
			>
				{
					petId
						? (
							<WhySpecial
								petId={petId!}
							/>
						) : null
				}
			</ModalWrapper>
		</>
	);
};
