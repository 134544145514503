import * as React from 'react';

import CoreSelect, { BaseOptionType } from 'antd/lib/select';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import {
	FilterOptionHook,
	SelectMode,
	SelectProps,
	SelectSize,
} from '@app/components/UI/Select/SelectProps';

type AntdFilterOptionHook = (inputValue: string, option?: BaseOptionType) => boolean;

function toAntdMode(mode?: SelectMode): undefined | 'multiple' {
	if (mode === SelectMode.Multiple) return 'multiple';

	return undefined;
}

function toAntdSize(size?: SelectSize): SizeType {
	switch (size) {
	case SelectSize.Large:
		return 'large';

	case SelectSize.Small:
		return 'small';

	default:
		return 'middle';
	}
}

function toAntdFilterOption(hook?: boolean | FilterOptionHook): boolean | AntdFilterOptionHook {
	if (typeof hook === 'undefined') return false;
	if (typeof hook === 'boolean') return hook as boolean;

	return (query: string, option?: BaseOptionType): boolean => {
		const key = option?.value;
		if (typeof key !== 'number') return false;

		return hook(query, key);
	};
}

export const Select = <T extends string | number>(props: SelectProps<T>): React.ReactElement<SelectProps<T>> => {
	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<CoreSelect<T>
			placeholder={props.placeholder}
			mode={toAntdMode(props.mode)}
			disabled={props.disabled}
			loading={props.loading}
			allowClear={props.showClear}
			showSearch={props.showSearch}

			className={props.className}
			style={props.style}
			size={toAntdSize(props.size)}

			value={props.value}
			onChange={props.onChange}

			onSelect={props.onSelect}
			onDeselect={props.onDeselect}
			onSearch={props.onSearch}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			onPopupScroll={props.onPopupScroll}
			onInputKeyDown={props.onInputKeyDown}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}

			filterOption={toAntdFilterOption(props.filterOption)}

			suffixIcon={props.suffixIcon}
			removeIcon={props.removeIcon}
			clearIcon={props.clearIcon}
			menuItemSelectedIcon={props.menuItemSelectedIcon}
		>
			{props.children}
		</CoreSelect>
	);
};
