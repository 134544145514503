import React from 'react';
import { useSelector } from 'react-redux';

import Text from 'antd/lib/typography/Text';
import clsx from 'clsx';
import '@app/scss/ui/components/linedText.scss';

import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';

export interface LinedTextProps {
	color: string;
	text: string;
	minimized?: boolean;
	className?: string;
	style?: React.CSSProperties;
	strong?: boolean;
}

export const LinedText: React.FC<LinedTextProps> = (props: LinedTextProps) => {
	const {
		text, color, minimized = false, className: classProps, style: styleProps, strong = false,
	} = props;

	const user = useSelector((state: ApplicationState) => state.login.user);
	const isClinic = user?.role === UserRole.ClinicUser;

	const style: React.CSSProperties = React.useMemo<React.CSSProperties>(() => {
		const style: React.CSSProperties = { ...styleProps };
		if (minimized && !isClinic) {
			style.borderLeftColor = color;
		} else if (minimized && isClinic) {
			style.color = color;
			style.border = 'none';
		} else if (!minimized && !isClinic) {
			style.borderBottomColor = color;
		} else {
			style.border = 'none';
		}

		return style;
	}, [styleProps, color, minimized]);
	const className = React.useMemo(() => `lined-text__container${minimized ? '_minimized' : ''} ${classProps || ''}`, [minimized, classProps]);

	return (
		<div style={style} className={className}>
			<Text strong={strong} style={style} className={clsx(isClinic && 'pet-card__data--table')}>{text}</Text>
		</div>
	);
};
