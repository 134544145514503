import React from 'react';

import List from 'antd/lib/list';

import { Nullable } from '@common/typescript/objects/Nullable';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { List as IList } from '@common/typescript/objects/List';
import { AlertMessage, AlertType } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { PetPrice } from '@app/objects/Pet';

import {
	StoreEntry,
	StoreEntryAttribute,
	StoreEntryNode,
	getPath,
	getRoot, StoreEntryAttributeValue,
} from '@app/objects/StoreEntry';
import { useStoreEntry } from '@app/hooks/StoreEntry/useStoreEntry';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import {
	getData,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import { UserRole } from '@app/objects/User';
import { allowAdd } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/Services';
import { useRequest } from '@app/hooks/useRequest';
import { Price } from '@app/objects/Price';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';

interface UrnSelectionProps {
	reference: PetPrice;

	value: PetPrice;
	onChange: (value: PetPrice) => void;
}

interface UrnAttributeSelectProps {
	attribute: StoreEntryAttribute;
	entry: StoreEntry;
	value: PetPrice;
	selection: Nullable<StoreEntryNode>;
	onChange: (value: Nullable<StoreEntryNode>) => void;

	disabled?: boolean;
}

export const UrnAttributeSelect: React.FC<UrnAttributeSelectProps> = (props: UrnAttributeSelectProps) => {
	const {
		attribute,
		entry,
		selection,
		onChange,
		disabled,
	} = props;

	const options = attribute.values
		.sort((a, b) => b.order - a.order)
		.map((i) => ({
			text: i.name,
			value: i.id,
		}));

	let value;
	if (selection) {
		const path = getPath(entry, selection).map((node: StoreEntryNode) => node.valueId);
		value = options.find((option) => path.includes(option.value))?.value;
	}

	return (
		<LocalSelect
			value={value}
			onChange={(id: number) => {
				if (!entry) return;

				const node = selection ?? getRoot(entry);
				if (!node) return;

				const candidates = entry.tree.filter((item: StoreEntryNode) => item.valueId === id);
				if (!candidates.length) return;

				/* Added a new value */
				const result = candidates.find((item: StoreEntryNode) => item.parentId === node.id);
				if (result) {
					onChange(result);

					return;
				}

				/* Changed existing value */
				const path = getPath(entry, node);
				const options = candidates.filter((c: StoreEntryNode) => path.some((n: StoreEntryNode) => n.id === c.parentId));
				if (options.length !== 1) return;

				onChange(options[0]);
			}}
			options={options}
			disabled={disabled}
			deselectType=""
			filterName=""
			fieldName=""
			allowClear={false}
			size="small"
			className="col-sm-6"
			style={{ width: '100%', minWidth: '15ch' }}
		/>
	);
};

function getPrice(prices: Array<Price>, count: number, currentPrice?: Nullable<Price>, itemId?: number): number {
	const price = prices.find((i) => i.inventoryItemId === itemId);

	if (!price && !currentPrice) return 0;

	return getPriceValue(price ?? currentPrice, count).value;
}
export interface PriceListParams {
	nodeId: Nullable<number>;
	crematoryId?: number;
	clinicId?: Nullable<number>;
	serviceType?: number;
	priceType?: number;
}

function isSelectable(path: Array<StoreEntryNode>, attribute: StoreEntryAttribute): boolean {
	const pathValues = path.map((node: StoreEntryNode) => node.valueId);
	const values = attribute.values.map((value: StoreEntryAttributeValue) => value.id);
	const intersects = values.some((value: number) => pathValues.includes(value));

	return !intersects;
}

function getUrnDescription(count: number, value: number): string {
	return `Requested ${count} urn${count > 1 ? 's' : ''} at the total of $${value.toFixed(2)}`;
}

export const UrnSelection: React.FC<UrnSelectionProps> = (props: UrnSelectionProps) => {
	const { item: entry, loading } = useStoreEntry({ entryId: props.value.entry?.id ?? null });
	const priceListRequest = useRequest<IList<Price>, PriceListParams>('storeEntryPricesList', undefined, { requestOnMount: false });

	const priceListParams = React.useMemo(() => ({
		nodeId: props.value.nodeId,
		crematoryId: props.value.price?.crematoryId,
		clinicId: props.value.price?.clinicId,
		serviceType: props.value.price?.serviceType,
		priceType: props.value.price?.priceType,
	}), [props.value]);

	React.useEffect(() => {
		priceListRequest.reload(priceListParams);
	}, []);

	if (loading) return <Loading />;
	if (!entry) return <div>No entry found</div>;

	const cost = getPrice(priceListRequest.item?.list ?? [], props.value.count, props.value.price, props.value.node?.itemId);
	const node = entry.tree.find((q: StoreEntryNode) => q.id === props.value.nodeId) ?? null;
	const reference = entry.tree.find((q: StoreEntryNode) => q.id === props.reference.nodeId) ?? null;
	const path = getPath(entry, reference);

	return (
		<List
			size="small"
			dataSource={getData(entry, node, UserRole.Crematory).filter((item: StoreEntryAttribute) => isSelectable(path, item))}
			style={{ flex: 1 }}
			header={(
				<>
					<b>Pending Urn:</b>
					<List.Item>
						<List.Item.Meta
							title={reference.fullName}
							description={getUrnDescription(props.value.count, cost)}
						/>
					</List.Item>
				</>
			)}
			renderItem={(item: StoreEntryAttribute) => (
				<List.Item>
					<List.Item.Meta title={<b>{item.name}</b>} />
					<UrnAttributeSelect
						attribute={item}
						entry={entry}
						value={props.value}

						selection={node}
						onChange={(value: Nullable<StoreEntryNode>) => props.onChange({ ...props.value, node: value, nodeId: value?.id ?? null })}
					/>
				</List.Item>
			)}
			footer={(
				<AlertMessage
					type={AlertType.Warning}
					message={allowAdd(entry, node, props.value.nodeId) && !priceListRequest.item?.list.find((i) => i.inventoryItemId === node?.itemId)
						? 'Price for selected set of parameters doesn\'t exist'
						: null}
					style={{ whiteSpace: 'pre-line' }}
				/>
			)}
		/>
	);
};
