import React from 'react';

import {
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
} from 'formik';
import { v4 } from 'uuid';
import Alert from 'antd/lib/alert';
import Space from 'antd/lib/space';

import { Nullable } from '@common/typescript/objects/Nullable';

import { Pet, PetPrice } from '@app/objects/Pet';
import { useRequest } from '@app/hooks/useRequest';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';

import {	StoreEntryNode } from '@app/objects/StoreEntry';
import { PriceKind } from '@app/objects/Price';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { UrnSelection } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/UrnSelection';
import {
	UrnSelectionPreview,
} from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/UrnSelectionPreview';
import { DefaultInfo } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/DefaultInfo';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';

interface FormValues {
	id: number;

	toConfirm: Array<PetPrice>;
	toSelect: Array<PetPrice>;
}

interface UrnFormProps {
	item: Pet;
	onChange: (item: Partial<Pet>, shouldClose?: boolean, params?: PromoteParams, setSubmitting?: () => void) => void;
	onClose: () => void;
}

type ConfirmPetPriceMessage = Pick<PetPrice, 'id' | 'completedCount'>;
export interface ConfirmMessage {
	services: Array<ConfirmPetPriceMessage>;
}

interface UpdatePetPriceMessage {
	id: number;
	nodeId: number;
}

export function toUpdateMessage(item: PetPrice): UpdatePetPriceMessage {
	return {
		id: item.id,
		nodeId: item.nodeId!,
	};
}

export interface UpdatePetMessage {
	id: number;
	updateServices: Array<UpdatePetPriceMessage>;
}

export function toConfirmMessage(item: PetPrice): ConfirmPetPriceMessage {
	return {
		id: item.id,
		completedCount: item.count,
	};
}

function getUrns(item: Nullable<Pet>): Array<PetPrice> {
	if (!item) return [];
	if (!item.services) return [];

	return item.services
		.filter((item: PetPrice) => item.price?.priceKind === PriceKind.UrnPrice)
		.filter((item: PetPrice) => !item.done)
		.map((item: PetPrice) => {
			const entry = item.node?.entry ?? null;
			if (entry && item.node) {
				entry.tree = entry.tree.map((item: StoreEntryNode) => ({
					...item,

					parent: null,
					children: [],
				}));

				if (entry.tree.findIndex((node: StoreEntryNode) => node.id === item.nodeId) < 0) {
					entry.tree.push({
						...item.node,

						parent: null,
						children: [],
					});
				}
			}

			return {
				...item,
				clientId: v4(),

				entry,
				node: entry ? entry.tree.find((node: StoreEntryNode) => node.id === item.nodeId) : null,
			};
		})
		.filter((item: PetPrice) => item.node && item.entry);
}

export function isSelected(item: PetPrice): boolean {
	if (!item.node || !item.entry) return false;

	return !item.entry.tree
		.filter((node: StoreEntryNode) => !node.disabled)
		.some((node: StoreEntryNode) => node.parentId === item.nodeId);
}

function getAlertMessage(values: FormValues): string {
	if (values.toSelect.length) {
		return 'Please, select an urn';
	}

	return 'Please, confirm all selected urns data is correct';
}

export const UrnForm: React.FC<UrnFormProps> = (props: UrnFormProps) => {
	const updatePet = useRequest<Pet, UpdatePetMessage>('updatePet', undefined, { requestOnMount: false });
	const updateServices = useRequest<Array<PetPrice>, ConfirmMessage>('updateServices', undefined, { requestOnMount: false });

	const item = props.item;
	const initialValues = React.useMemo(() => {
		const urns = getUrns(item);

		return {
			id: item.id,

			toConfirm: urns.filter(isSelected),
			toSelect: urns.filter((item: PetPrice) => !isSelected(item)),
		};
	}, [item]);

	const submit = (
		values: FormValues,
		setSubmitting: (val: boolean) => void,
	) => {
		function update(list: Array<PetPrice>): Promise<unknown> {
			const data = list.map(toUpdateMessage);

			return updatePet.reload({ id: values.id, updateServices: data });
		}

		function confirm(list: Array<PetPrice>): Promise<unknown> {
			const data = list.map(toConfirmMessage);

			return updateServices.reload({ services: data });
		}

		let task: Promise<unknown> = Promise.resolve();

		if (values.toSelect.length) {
			const items = [values.toSelect[0]];

			task = update(items).then(() => confirm(items));
		} else if (values.toConfirm.length) {
			task = confirm(values.toConfirm);
		}

		task
			.then(() => props.onChange({ id: item.id }))
			.finally(() => setSubmitting(false));
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => submit(values, setSubmitting)}
			enableReinitialize
		>
			{
				(formik: FormikProps<FormValues>) => (
					<Form id="urn-form">
						<StatusModalController width={500} />

						<Space direction="vertical" size="middle">
							<DefaultInfo item={item} />

							{
								formik.values.toSelect.length
									? (
										<UrnSelection
											reference={formik.initialValues.toSelect[0]}
											value={formik.values.toSelect[0]}
											onChange={(value: PetPrice) => formik.setFieldValue('toSelect.0', value, false)}
										/>
									) : null
							}
							{
								formik.values.toConfirm.length && !formik.values.toSelect.length
									? (
										<UrnSelectionPreview urns={formik.values.toConfirm} />
									) : null
							}
						</Space>

						<Stack
							direction={StackDirection.Vertical}
							gap={1}
							style={{ margin: '8px 0' }}
						>
							<Alert
								type="warning"
								message={getAlertMessage(formik.values)}
								closable
								onClose={(event: React.MouseEvent) => event.stopPropagation()}
							/>
							{
								updateServices.error || updatePet.error ? (
									<Alert
										type="error"
										message={updatePet.error ?? updateServices.error}
										closable
										onClose={(event: React.MouseEvent) => event.stopPropagation()}
									/>
								) : null
							}
						</Stack>
						<ModalButtons
							onClose={props.onClose}
							okText={formik.values.toSelect.length ? 'Save' : 'Confirm'}
						/>
					</Form>
				)
			}
		</Formik>
	);
};
