import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
	Form,
	Formik,
	FormikProps,
	useFormikContext,
} from 'formik';
import { v4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import Space from 'antd/lib/space';

import Button from 'antd/lib/button';

import Tabs from 'antd/lib/tabs';

import { PetTrackChange, setDefaultCarrier, setDefaultDeliveryType } from './Components/Sections/PetTrackChange';

import { List } from '@common/typescript/objects/List';
import ScrollToError from '@common/react/components/Forms/ScrollToError';
import { ItemState } from '@common/react/store/Item';
import * as PagesState from '@common/react/store/ItemList';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { Nullable } from '@common/typescript/objects/Nullable';
import { getShallowChanges, pickDifference } from '@common/typescript/utils/entity';

import { ApplicationState } from '@app/store';
import { Actions, dispatchToProps } from '@app/components/Pages/ItemEditor';
import {
	DeliveryType,
	getDefaultPet,
	Pet,
	PetInvoiceSummary,
	PetListInfo,
	PetOnHold,
	PetPrice,
	ServiceType,
	WeightSource,
} from '@app/objects/Pet';
import { Clinic, ClinicListInfo, ClinicSelectInfo } from '@app/objects/Clinic';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import {
	CrematoryListInfo,
	CrematoryServiceTypePreferences,
	getDefaultCrematoryServiceTypePreferences,
} from '@app/objects/Crematory';
import { CurrentStatus } from '@app/objects/CurrentStatus';
import { User, UserRole } from '@app/objects/User';
import { Location } from '@app/objects/Location';
import { Status } from '@app/objects/Status';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { OldPetStatusHistory } from '@app/components/Pages/PetEditor/OldPetStatusHistory';
import { Carrier } from '@app/objects/Carriers';
import {
	CreatePetMessage,
	PetFormValues,
	ReturnValues,
	UpdatePetMessage,
} from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { petValidationSchema } from '@app/components/Pages/PetEditor/OldPetEditor/schemas';
import {
	toCreateMessage, toUpdateMessage, updateSpecialServiceKit,
} from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import {
	SpecialServiceSection,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceSection';
import { OwnerInfoSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/OwnerInfoSection';
import { DeliverySection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DeliverySection';
import { PetInfoSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/PetInfoSection';
import { PetEditorHeader } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/PetEditorHeader';
import { TopSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/TopSection';
import { TagStack } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/TagStack';
import { ClinicSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ClinicSection';
import { ProcessSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProcessSection';
import { ProductsSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProductsSection';

import { PricingManager, Values } from '@app/services/pricing/PricingManager';
import { OldPetEditorTabs } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetEditorTabs';
import { getWeight } from '@app/components/Utils/Prices/Helpers';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { ExistingPriceStack } from '@app/services/pricing/ExistingPriceStack';
import { DocumentsSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DocumentsSection/DocumentsSection';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { activePermission, Permission } from '@app/objects/Permission';
import { Container } from '@app/components/UI/Container/Container';
import { useRequest } from '@app/hooks/useRequest';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { allowAdd } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/Services';
import { StoreEntryNode } from '@app/objects/StoreEntry';

const path: string = 'getPet';
const store: keyof ApplicationState = 'pet';
const saveItemPath: string = 'createPet';
const updateItemPath: string = 'updatePet';
const itemsStore: keyof ApplicationState = 'pets';
const defaultListParams = {
	page: 1,
	text: '',
	count: 1000,
	column: [{
		caption: 'name',
		direction: 1,
	}],
};
const additionalParams: Record<string, boolean> = {
	withInvoice: true,
	withCurrentStatus: true,
	withServices: true,
	withUrns: true,
	withBatch: true,
	withFiles: true,

	// for title info
	withPetBreed: true,
	withPetSpecie: true,
	withPetGender: true,

	// for list
	withClinic: true,
};

interface ReduxProps {
	clinics: PagesState.ItemsState<ClinicListInfo>;
	crematories: PagesState.ItemsState<CrematoryListInfo>;
	pets: PagesState.ItemsState<PetListInfo>;
	locations: PagesState.ItemsState<Location>;
	user: User | null;
	carriers: PagesState.ItemsState<Carrier>;
	crematoryList: Array<CrematoryListInfo>;
	clinicsList: Array<ClinicSelectInfo>;
}

export interface LoadPricesParams extends Omit<PriceListRequestParams, 'count' | 'withSpecialService' | 'withInventoryItem' | 'clinicId'> {
	clinicId: Nullable<number>;
}

interface PriceListRequestParams {
	crematoryId: number;
	clinicId: Array<Nullable<number>>;
	priceType: PriceType;
	serviceType: ServiceType;
	withSpecialService: boolean;
	withInventoryItem: boolean;
	count: number;
}

function toValues(values: PetFormValues, source: WeightSource): Values {
	const urns: Array<PetPrice> = values.urns ?? [];
	const products: Array<PetPrice> = values.products ?? [];
	const summary: PetInvoiceSummary = values.invoiceSummary ?? {
		servicesTotal: 0,
		productsTotal: 0,
		deliveryTotal: 0,
		subTotal: 0,
		serviceTax: 0,
		serviceTaxPercentage: 0,
		productTax: 0,
		productTaxPercentage: 0,
		total: 0,
	};

	return {
		id: values.id,
		engraving: values.engraving ?? [],
		services: values.services,
		recalculatePrice: values.recalculatePrice,

		weight: getWeight(values, source),
		rush: values.rush,

		crematoryId: values.crematoryId,
		clinicId: values.clinicId,

		priceType: values.priceType,
		serviceType: values.serviceType,

		petSpecieId: values.petSpecieId,
		deliveryType: values.deliveryType,
		urns,
		products,
		discountId: values.discountId,

		summary,
	};
}

export function getCrematory(
	item: Nullable<{ crematoryId: number }>,
	user: Nullable<User>,
	crematories: Array<CrematoryListInfo>,
): Nullable<CrematoryListInfo> {
	if (user?.role === UserRole.Crematory || user?.role === UserRole.CrematoryUser) {
		const id = user?.activeCrematoryId;
		if (id === null || id <= 0) return null;

		return crematories.find((q: CrematoryListInfo) => q.id === id) ?? null;
	}

	if (!item) return null;

	if (item.crematoryId > 0) {
		return crematories.find((q: CrematoryListInfo) => q.id === item.crematoryId) ?? null;
	}

	return crematories[0] ? crematories[0] : null;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getInitialValues(item: Pet, onGetCrematory: (values: PetFormValues) => Nullable<CrematoryListInfo>): PetFormValues {
	const crematory = onGetCrematory(item as PetFormValues);
	const crematoryId = crematory ? crematory.id : item.crematoryId;
	const services = item.services ?? [];
	const urns = services.filter((i) => i.price?.priceKind === PriceKind.UrnPrice) ?? [];
	const products = services.filter((i) => i.price?.priceKind === PriceKind.ProductPrice) ?? [];

	if (services.length) {
		services.forEach((q: PetPrice) => {
			// eslint-disable-next-line no-param-reassign
			q.clientId = v4();
		});
	}

	urns.forEach((urn: PetPrice) => {
		// eslint-disable-next-line no-param-reassign
		urn.entry = urn.node?.entry;
		// eslint-disable-next-line no-param-reassign
		urn.toSelect = !allowAdd(urn.node?.entry, urn.node, urn.nodeId);
	});

	return {
		...item,

		deliveryAddress: item.deliveryAddress ?? '',
		deliveryAddress2: item.deliveryAddress2 ?? '',
		deliveryCity: item.deliveryCity ?? '',
		deliveryZip: item.deliveryZip ?? '',
		deliveryState: item.deliveryState ?? null,
		deliveryStateId: item.deliveryStateId ?? null,
		specialInstructions: item.specialInstructions ?? '',
		batchMachineLocation: item.batchMachineLocation ?? '',
		trackingNumber: item.trackingNumber ?? '',
		phone: item.phone ?? '',
		email: item.email ?? '',
		clinicName: item.clinicName ?? '',
		name: item.name ?? '',
		color: item.color ?? '',

		vet: item.vet ?? '',
		internalIdNum: item.internalIdNum ?? '',
		onHold: item.onHold ?? PetOnHold.None,

		ownerFirstName: item.ownerFirstName ?? '',
		ownerLastName: item.ownerLastName ?? '',
		ownerPhone: item.ownerPhone ?? '',
		ownerPhone2: item.ownerPhone2 ?? '',
		ownerEmail: item.ownerEmail ?? '',
		ownerAddress: item.ownerAddress ?? '',
		ownerAddress2: item.ownerAddress2 ?? '',
		ownerCity: item.ownerCity ?? '',
		ownerState: item.ownerState ?? null,
		ownerStateId: item.ownerStateId ?? null,
		ownerZip: item.ownerZip ?? '',
		ownerNotes: item.ownerNotes ?? '',

		crematoryId,
		services,
		urns,
		products,

		engraving: item.engraving || [{
			id: -1, order: 0, name: item.name, removed: false,
		}],
		preferences: crematory ? { country: crematory.country } : null,

		recalculatePrice: false,
		carrierId: item.carrierId ?? 0,

		notes: item.notes ?? [],
		loadingUrns: false,
		discountId: item.discountId ?? -1,
	};
}

function getCalculatedPrice(
	form: FormikProps<PetFormValues>,
	prices: Array<Price>,
	onGetCrematory: (values: PetFormValues) => Nullable<CrematoryListInfo>,
): IPriceStack {
	const values = form.values;
	const initial = form.initialValues;
	const manager = new PricingManager();

	const oldCrematory = onGetCrematory(initial);
	const newCrematory = onGetCrematory(values);

	if (newCrematory) {
		const current = toValues(values, newCrematory.weightSource);
		const original = toValues(
			initial,
			oldCrematory ? oldCrematory.weightSource : newCrematory.weightSource,
		);

		current.summary.productTaxPercentage = values.priceType === PriceType.Retail ? newCrematory.retailProductTax : newCrematory.wholesaleProductTax;
		current.summary.serviceTaxPercentage = values.priceType === PriceType.Retail ? newCrematory.retailServiceTax : newCrematory.wholesaleServiceTax;

		return manager.getPrice(current, original, prices);
	}

	return new ExistingPriceStack([]);
}

function onUpdatePet(petId: number, services: Array<PetPrice>, props: PetEditorInnerProps) {
	if (props.item?.services) {
		let needUpdateKit = false;
		const result: Array<PetPrice> = [...props.item.services];

		services.forEach((item: PetPrice) => {
			const id = result.findIndex((q: PetPrice) => q.id === item.id);
			if (id >= 0) {
				result[id] = item;
			} else {
				result.push(item);
			}

			if (item.price?.specialService?.specialServiceType === SpecialServiceType.KitService) needUpdateKit = true;
		});

		props.itemActions.updateItem<Pick<Pet, 'id' | 'services'>>('pet', {
			services: needUpdateKit ? updateSpecialServiceKit(result) : result,
		});

		if (props.pets.items.length > 0) {
			props.pagesActions.refreshPages('pets', 'petList', {
				page: 1,
				withPetBreed: true,
				withSpecie: true,
				withClinic: true,
			});
		}
	}
}

function onUpdateUrnPet(petId: number, services: Array<PetPrice>, props: PetEditorInnerProps) {
	const result: Array<PetPrice> = [...props.item?.services ?? []];

	services.forEach((item: PetPrice) => {
		const id = result.findIndex((q: PetPrice) => q.id === item.id);

		if (id >= 0) {
			const oldItem = { ...result[id] };
			result[id] = {
				...oldItem,
				...item,
				entry: oldItem.entry,
				node: { ...item.node, entry: oldItem.entry ?? null, parent: null } as Nullable<StoreEntryNode>,
			};
		} else {
			result.push(item);
		}
	});

	props.itemActions.updateItem(store, { id: petId, services: result });
	props.pagesActions.refreshPages('pets', 'petList', {
		page: 1,
		withPetBreed: true,
		withSpecie: true,
		withClinic: true,
	});
}

function loadPrices(
	params: LoadPricesParams,
	onReload: (data?: PriceListRequestParams | undefined) => Promise<List<Price> | void>,
	onChangePrices: (value: Array<Price>) => void,
	onChangeServicesPrices: (value: Array<Price>) => void,
) {
	onReload({
		crematoryId: params.crematoryId,
		clinicId: [params.clinicId, null],
		priceType: params.priceType || PriceType.Wholesale,
		serviceType: params.serviceType,
		withSpecialService: true,
		withInventoryItem: true,
		count: 500,
	}).then((response: List<Price> | void) => {
		if (!response) return;

		const { list } = response;
		const prices = list.filter((price: Price) => !price.specialServiceId)
			.sort((a: Price, b: Price) => a.to - b.to);
		const servicesPrice = list.filter((price: Price) => price.specialServiceId)
			.sort((a: Price, b: Price) => a.to - b.to);
		const isClinicSet = params.clinicId !== null && params.clinicId > 0;

		let loadedServices = list.filter((price: Price) => price.specialServiceId !== null && price.specialServiceId > 0
			&& (isClinicSet ? (price.clinicId === +(params.clinicId as number) || price.clinicId === null) : price.clinicId === null));

		onChangePrices(prices);
		onChangeServicesPrices(servicesPrice);

		if (isClinicSet) {
			// reduce special services overwritten by clinic
			const clinicServices = loadedServices.filter((item) => item.clinicId === +(params.clinicId as number));

			loadedServices = loadedServices.filter((item) => {
				const foundInClinic = clinicServices.find((service: Price) =>
					service.specialServiceId === item.specialServiceId && service.serviceType === item.serviceType);

				if (item.clinicId) {
					return true;
				}

				return !foundInClinic;
			});
		}
	});
}

function getCalculatedPriceServices(newValues: ReturnValues, calculatedPrice: IPriceStack): Array<PetPrice> {
	const price = calculatedPrice?.base;

	if (price) return [...newValues.services, price];

	return [];
}

function getNewValues(item: PetFormValues, values: PetFormValues, isWholesale: boolean): ReturnValues {
	const clinicLocationId = values.clinicLocationId && +values.clinicLocationId > 0 ? +values.clinicLocationId : null;
	const clinicId = values.clinicId ? +values.clinicId : null;
	const services = [...values.services ?? [], ...values.products, ...values.urns ?? []];
	const carrierId = values.carrierId || null;

	return {
		...item,
		...values,

		clinicId: isWholesale && clinicId ? clinicId : null,
		clinicLocationId: isWholesale ? clinicLocationId : null,
		deliveryClinicId: +values.deliveryType === DeliveryType.Clinic && values.deliveryClinicId
		&& +values.deliveryClinicId > 0 ? +values.deliveryClinicId : null,
		clinic: undefined,
		deliveryClinic: null,
		clinicLocation: undefined,
		services,
		carrierId,
		discountId: values.discountId ?? null,
	};
}

function handleSubmit(
	values: PetFormValues,
	initialValues: PetFormValues, // use it instead of store item
	user: Nullable<User>,
	calculatedPrice: IPriceStack,
	submitRequest: (message: Partial<UpdatePetMessage> | CreatePetMessage) => void,
) {
	const isWholesale = +values.priceType !== PriceType.Retail;
	const role = user?.role;
	const initialServices = initialValues.services ?? [];
	const newValues = getNewValues(initialValues, values, isWholesale);

	if (initialValues.id > 0) {
		const newServices = newValues.services.filter((q: PetPrice) => q.id === -1)
			.filter((q: PetPrice) => q.price?.specialServiceId || q.price?.priceKind === PriceKind.PickupPrice);
		const updatedServices = newValues.services.filter((q: PetPrice) => q.id > 0)
			.filter((q: PetPrice) => (q.price?.specialServiceId && q.price?.priceKind === PriceKind.SpecialServicePrice) || q.price?.priceKind === PriceKind.PickupPrice)
			.filter((q1: PetPrice) => {
				const value = (initialServices ?? []).find((q2: PetPrice) => q2.id === q1.id);

				return value.removed !== q1.removed || value.count !== q1.count || value.value !== q1.value;
			});

		newValues.services = [...newServices, ...updatedServices];
	} else {
		newValues.services = (values.services ?? []).filter((item: PetPrice) => !item.removed);
	}

	const prices = initialServices.filter((service) => service.price && !service.price.specialServiceId);
	const price = prices[0];

	if (calculatedPrice.base) {
		newValues.services = getCalculatedPriceServices(newValues, calculatedPrice);
	} else if (price) {
		newValues.services.push({ ...price, removed: true });
	}

	if (initialValues.id > 0) {
		const initial = toUpdateMessage(initialValues, role);
		const updated = toUpdateMessage(newValues, role);

		const message: Partial<UpdatePetMessage> = getShallowChanges(initial, updated);
		message.updateServices = pickDifference(initial.updateServices, updated.updateServices);

		submitRequest(message);
	} else {
		const message: CreatePetMessage = toCreateMessage(newValues);

		submitRequest(message);
	}
}

interface InformationProps extends PetEditorInnerProps {
	disableHistory: boolean;
	onChangeStatus: (status: CurrentStatus) => void;
}

const Information: React.FC<InformationProps> = ({ disableHistory, onChangeStatus, ...props }: InformationProps) => {
	const { values } = useFormikContext<PetFormValues>();

	return (
		<Stack direction={StackDirection.Vertical}>
			{
				values.onHold === PetOnHold.None && (
					<div className="card">
						<div className="card__body">
							<OldPetStatusHistory
								disabled={disableHistory}
								onChangeStatus={onChangeStatus}
								onRefresh={(petId: number, services: Array<PetPrice>) => onUpdatePet(petId, services, props)}
								onUrnRefresh={(services: Array<PetPrice>) => onUpdateUrnPet(values.id, services, props)}
							/>
						</div>
					</div>
				)
			}
			<OldPetEditorTabs pet={values} />
		</Stack>
	);
};

type PetEditorInnerProps = ItemState<Pet> & ReduxProps & Actions<Pet>
const PetEditorInner: React.FC<PetEditorInnerProps> = (props: PetEditorInnerProps) => {
	const { t } = useTranslation('translation');
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const formRef = React.useRef<FormikProps<PetFormValues>>(null);
	const [prices, setPrices] = React.useState<Array<Price>>([]);
	const [servicesPrice, setServicesPrice] = React.useState<Array<Price>>([]);
	const [serviceTypePreferences, setServiceTypePreferences] = React.useState<CrematoryServiceTypePreferences>(getDefaultCrematoryServiceTypePreferences());
	const [disableHistory, setDisableHistory] = React.useState<boolean>(false);
	const item = props.item ?? getDefaultPet();
	const refItem = React.useRef<Pet>(item);
	const schema = petValidationSchema(serviceTypePreferences);
	const userCanCreatePet = item.id < 0 && activePermission(props.user, Permission.CreatePet);
	const userCanUpdatePet = item.id > 0 && activePermission(props.user, Permission.UpdatePet);

	const priceListRequest = useRequest<List<Price>, PriceListRequestParams>('priceList', undefined, { requestOnMount: false });

	const onGetCrematory = React.useCallback((items: PetFormValues) => getCrematory(items, props.user, props.crematories.items), [props.user, props.crematories.items]);
	const onLoadPrices = React.useCallback((params) => loadPrices(params, priceListRequest.reload, setPrices, setServicesPrice), []);

	const calculatedPrice = (): IPriceStack => {
		const form = formRef.current;

		if (form) return getCalculatedPrice(form, prices, onGetCrematory);

		return new ExistingPriceStack([]);
	};

	const onChangeStatus = (status: CurrentStatus) => {
		props.itemActions.updateItem<Pet>('pet', {
			currentStatus: status,
		});
	};

	const loadCrematoryDependencies = (id: number) => {
		if (id <= 0) return;

		const crematory = props.crematories.items.find((item) => item.id === id);
		if (!crematory) return;

		const form = formRef.current;
		if (!form) return;

		setDefaultCarrier(form.values, props.carriers.items, props.crematories.items, form.setFieldValue);
		setDefaultDeliveryType(form.values, props.crematories.items, props.clinicsList, form.setFieldValue);

		form?.setFieldValue('preferences', { country: crematory.country }, false);
	};

	const reloadPrices = () => {
		const form = formRef.current;
		if (!form) return;

		const values = form.values;
		const params = {
			crematoryId: values.crematoryId,
			clinicId: [values.clinicId, null],
			priceType: values.priceType || PriceType.Wholesale,
			serviceType: values.serviceType,
			withSpecialService: true,
			withInventoryItem: true,
			count: 500,
		};

		priceListRequest.reload(params)
			.then((response: List<Price> | void) => {
				if (!response) return;

				const prices = response.list.filter((price: Price) => !price.specialServiceId)
					.sort((a: Price, b: Price) => a.to - b.to);
				const servicesPrice = response.list.filter((price: Price) => price.specialServiceId)
					.sort((a: Price, b: Price) => a.to - b.to);

				setPrices(prices);
				setServicesPrice(servicesPrice);
			});
	};

	const createRequest = useRequest<Pet, CreatePetMessage>(saveItemPath, undefined, { requestOnMount: false });
	const createPet = (message: CreatePetMessage, onChangeLoading: (val: boolean) => void) => {
		createRequest.reload(message)
			.then((item: Pet | void) => {
				if (!item) return;

				alertMessage(MessageType.success, `${t('messages.type.success')}! Pet was created!`);
				const locationPath = generatePath(RoutePaths.petEditor, { id: item.id, type: 'edit' });

				history.replace(locationPath);
				props.itemActions.loadItem(store, path, item.id, getDefaultPet(), additionalParams, () => true);
				props.pagesActions.addItem(itemsStore, item);
			})
			.catch((error: string) => alertMessage(MessageType.error, error))
			.finally(() => onChangeLoading(false));
	};

	const updateRequest = useRequest<Pet, Partial<UpdatePetMessage>>(updateItemPath, undefined, { requestOnMount: false });
	const updatePet = (message: Partial<UpdatePetMessage>, onChangeLoading: (val: boolean) => void) => {
		updateRequest.reload({ ...message, ...additionalParams })
			.then((item: Pet | void) => {
				if (!item) return;

				alertMessage(MessageType.success, `${t('messages.type.success')}! Pet was updated!`);

				props.itemActions.updateItem(store, item);
				props.pagesActions.updateItem(itemsStore, item);

				history.push(RoutePaths.petList);
			})
			.catch((error: string) => alertMessage(MessageType.error, error))
			.finally(() => onChangeLoading(false));
	};

	React.useEffect(() => {
		if (id) props.itemActions.loadItem(store, path, +id, getDefaultPet(), additionalParams);
	}, [id]);

	React.useEffect(() => {
		// this need for recalculated price if we changed something on pet list for the same pet
		if (props.item && item?.outOfDate) {
			props.itemActions.loadItem(store, path, +id, getDefaultPet(), additionalParams, () => true)
				.then(() => props.itemActions.updateItem(store, { id: +id, outOfDate: false }));
		}

		props.pagesActions.reqPages<Status>('statuses', 'statusList', 'status', { page: 1, text: '', count: 50 });
		props.pagesActions.reqPages<CrematoryListInfo>('crematories', 'crematoryList', 'crematory', { page: 1, text: '', count: 10 })
			.then(() => {
				const item = props.item ?? getDefaultPet();
				const activeCrematoryId = props.user?.activeCrematoryId ?? props.crematories.items[0].id;
				if (+id < 0 && activeCrematoryId > 0) {
					onLoadPrices({
						crematoryId: activeCrematoryId,
						priceType: item.priceType,
						serviceType: item.serviceType,
						clinicId: item.clinicId,
					});

					if (activeCrematoryId) loadCrematoryDependencies(activeCrematoryId);
				}
			});
		props.pagesActions.reqPages<Clinic>('clinics', 'clinicList', 'clinic', defaultListParams);
		props.pagesActions.reqPages<Carrier>('carriers', 'carrierList', 'carrier', defaultListParams);
	}, [item]);

	React.useEffect(() => {
		if (props.item === refItem.current) return;

		const services = props.item?.services ?? [];
		const shouldUpdate = services.reduce((res: boolean, cur: PetPrice) => res || !cur.clientId, false);
		if (shouldUpdate) {
			services.forEach((item: PetPrice) => {
				// eslint-disable-next-line no-param-reassign
				item.clientId = v4();
			});
		}
	}, [item]);

	if (!props.item || props.item.id !== +id) return <Loading />;

	return (
		<Formik
			initialValues={getInitialValues(item, onGetCrematory)}
			validationSchema={schema}
			onSubmit={(values: PetFormValues, { setSubmitting }) => {
				const submitRequest = (message) => (values.id > 0 ? updatePet(message, setSubmitting) : createPet(message, setSubmitting));
				handleSubmit(
					values,
					getInitialValues(item, onGetCrematory),
					props.user,
					calculatedPrice(),
					submitRequest,
				);
			}}
			enableReinitialize
			innerRef={formRef}
		>
			{({ values, setFieldValue, ...formikBag }: FormikProps<PetFormValues>) => (
				<Container className="page-container">
					<Form id="pet-form">
						<PetEditorHeader
							calculatedPrice={calculatedPrice()}
							onRecalculate={() => {
								setFieldValue('recalculatePrice', true, false);
								reloadPrices();
							}}
						/>
						<Tabs
							defaultActiveKey="editor"
							className={clsx('pet-editor__desktop-view', values.id > 0 && 'pet-editor__mobile-view')}
							items={[
								{
									key: 'editor',
									label: 'Editor',
									children: (
										<Stack
											direction={StackDirection.Horizontal}
											gap={2}
											className="pet-editor-page__content scroll-container"
										>
											<Stack.LayoutItem fluid>
												<Space size="middle" direction="vertical" style={{ display: 'flex' }}>
													<div className="card is-relative">
														<TagStack />
														<div className="card__body">
															<PetTrackChange
																item={item}
																prices={prices}
																reloadPrices={reloadPrices}
																onLoadPrices={onLoadPrices}
																loadCrematoryDependencies={loadCrematoryDependencies}
																onChangeDisableHistory={setDisableHistory}
																onChangeServiceTypePreferences={setServiceTypePreferences}
															/>
															<ScrollToError {...formikBag} formId="pet-form" />
															<Space direction="vertical" style={{ display: 'flex' }} size="middle">
																<TopSection />
																<PetInfoSection />
																{values.priceType !== PriceType.Retail && <ClinicSection />}
																<SpecialServiceSection prices={servicesPrice} />
																<OwnerInfoSection
																	country={values.preferences?.country}
																	calculatedPrice={calculatedPrice()}
																/>
																<DeliverySection
																	country={values.preferences?.country}
																	prices={prices}
																/>
																{
																	(values.serviceType === ServiceType.Private || values.serviceType === ServiceType.SemiPrivate || values.batchAction)
																		? <ProcessSection /> : null
																}
																<ProductsSection
																	prices={prices}
																	onLoadPrice={(values) => onLoadPrices(values)}
																/>
																<DocumentsSection />
															</Space>
														</div>
													</div>
													{(userCanCreatePet || userCanUpdatePet) && (
														<Space style={{ justifyContent: 'center' }}>
															<Button onClick={() => history.goBack()}>
																Cancel
															</Button>
															<Button
																type="primary"
																loading={formikBag.isSubmitting}
																onClick={formikBag.submitForm}
															>
																Save
															</Button>
														</Space>
													)}
												</Space>
											</Stack.LayoutItem>

											{
												values.id > 0 && (
													<Stack.LayoutItem span={{ xs: 12, lg: 4 }} className="pet-editor__right-block">
														<Information disableHistory={disableHistory} onChangeStatus={onChangeStatus} {...props} />
													</Stack.LayoutItem>
												)
											}
										</Stack>
									),
								},
								{
									key: 'info',
									label: 'Information',
									children: (<Information disableHistory={disableHistory} onChangeStatus={onChangeStatus} {...props} />),
								},
							]}
						/>
					</Form>
				</Container>
			)}
		</Formik>
	);
};

export const OldPetEditor = connect<ItemState<Pet> & ReduxProps, Actions<Pet>, unknown, ApplicationState>(
	(state: ApplicationState) => ({
		...state.pet,
		clinics: state.clinics,
		crematories: state.crematories,
		pets: state.pets,
		user: state.login.user,
		locations: state.clinicLocations,
		carriers: state.carriers,
		crematoryList: state.selects.crematories.items,
		clinicsList: state.selects.clinics.items,
	}),
	dispatchToProps,
)(PetEditorInner);
