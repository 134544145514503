import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import {
	Field,
	FieldArray,
	FieldArrayRenderProps,
	FieldProps,
	useFormikContext,
} from 'formik';
import { useTranslation } from 'react-i18next';
import Table, { ColumnProps } from 'antd/lib/table';
import Button from 'antd/lib/button';
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';

import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { Price, PriceKind } from '@app/objects/Price';
import { PetPrice } from '@app/objects/Pet';
import { useModal } from '@app/hooks/Editors/useModal';
import { Preview } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/Preview';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { ProductModal } from '@app/components/Pages/PetEditor/ProductsModal';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';

import { ApplicationState } from '@app/store';
import { ItemCategory } from '@app/objects/Inventory';
import { RootKey } from '@app/store/SelectList/SelectList';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { ProductSelectFilter, isEqualProduct } from '@app/store/SelectList/SelectsInterfaces';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { Query } from '@app/services/query/Query';

export interface ProductsSectionProps {
	prices: Array<Price>;
	onLoadPrice: (values) => void;
}

export function getColumns(
	translate: (key: string) => string,
	setPreview: (value?: string) => void,
	showPricesToClinic?: boolean,
): Array<ColumnProps<PetPrice>> {
	return TableBuilder.shape<PetPrice>()
		.addColumn({
			title: <b>Name</b>,
			width: '30%',
			dataIndex: 'name',
		})
		.addColumn({
			title: <b>{translate('labels.product-count')}</b>,
			dataIndex: 'count',
			width: '150px',
			render: (value: number, record: PetPrice, id: number) => (
				<Field name={`products.${id}.count`}>
					{(fieldProps: FieldProps<PetFormValues>) => {
						const onChange = (value: number) => {
							if (!record.price) return;

							const count = value;
							const currentValue = getPriceValue(record.price, count);

							const values: Array<PetPrice> = fieldProps.form.values.products.map((q: PetPrice) => {
								if (q.clientId === record.clientId) {
									return {
										...q,
										count,

										value: currentValue.value,
										extra: currentValue.extra,
									};
								}

								return q;
							});

							fieldProps.form.setFieldValue('products', values);
						};
						const error = fieldProps.form.errors.products?.[id];
						const touched = fieldProps.form.touched.products?.[id]?.count;

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.count
									? 'table-cell-error'
									: ''}
								render={({ field }: FieldProps<number, PetFormValues>) => (
									<NumberField {...field} onChange={onChange} />
								)}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: <b>Cost</b>,
			dataIndex: 'value',
			width: '150px',
			render: (value: number, record: PetPrice, id: number) => (
				<Field name={`products.${id}.value`}>
					{(fieldProps: FieldProps<PetFormValues>) => {
						const onChange = (value: number) => {
							if (!record.price) return;

							const values: Array<PetPrice> = fieldProps.form.values.products.map((q: PetPrice) => {
								if (q.clientId === record.clientId) {
									return {
										...q,
										value,
									};
								}

								return q;
							});

							fieldProps.form.setFieldValue('products', values);
						};
						const error = fieldProps.form.errors.products?.[id];
						const touched = fieldProps.form.touched.products?.[id]?.value;

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.value
									? 'table-cell-error'
									: ''}
								render={({ field }: FieldProps<number, PetFormValues>) => (
									<NumberField {...field} onChange={onChange} />
								)}
							/>
						);
					}}
				</Field>
			),
		}, showPricesToClinic)
		.addColumn({
			title: '',
			align: 'right',
			dataIndex: 'image',
			render: (_, record: PetPrice) => (
				<Field name="products">
					{() => {
						const inventoryItem = record.price?.inventoryItem;
						const image = inventoryItem?.originalAvatar ?? inventoryItem?.avatar;

						if (!image) return null;

						return (
							<Button
								type="link"
								onClick={() => setPreview(image)}
								icon={<EyeOutlined />}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			width: '40px',
			render: (value, item: PetPrice, id: number) => (
				<FieldArray
					name="products"
					render={(props: FieldArrayRenderProps) => (
						<Button
							type="text"
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								if (item.id > 0) {
									props.replace(id, { ...item, removed: true });
									// Move item to the end of the list (so that order of existing items
									// Confirms to order of visible items
									props.move(id, props.form.values.products.length - 1);
								} else {
									props.remove(id);
								}
							}}
						/>
					)}
				/>
			),
		})
		.build();
}

function getAvailablePrices(prices: Array<Price>, clinicId: Nullable<number>): Query<Price> {
	const base: Query<Price> = new Query<Price>(prices)
		.where((q) => q.priceKind === PriceKind.ProductPrice);

	const clinicQuery = base.clone().where((q: Price) => q.clinicId === clinicId);

	if (clinicQuery.any()) return clinicQuery;

	return base.where((q) => q.clinicId === null);
}

export const ProductsSection = ({ prices, onLoadPrice }: ProductsSectionProps) => {
	const modal = useModal(false);
	const { t } = useTranslation();
	const translate = (key: string) => t(iKey(key));
	const { values, setFieldValue } = useFormikContext<PetFormValues>();

	const [preview, setPreview] = React.useState<string | undefined>(undefined);

	const dispatch = useDispatch();
	const store = useSelector((state: ApplicationState) => state.selects.products);
	const actions = bindActionCreators(getActionCreators(
		'products',
		{ endpoint: 'inventoryItemList', key: RootKey, equal: isEqualProduct },
	), dispatch);
	const categoryFilters: Omit<ProductSelectFilter, 'category'> = React.useMemo(() => ({
		search: '',
		crematoryId: values.crematoryId,
		serviceType: values.serviceType,
		priceType: values.priceType,
		category: [ItemCategory.ProductCategory, ItemCategory.Product],
		parentId: [null],
	}), [values.crematoryId, values.serviceType, values.priceType, values.clinicId]);

	const priceRequestParams = React.useMemo(() => ({
		crematoryId: values.crematoryId,
		serviceType: values.serviceType,
		priceType: values.priceType,
		clinicId: values.clinicId,
	}), [values.crematoryId, values.serviceType, values.priceType, values.clinicId]);

	React.useEffect(() => {
		actions.request(categoryFilters);
	}, [categoryFilters]);

	React.useEffect(() => {
		onLoadPrice(priceRequestParams);
	}, [priceRequestParams]);

	const availablePrices = React.useMemo(() => getAvailablePrices(prices, values.clinicId).array(), [prices]);

	if (!store[RootKey]?.items || !availablePrices.length) return null;

	return (
		<>
			<SectionContainer titleLocalizationKey="sections.products">
				<FieldArray
					name="products"
					render={(props: FieldArrayRenderProps) => (
						<div className="urns-container">
							<div className="table-controls__container">
								<div className="table-controls__action-container pull-right">
									<div className="table-controls__action">
										<Button
											type="link"
											onClick={modal.open}
											icon={<PlusOutlined />}
										>
											Add Product
										</Button>
									</div>
								</div>
							</div>
							<Table
								columns={getColumns(translate, setPreview)}
								dataSource={props.form.values.products?.filter((q: PetPrice) => !q.removed)}
								size="small"
								rowKey="clientId"
								pagination={false}
								className="form-group"
							/>
						</div>
					)}
				/>
			</SectionContainer>
			<ProductModal
				modal={modal}
				prices={prices}
				onChange={(items: Array<PetPrice>) => setFieldValue('products', items)}

				petId={values.id}
				products={values.products?.filter((q: PetPrice) => !q.removed)}

				crematoryId={values.crematoryId}
				clinicId={values.clinicId}

				serviceType={values.serviceType}
				priceType={values.priceType}
			/>
			<Preview image={preview} onClose={() => setPreview(undefined)} />
		</>
	);
};
