import {
	createStore,
	applyMiddleware,
	compose,
	combineReducers,
	Store,
	StoreEnhancerStoreCreator,
	ReducersMapObject,
	StoreEnhancer, Action,
} from 'redux';
import thunk from 'redux-thunk';

import { History } from 'history';

import { BaseUser } from '@common/react/objects/BaseUser';

import { TypeKeys } from './store/Login';
import { BaseApplicationState } from './store';

let store: Store<BaseApplicationState<BaseUser>> | null = null;
const devtoolsExtensionKey: string = '__REDUX_DEVTOOLS_EXTENSION__';

export function getStore<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(): Store<TApplicationState> | null {
	if (store) return store as Store<TApplicationState>;

	return null;
}

function buildRootReducer<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(allReducers: ReducersMapObject<TApplicationState>) {
	return (state: TApplicationState, action: Action) => {
		const appReducer = combineReducers<TApplicationState>(allReducers);

		if (action.type === TypeKeys.CLEARSTATE) {
			return appReducer(undefined, action);
		}

		return appReducer(state, action);
	};
}

export function updateReducers<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(reducers: ReducersMapObject<TApplicationState>): void {
	if (store === null) throw 'configureStore must be called first!';

	const builtReducers = buildRootReducer<TUser, TApplicationState>(reducers);

	// @ts-ignore
	store.replaceReducer(builtReducers);
}

export default function configureStore<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(history: History, reducers: ReducersMapObject, initialState?: TApplicationState): (Store<TApplicationState>) {
	// Build middleware. These are functions that can process the actions before they reach the store.
	const windowIfDefined = typeof window === 'undefined' ? null : window as any;
	// If devTools is installed, connect to it
	const devToolsExtension = windowIfDefined && windowIfDefined[devtoolsExtensionKey] as () => StoreEnhancer;
	const middlewares: StoreEnhancer = compose(
		applyMiddleware(thunk),
		devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next,
	);

	// Combine all reducers and instantiate the app-wide store instance
	const allReducers = buildRootReducer(reducers);

	// if (typeof createStoreWithMiddleware === 'function') {
	// 	store = createStoreWithMiddleware(allReducers, initialState) as Store<TApplicationState>;
	// }
	store = createStore(allReducers, initialState, middlewares);

	return store as Store<TApplicationState>;
}
