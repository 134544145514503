import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	DeliveryType,
	Pet,
	PetPrice,
	ServiceType,
	WeightSource,
} from '@app/objects/Pet';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import { getWeight } from '@app/components/Utils/Prices/Helpers';
import { TrackChange } from '@app/smart components/Tracker/TrackChange';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { getCrematory, LoadPricesParams } from '@app/components/Pages/PetEditor/OldPetEditor/Editor';
import { CrematoryListInfo, CrematoryServiceTypePreferences, getServiceTypePreferenceKind } from '@app/objects/Crematory';
import { Carrier } from '@app/objects/Carriers';
import { toUpperCase } from '@app/components/Utils/Utils';
import { ClinicSelectInfo } from '@app/objects/Clinic';

interface PetTrackChangeProps {
	item: Pet;
	prices: Array<Price>;
	reloadPrices: () => void;
	onLoadPrices: (params: LoadPricesParams) => void,
	loadCrematoryDependencies: (id: number) => void,
	onChangeDisableHistory: (value: React.SetStateAction<boolean>) => void;
	onChangeServiceTypePreferences: (value: CrematoryServiceTypePreferences) => void;
}

export function setDefaultCarrier(
	values: PetFormValues,
	carriers: Array<Carrier>,
	crematories: Array<CrematoryListInfo>,
	onChangeValues: (field: string, value: Nullable<number>) => void,
) {
	if (values.crematoryId <= 0) return;

	const crematory = crematories.find((item: CrematoryListInfo) => item.id === values.crematoryId);
	if (!crematory) return;

	let value: Nullable<number> = null;

	if (crematory && crematory.defaultCarrier) {
		value = crematory.defaultCarrier.id;
	} else if (carriers.length === 1) {
		value = carriers[0].id;
	}

	onChangeValues('carrierId', value);
}

export function setDefaultDeliveryType(
	values: PetFormValues,
	crematories: Array<CrematoryListInfo>,
	clinics: Array<ClinicSelectInfo>,
	onChangeValues: (field: string, value: DeliveryType) => void,
) {
	if (values.crematoryId > -1) {
		const crematory = crematories.find((item) => item.id === values.crematoryId);
		if (!crematory) return;

		const crematoryServiceType = getServiceTypePreferenceKind(values.serviceType);

		let deliveryType = crematory.private.defaultRetailDeliveryType;

		if (values.priceType === PriceType.Retail) deliveryType = crematory?.[crematoryServiceType]?.defaultRetailDeliveryType;
		if (values.priceType === PriceType.Wholesale) {
			const clinic = clinics.find((i) => i.id === values.clinicId);
			const option = `default${toUpperCase(crematoryServiceType)}DeliveryType`;

			deliveryType = clinic ? clinic?.[option] : deliveryType;
		}

		onChangeValues('deliveryType', deliveryType);
	}
}

function loadPricesBySelect(
	values: PetFormValues,
	initialValues: PetFormValues,
	prices: Array<Price>,
	onLoadPrices: (params: LoadPricesParams) => void,
	priceKind?: PriceKind,
) {
	const params = {
		crematoryId: values.crematoryId,
		clinicId: values.clinicId,

		priceType: values.priceType,
		serviceType: values.serviceType,
	};

	const isPriceAlreadyLoaded = prices.length;

	if (priceKind === PriceKind.SpecialServicePrice) {
		const originServices = initialValues.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;
		const updateServices = values.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;

		if (updateServices && originServices && (originServices <= updateServices) && isPriceAlreadyLoaded) return;
	}

	if (priceKind === PriceKind.UrnPrice) {
		const originUrns = initialValues.urns.length;
		const updateUrns = values.urns.length;

		if (originUrns <= updateUrns && isPriceAlreadyLoaded) return;
	}

	if (priceKind === PriceKind.ProductPrice) {
		const originServices = initialValues.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;
		const updateServices = values.products.length;

		if (updateServices && originServices && (originServices <= updateServices) && isPriceAlreadyLoaded) return;
	}

	onLoadPrices(params);
}

export const PetTrackChange: React.FC<PetTrackChangeProps> = (props: PetTrackChangeProps) => {
	const { values, setFieldValue, initialValues } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematories = useSelector((state: ApplicationState) => state.crematories.items);
	const carriers = useSelector((state: ApplicationState) => state.carriers.items);
	const clinics = useSelector((state: ApplicationState) => state.selects.clinics.items);

	const onLoadPricesBySelect = React.useCallback((val?: PriceKind) =>
		loadPricesBySelect(values, initialValues, props.prices, props.onLoadPrices, val), [
		values, initialValues, props.prices,
	]);
	const onChangeDefaultDeliveryType = React.useCallback(() => setDefaultDeliveryType(values, crematories, clinics, setFieldValue), [
		values, crematories, clinics,
	]);

	const handleWeightChange = () => {
		if (values) {
			const oldCrematory = getCrematory(props.item, user, crematories);
			const newCrematory = getCrematory(values, user, crematories);

			const oldSource = oldCrematory?.weightSource ?? WeightSource.ActualWeight;
			const newSource = newCrematory?.weightSource ?? WeightSource.ActualWeight;

			const oldWeight = props.item ? getWeight(props.item, oldSource) : 0;
			const newWeight = getWeight(values, newSource);
			const hasChanged = oldWeight !== newWeight;

			if (hasChanged && props.prices.length === 0) props.reloadPrices();
		}
	};

	const setServiceTypePreferences = () => {
		const option = getServiceTypePreferenceKind(values.serviceType);

		const crematoryId = user?.role === UserRole.Admin ? values.crematoryId : user?.activeCrematoryId;
		const crematory = crematories.find((i) => i.id === crematoryId);

		props.onChangeServiceTypePreferences(crematory?.[option]);
	};

	const onChangeLoadPrices = () => {
		const petExists = values.id > 0;
		if (petExists) props.onChangeDisableHistory((prev) => !prev);

		onLoadPricesBySelect();
	};

	React.useEffect(() => setServiceTypePreferences(), [crematories]);

	return (
		<>
			<TrackChange
				track={values.crematoryId}
				onChange={(id: number) => {
					if (id <= 0) return;

					onChangeLoadPrices();
					props.loadCrematoryDependencies(id);
					setServiceTypePreferences();
				}}
			/>
			<TrackChange
				track={values.priceType}
				onChange={() => {
					onChangeDefaultDeliveryType();
					onChangeLoadPrices();
				}}
			/>
			<TrackChange
				track={values.serviceType}
				onChange={(value: ServiceType) => {
					onChangeDefaultDeliveryType();
					onChangeLoadPrices();
					setServiceTypePreferences();

					if (value === ServiceType.Communal) {
						setFieldValue('engraving', []);
						setFieldValue('urns', []);
					}
				}}
			/>
			<TrackChange
				track={values.clinicId}
				onChange={() => {
					onChangeLoadPrices();

					if (values.priceType === PriceType.Wholesale) onChangeDefaultDeliveryType();
				}}
			/>
			<TrackChange
				track={values.deliveryType}
				onChange={(value: DeliveryType) => {
					const crematoryId = user?.role !== UserRole.Crematory
						? values.crematoryId : user.activeCrematoryId;

					if (value === DeliveryType.Mail && crematoryId > 0) setDefaultCarrier(values, carriers, crematories, setFieldValue);
				}}
			/>
			<TrackChange
				track={values.actualWeight}
				onChange={() => handleWeightChange()}
			/>
			<TrackChange
				track={values.reportedWeight}
				onChange={() => handleWeightChange()}
			/>
			<TrackChange
				track={values.urns}
				onChange={() => onLoadPricesBySelect(PriceKind.UrnPrice)}
			/>
			<TrackChange
				track={values.services}
				onChange={() => onLoadPricesBySelect(PriceKind.SpecialServicePrice)}
			/>
			<TrackChange
				track={values.discountId}
				onChange={() => onLoadPricesBySelect()}
			/>
			<TrackChange
				track={values.products}
				onChange={() => onLoadPricesBySelect(PriceKind.ProductPrice)}
			/>
		</>
	);
};
