import * as React from 'react';

import clsx from 'clsx';
import { FieldProps, ErrorMessage } from 'formik';
import Input, { InputProps } from 'antd/lib/input';

export type FormikInputRenderFunc = (fieldProps: FieldProps, inputProps?: InputProps) => React.ReactNode;

interface ErrorComponentProps {
	error: string | Record<string, string>;
}

export interface FormikInputProps {
	fieldProps: FieldProps;

	title?: React.ReactNode;
	inputId?: string;
	inputProps?: InputProps;

	containerClassName?: string;
	render?: FormikInputRenderFunc;

	showValidateAfterSubmit?: boolean;
	ErrorComponent?: React.FC<ErrorComponentProps>;
}

const defaultRender = ({ field }: FieldProps, inputProps?: InputProps) => (
	<Input type="text" id={field.name} {...field} {...inputProps} />
);

export const defaultErrorComponent: React.FC<ErrorComponentProps> = (props: ErrorComponentProps) => (
	<div className="validation-message">
		{typeof props.error === 'string' ? props.error : Object.keys(props.error)
			.filter((key: string) => typeof props.error[key] === 'string')
			.map((key: string) => props.error[key])
			.join(', ')}
	</div>
);

export const FormikInput: React.FC<FormikInputProps> = ({
	fieldProps,
	containerClassName = 'form-group col-sm-6',
	render = defaultRender,
	title,
	inputId,
	showValidateAfterSubmit = true,
	inputProps,
	ErrorComponent = defaultErrorComponent,
}) => {
	const { form, field } = fieldProps;

	const errorMessage = (showValidateAfterSubmit ? form.submitCount > 0 : true)
		? <ErrorMessage name={field.name} render={(msg: string | Record<string, string>) => <ErrorComponent error={msg} />} />
		: null;
	const isError = errorMessage && Object.keys(form.errors).includes(field.name);

	return (
		<div className={clsx('is-relative', isError && 'has-error', containerClassName)}>
			{title && <label htmlFor={inputId || field.name}>{title}</label>}
			{render(fieldProps, inputProps)}
			{errorMessage}
		</div>
	);
};
