import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Modal from 'antd/lib/modal';

import { request } from '@common/react/components/Api';
import { member } from '@common/react/utils/decorators';
import { WithFile } from '@common/typescript/objects/WithFile';
import { FileWall } from '@common/react/components/Forms/Files/FileWall';
import { ImageComponent } from '@common/react/components/UI/Image/Image';
import {
	FileType,
	FileInterface,
} from '@common/typescript/objects/FileInterface';

import { alertMessage, MessageType } from '@app/utilities/alert';
import { Thumbnail } from '@app/components/Forms/Thumbnail';
import { deleteConfirmation } from '@app/components/UI/Modal/DeleteConfirmation';

import noImage from '@images/no_image.jpg';

interface PictureWallProps<TEntity> extends WithTranslation {
	id: number;
	type: string;

	object?: Array<WithFile<TEntity>>;
	onUpdate?: (result) => void;

	buttonCaption?: string;
	infoMessage?: string;
	fileType?: FileType;
	propertyOrigin?: string;

	style?: React.CSSProperties;
	lineHeight?: number;
	withDeleteBtn?: boolean;
	asButton?: boolean;
	buttonClassName?: string;
	withProgressBar?: boolean;
	withConfirm?: boolean;
}

interface PictureWallState {
	previewVisible: boolean;
	previewImage: string;
}

class PictureWallInner<TEntity> extends React.Component<PictureWallProps<TEntity>, PictureWallState> {
	public state: PictureWallState = {
		previewVisible: false,
		previewImage: '',
	};

	@member
	onConfirmDelete(id: number): void {
		deleteConfirmation({
			title: 'image',
			callback: () => this.removeFileUpload(id),
			t: this.props.t,
		});
	}

	@member
	onUpdate(result: FileInterface): void {
		if (result && Array.isArray(this.props.object)) {
			const link: WithFile<TEntity> = {
				id: -1,
				file: result,
				fileId: result.id,
				objectId: this.props.id,
				object: undefined,
			};
			this.props.onUpdate?.([...this.props.object, link]);
		}
	}

	@member
	removeFileUpload(value: number): Promise<void> | undefined {
		const {
			propertyOrigin = 'originalPicture',
			onUpdate,
			object,
			type,
			id,
		} = this.props;

		if (object) {
			return request('uploadedFile', {
				objectType: type,
				objectId: id,
				src: object[propertyOrigin],
				id: value,
				deleted: true,
			})
				.then(() => {
					if (Array.isArray(object)) {
						onUpdate?.(object.filter((q: WithFile<TEntity>) => q.fileId !== value));
					}
				})
				.catch((error: string) => alertMessage(MessageType.error, error));
		}

		return undefined;
	}

	@member
	handleCancel(): void {
		this.setState({ previewVisible: false });
	}

	@member
	handlePreview(path: string): void {
		this.setState({
			previewImage: path,
			previewVisible: true,
		});
	}

	render(): JSX.Element {
		const {
			id,
			type,
			object,
			buttonCaption,
			infoMessage,
			fileType = FileType.Avatar,
			style,
			lineHeight,
			withDeleteBtn = true,
			asButton,
			buttonClassName,
			withProgressBar = false,
			withConfirm = false,
		} = this.props;

		let contents;

		if (Array.isArray(object)) {
			contents = (
				<>
					{object.map((item: WithFile<TEntity>) => (
						<Thumbnail
							withConfirm={withConfirm}
							id={item.fileId}
							key={item.fileId}
							path={item.file.src}
							thumb={item.file.thumb}
							confirmDelete={this.onConfirmDelete}
							removeFileUpload={this.removeFileUpload}
							openPreview={this.handlePreview}
							style={style}
							lineHeight={lineHeight}
							withDeleteBtn={withDeleteBtn}
						/>
					))}
					{this.props.onUpdate
						? (
							<FileWall
								buttonCaption={buttonCaption}
								infoMessage={infoMessage}
								fileType={fileType}
								objectId={id}
								count={object.length}
								type={type}
								onUpdate={this.onUpdate}
								multiple
								asButton={asButton}
								buttonClassName={buttonClassName}
								withProgressBar={withProgressBar}
							/>
						) : null}
				</>
			);
		}

		return (
			<>
				{contents}
				<Modal
					open={this.state.previewVisible}
					footer={null}
					onCancel={this.handleCancel}
					centered
				>
					<ImageComponent src={this.state.previewImage} alt="Source image" fallback={noImage} width="100%" />
				</Modal>
			</>
		);
	}
}

export const PictureWall = withTranslation()(PictureWallInner);
