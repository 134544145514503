import * as React from 'react';
import { useHistory, useLocation, generatePath } from 'react-router-dom';

import Menu from 'antd/lib/menu';

import { MenuItem } from '@app/objects/MenuItem';
import { getMatchedKey, getSelectedKeys } from '@app/components/UI/SidePanel/MenuItems';

const params = { page: 0 };

interface PanelMenuProps {
	items: Array<MenuItem>;
	toggle: (state?: boolean) => void;
}

export const PanelMenu: React.FC<PanelMenuProps> = (props: PanelMenuProps): JSX.Element => {
	const history = useHistory();

	const location = useLocation();
	const selectedKeys = getSelectedKeys(location.pathname);

	return (
		<Menu
			selectedKeys={selectedKeys}
			onSelect={() => props.toggle(false)}
			mode="inline"
		>
			{props.items.map((item) => {
				const itemPathKey = getMatchedKey(item.path);
				if (item.children) {
					return (
						<Menu.SubMenu key={itemPathKey} title={item.name}>
							{item.children.list.map((child) => {
								const childItemPathKey = getMatchedKey(child.path);

								return (
									<Menu.Item
										key={childItemPathKey}
										onClick={() => {
											const path = generatePath(childItemPathKey, params);
											history.push(path);
										}}
									>
										<span>{child.name}</span>
									</Menu.Item>
								);
							})}
						</Menu.SubMenu>
					);
				}

				return (
					<Menu.Item
						key={itemPathKey}
						onClick={() => {
							const path = generatePath(item.path, params);
							history.replace(path);
						}}
					>
						<span>{item.name}</span>
					</Menu.Item>
				);
			})}
		</Menu>
	);
};
