import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

export interface LabelPaperMargins {
	left: number;
	right: number;
	top: number;
	bottom: number;
}

export interface LabelPaperTypography {
	titleFontSize: number;
	headerFontSize: number;
	baseFontSize: number;
}

export interface LabelPaper extends WithId {
	name: string;
	description: string;

	printQr: boolean;

	crematoryId: Nullable<number>;

	margins: LabelPaperMargins;
	labelMargins: LabelPaperMargins;
	labelPaddings: LabelPaperMargins;
	typography: LabelPaperTypography;

	width: number;
	height: number;
	orientation: Orientation;
	unit: LengthUnit;

	rows: number;
	columns: number;

	spacing: Spacing;
}

export enum Orientation {
	Landscape = 0,
	Portrait = 1,
}

export enum LengthUnit {
	Millimeter = 0,
	Centimeter = 1,
	Inch = 2,
}

export interface Spacing {
	column: number;
	row: number;
}
