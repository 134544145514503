import * as React from 'react';

import { PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';
import { WeightForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/WeightForm';
import { Pet } from '@app/objects/Pet';
import { SpecialServicesForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/SpecialServicesForm';
import { BatchForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/BatchForm';
import { UrnForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/UrnForm';
import { HistoryChangeForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/HistoryChangeForm';
import { TrackingForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/TrackingForm';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { InternalIdForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/InternalIdForm';
import { ProductForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/ProductForm';

interface SpecificContentProps {
	code: PromoteResponseCode;

	item: Pet;
	onChange: (item: Partial<Pet>, shouldClose?: boolean, params?: PromoteParams) => void;
	onClose: () => void;
}

export const SpecificContent: React.FC<SpecificContentProps> = (props: SpecificContentProps) => {
	switch (props.code) {
	case PromoteResponseCode.MissingActualWeight:
	case PromoteResponseCode.MissingAshesWeight:
		return (
			<WeightForm
				code={props.code}
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);

	case PromoteResponseCode.ServicesWarning:
		return (
			<SpecialServicesForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);

	case PromoteResponseCode.BatchWarning:
		return (
			<BatchForm
				item={props.item}
				onClose={props.onClose}
			/>
		);

	case PromoteResponseCode.MissingUrnWarning:
		return (
			<UrnForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);
	case PromoteResponseCode.ConfirmProducts:
		return (
			<ProductForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);
	case PromoteResponseCode.HasUnconfirmedChanges:
		return (
			<HistoryChangeForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);

	case PromoteResponseCode.RequireTrackingNumber:
		return (
			<TrackingForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);
	case PromoteResponseCode.MissingInternalId:
		return (
			<InternalIdForm
				item={props.item}
				onChange={props.onChange}
				onClose={props.onClose}
			/>
		);

	default:
		return null;
	}
};
