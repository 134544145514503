import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import { schema as EnUsSchema } from '@app/internationalization/en-us/schema';

export type Translator = (key: string) => string;

export const defaultLanguageCode: string = 'en-US';
export function initInternationalization(): void {
	i18next
		.use(resourcesToBackend((language) =>
			import(`@app/internationalization/${language}/schema.ts`)
				.then((res) => res.schema)))
		.use(initReactI18next)
		.init({
			partialBundledLanguages: true,
			resources: {
				'en-US': {
					translation: EnUsSchema,
				},
			},
			fallbackLng: defaultLanguageCode,
			interpolation: {
				escapeValue: false,
			},
			lowerCaseLng: true,
			load: 'currentOnly',
			react: {
				useSuspense: false,
			},
		});
}
