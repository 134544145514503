import React from 'react';

import { v4 } from 'uuid';
import {
	Field, FieldProps, Form, Formik,
	useFormikContext,
} from 'formik';
import Table, { ColumnProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import Input from 'antd/lib/input';

import { Nullable, isPresent } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { Price, PriceType } from '@app/objects/Price';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import {
	SpecialServiceActions,
	getPriceValue,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { useRequest } from '@app/hooks/useRequest';
import { ModalControls } from '@app/hooks/Editors/useModal';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';

interface SpecialServiceDialogProps {
	modal: ModalControls;
	actions: SpecialServiceActions;

	selection: Array<number>;
	onChangeSelection: (value: Array<number>) => void;

	items?: Array<PetPrice>;
	serviceType: ServiceType;
	priceType: PriceType;
	crematoryId: number;
	clinicId?: Nullable<number>;
	petId: number;
}

interface Base {
	pendingOnly: boolean;
}

interface ExistingPetProps extends Base {
	petId: number;
	excluding: Array<number>;
	filters: {
		name: string;
	};
}

interface CreatePetProps extends Base {
	pet: {
		crematoryId: number;
		priceType: PriceType;
		serviceType: ServiceType;
		clinicId: Nullable<number>;
	};
	filters: {
		name: string;
	};
}

type ServiceProps = ExistingPetProps | CreatePetProps;

function isAvailable(item: Price, picked: Array<PetPrice>, available: Array<Price>, petId: number): boolean {
	if (picked.filter((item: PetPrice) => !item.removed).find((q: PetPrice) => q.priceId === item.id)) return false;

	return petId < 1 || !!available.find((q: Price) => q.specialServiceId === item.specialServiceId);
}

function sortServices(a: Price, b: Price): number {
	if (!a.specialService || !b.specialService) return 0;
	if (a.specialService.name > b.specialService.name) return 1;

	return -1;
}

function getExcluding(list?: Array<PetPrice>): Array<number> {
	if (!list) return [];

	const ids = list
		.filter((item: PetPrice) => !item.removed)
		.map((item: PetPrice) => item.price?.specialServiceId)
		.filter(isPresent);

	return Array.from(new Set(ids));
}

export function getPetPrice(services: Array<Price>, petId: number) {
	return services.map((item: Price) => {
		const value = getPriceValue(item);

		return {
			id: -1,
			clientId: v4(),
			deleted: false,

			priceId: item.id,
			price: item,

			pet: null,
			petId,

			count: 1,
			completedCount: 0,
			done: false,

			value: value.value,
			extra: value.extra,

			batchCount: item.batchCount,
			batchPrice: item.batchPrice,

			node: null,
			nodeId: null,

			name: item.specialService?.name ?? 'Special Service',
			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			note: item.specialService?.defaultNote ?? '',
		};
	});
}

function filterServices(services: Array<Price>, diff: Array<number>) {
	return services.filter((item: Price) => !!diff.find((id: number) => item.id === id));
}

export const getPrice = (value: number): string => {
	if (value === 0) return 'Free';

	return `$${value.toFixed(2)}`;
};

// Add Special Service dialog columns
const columns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: <b>Name</b>,
		dataIndex: ['specialService', 'name'],
	})
	.addColumn({
		title: <b>Description</b>,
		dataIndex: ['specialService', 'description'],
	})
	.addColumn({
		title: <b>Note</b>,
		dataIndex: ['specialService', 'defaultNote'],
		render: (_, record: Price, index) => {
			if (!record.specialService?.hasNote) return null;

			return (
				<Field name={`${index}.specialService.defaultNote`}>
					{({ field, form, meta }: FieldProps<string, Price>) => (
						<FormikInput
							fieldProps={{ field, form, meta }}
							containerClassName=""
							render={({ field, form }) => (
								<Input
									value={field.value}
									id={field.name}
									onClick={(e) => e.stopPropagation()}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										form.setFieldValue(field.name, e.currentTarget.value);
									}}
								/>
							)}
						/>
					)}
				</Field>
			);
		},
	})
	.addColumn({
		title: '',
		dataIndex: 'value',
		align: 'right',
		render: (value: number) => (
			<MobileCell caption="">{getPrice(value)}</MobileCell>
		),
	})
	.build();

export const SpecialServiceDialog: React.FC<SpecialServiceDialogProps> = (props: SpecialServiceDialogProps) => {
	const [query, setQuery] = React.useState<string>('');
	const [services, setServices] = React.useState<Array<Price>>(() => []);
	const { initialValues } = useFormikContext<PetFormValues>();

	const availableServices = useRequest<Array<Price>, ServiceProps>('availableSpecialServiceList', undefined, { requestOnMount: false });

	const excludingSpecialServices = React.useMemo(() => ({
		excluding: getExcluding(props.items),
	}), [props.items]);

	const params: Pick<CreatePetProps, 'pet'> | Pick<ExistingPetProps, 'petId'> = React.useMemo(() => {
		const values = {
			pet: {
				crematoryId: props.crematoryId,
				serviceType: props.serviceType,
				priceType: props.priceType,
				clinicId: props.clinicId ?? null,
			},
		};

		const isEqual = initialValues.clinicId !== props.clinicId
			|| initialValues.crematoryId !== props.crematoryId
			|| initialValues.priceType !== props.priceType
			|| initialValues.serviceType !== props.serviceType;

		if (props.petId > 0 && !isEqual) return { petId: props.petId };

		return values;
	}, [
		props.serviceType,
		props.priceType,
		props.crematoryId,
		props.clinicId,
		props.petId,
		initialValues.priceType,
		initialValues.serviceType,
		initialValues.crematoryId,
		initialValues.clinicId,
	]);

	React.useEffect(() => {
		if (!props.modal.state) return;

		availableServices.reload({
			filters: { name: query },
			...params,
			...excludingSpecialServices,
			pendingOnly: true,
		});
	}, [props.serviceType, props.priceType, props.crematoryId, props.clinicId, props.petId, query, props.modal.state]);

	React.useEffect(() => {
		if (props.modal.state) {
			const available: Array<Price> = availableServices.item ?? [];

			setServices([...available].sort(sortServices));
		}
	}, [props.items, availableServices.item, props.modal.state]);

	return (
		<Formik
			initialValues={services}
			onSubmit={(values) => {
				const diff = props.selection.filter((id: number) => !(props.items ?? []).find((item: PetPrice) => item.priceId === id));
				const items: Array<PetPrice> = getPetPrice(filterServices(values, diff), props.petId);
				props.actions.add(items);

				props.modal.close();
				setQuery('');
				props.onChangeSelection([]);
			}}
			enableReinitialize
		>
			{(formikBag) => (
				<Form id="services-modal-from">
					<ModalWrapper
						title="Add Special Service"
						modalClassName="services-modal"
						isOpened={props.modal.state}
						onOk={formikBag.submitForm}
						onCancel={() => {
							props.modal.close();
							setQuery('');
						}}
						width="60%"
					>
						<Search
							allowClear
							placeholder="Search by Name"
							onSearch={(value: string) => setQuery(value)}
							style={{ marginBottom: '10px' }}
							enterButton
						/>
						<Table
							columns={columns}
							dataSource={formikBag.values}
							pagination={{
								total: services.length,
								pageSize: 20,
								hideOnSinglePage: true,
							}}
							rowSelection={{
								selectedRowKeys: props.selection,
								onChange: (selection: Array<React.Key>) => props.onChangeSelection(selection as Array<number>),
								getCheckboxProps: (item: Price) => ({
									disabled: !isAvailable(item, (props.items ?? []), (availableServices.item ?? []), props.petId),
								}),
							}}
							onRow={(record) => ({
								onClick: (event) => {
									event.preventDefault();
									const isSelect = props.selection.includes(record.id);
									if (!isSelect) {
										props.onChangeSelection([...props.selection, record.id]);
									} else {
										props.onChangeSelection(props.selection.filter((s) => s !== record.id));
									}
								},
							})}
							loading={availableServices.loading}
							childrenColumnName="child"
							rowKey="id"
							className="table-mobile pets-table"
							size="small"
						/>
						<AlertMessage message={availableServices.error} />
					</ModalWrapper>
				</Form>
			)}
		</Formik>
	);
};
