import * as React from 'react';

import * as dayjs from 'dayjs';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker';

import { DatePicker } from '@common/react/components/Forms/Pickers/index';

const { RangePicker } = DatePicker;

const disabledAfterToday = (currentDate: dayjs.Dayjs | null) => (currentDate ? currentDate.isAfter(dayjs(), 'day') : false);

const disabledBeforeToday = (currentDate: dayjs.Dayjs | null) => (currentDate ? currentDate.isBefore(dayjs(), 'day') : false);

export const disabledFrom = (from: number | null, withoutToday?: boolean) => (currentDate?: dayjs.Dayjs) => (
	currentDate ? (!withoutToday && currentDate.isBefore(dayjs(), 'day') || (from !== null ? currentDate.isBefore(dayjs(from), 'day') : false)) : false
);

export const disabledTo = (to: number | null, withoutToday?: boolean) => (currentDate?: dayjs.Dayjs) => (
	currentDate ? (!withoutToday && currentDate.isAfter(dayjs(), 'day') || (to !== null ? currentDate.isAfter(dayjs(to), 'day') : false)) : false
);

interface ComponentProps {
	format?: string;
	disabledDate?: (currentDate: dayjs.Dayjs | null) => boolean;
	maxDateToday?: boolean;
	minDateToday?: boolean;
	locale?: any;
	dateRender?: (current: dayjs.Dayjs, today: dayjs.Dayjs) => React.ReactNode;
}

type AntdProps<T> = T & { showTime?: boolean }
export interface DatepickerProps extends ComponentProps {
	value: number | null;
	onChange: (date: number | null) => void;
	antdProps?: AntdProps<DatePickerProps>;
}

interface RangepickerProps extends ComponentProps {
	value: [number, number] | null;
	onChange: (date: [number, number] | null) => void;
	antdProps?: AntdProps<RangePickerProps>;
}

const Datepicker: React.FC<DatepickerProps> =
	({ value, onChange, format = 'MM/DD/YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}, dateRender}) => {
		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;

		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;

		const defaultValue = value !== null && value !== undefined ? dayjs(value) : undefined;

		const innerOnChange = (date: dayjs.Dayjs | null) => {
			onChange(date === null ? null : +date);
		};

		return (
			<div className="datepicker-component">
				<DatePicker
					{...antdProps}
					format={antdProps && antdProps?.showTime ? 'MM/DD/YYYY HH:mm' : format}
					onChange={innerOnChange}
					disabledDate={disabledDate}
					locale={locale}
					value={defaultValue}
					getPopupContainer={antdProps.getPopupContainer}
					dateRender={dateRender}
				/>
			</div>
		);
	};

export const Rangepicker: React.FC<RangepickerProps> =
	({
		value, onChange, format = 'MM/DD/YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {},
	}) => {
		const innerOnChange = (date: any) => {
			onChange(date.length === 2 ? [+date[0], +date[1]] : null);
		};

		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;

		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;

		let defaultValue;

		if (value !== null && value[0] !== null && value[0] !== undefined && value[1] !== null && value[1] !== undefined ) {
			defaultValue = [dayjs(value[0] as number), dayjs(value[1] as number)];
		} else {
			defaultValue = [undefined, undefined];
		}

		return (
			<div className="datepicker-component">
				<RangePicker
					{...antdProps}
					format={antdProps && antdProps.showTime ? 'MM/DD/YYYY HH:mm' : format}
					onChange={innerOnChange}
					disabledDate={disabledDate}
					locale={locale}
					defaultValue={defaultValue}
					getPopupContainer={antdProps.getPopupContainer}
				/>
			</div>
		);
	};

export default Datepicker;
