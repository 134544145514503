import * as React from 'react';

import List from 'antd/lib/list';
import Empty from 'antd/lib/empty';

import { Loading } from '@common/react/components/UI/Loading/Loading';

import { useRequest } from '@app/hooks/useRequest';
import { PetSpecial, PetSpecialDescription } from '@app/objects/PetSpecial';
import { DateView } from '@app/components/UI/Views/DateView/DateView';

interface OwnProps {
	petId: number;
}

interface RequestParams {
	petId: number;
}

export const WhySpecial: React.FC<OwnProps> = (props: OwnProps) => {
	const request = useRequest<PetSpecial, RequestParams>('getPetSpecial', { petId: props.petId });

	if (request.loading) return <Loading />;
	if (!request.loading && !request.item) return <Empty description="No details on why pet is 'Special' found" />;

	return (
		<List
			loading={request.loading}
			dataSource={request.item?.descriptions ?? []}
			size="small"
			header={(
				<b>
					Last checked on <i><DateView view="long" date={request.item?.time ?? 0} /></i>
				</b>
			)}
			renderItem={(item: PetSpecialDescription) => (
				<List.Item>{item.description}</List.Item>
			)}
		/>
	);
};
