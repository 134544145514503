export interface ResponseErrorContent {
	message: string;
	code: number;
}

/**
 * This is the base error of request
 * When Request fails ResponseError object is generated
 */
export class ResponseError extends Error {
	public readonly code: number;

	constructor(content: ResponseErrorContent) {
		super(content.message);
		this.code = content.code;
		this.name = 'Response Error';
	}

	public get details(): string {
		return `<${this.name}> [${this.code}]: ${this.message}`;
	}
}
