import { Action, ActionCreatorsMapObject, Dispatch } from 'redux';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';
import { WithName } from '@common/typescript/objects/WithName';

import { request } from '@app/components/Api';

import { ApplicationState } from '@app/store';
import {
	SelectList,
	GeneralKey,
	getSelectItemState,
} from '@app/store/SelectList/SelectList';
import { FilterComparator } from '@app/store/SelectList/SelectsInterfaces';
import {
	EntityType,
	FilterType,
	StoreType,
	RecordType,
} from '@app/store/SelectList/UtilityTypes';

type GetState = () => ApplicationState;

export enum TypeKeys {
	REQUESTITEMS = 'SELECTREQUESTITEMS',
	RECEIVEITEMS = 'SELECTRECEIVEITEMS',
	RECEIVEERROR = 'SELECTRECEIVEERROR',
	REQUESTMOREITEMS = 'SELECTREQUESTMOREITEMS',
	RECEIVEMOREITEMS = 'SELECTRECEIVEMOREITEMS',
	UPDATEITEM = 'SELECTUPDATEITEM',
	REQUESTREFRESHITEMS = 'SELECTREQUESTREFRESHITEMS',
	RECEIVEREFRESHITEMS = 'SELECTRECEIVEREFRESHITEMS',
	RESETSTORE = 'RESETSTORE',
}

export interface IListAction<Type extends TypeKeys = TypeKeys, Payload = unknown> {
	type: Type;
	payload: Payload;
	store: keyof SelectList;
	key?: string; // This should be subtype of SelectList[store]
}

export type IListRequestAction<TFilters = null, T = null> = IListAction<TypeKeys.REQUESTITEMS, {
	filters: Nullable<TFilters>,
	requestId: number,
	params: Partial<T>,
}>;
export type IListReceiveAction<T> = IListAction<TypeKeys.RECEIVEITEMS, {
	items: Array<T>;
	total: number;
	requestId: number;
	}
>;
export type IListRequestMoreAction = IListAction<TypeKeys.REQUESTMOREITEMS>;
export type IListReceiveMoreAction<T> = IListAction<TypeKeys.RECEIVEMOREITEMS, {
	items: Array<T>;
	total: number;}
>;
export type IListRequestErrorAction = IListAction<TypeKeys.RECEIVEERROR, {
	error: string,
}>;
export type IListUpdateAction<T> = IListAction<TypeKeys.UPDATEITEM, Partial<T> & WithId>;

export type ListDispatchType = (action: IListAction) => IListAction;

export function isListAction(action: Action): action is IListAction {
	if (typeof action.type !== 'string') return false;

	const str = action.type as string;

	return (
		str === TypeKeys.REQUESTITEMS
		|| str === TypeKeys.RECEIVEITEMS
		|| str === TypeKeys.REQUESTMOREITEMS
		|| str === TypeKeys.RECEIVEMOREITEMS
		|| str === TypeKeys.RECEIVEERROR
		|| str === TypeKeys.UPDATEITEM
		|| str === TypeKeys.RESETSTORE
	);
}

interface Request<TValue, TKey extends keyof SelectList> {
	(filters: Nullable<FilterType<TKey>>, preselect?: TValue | Array<TValue>, count?: number): void;
	<T extends SelectRequestParams<TValue>>(filters: Nullable<FilterType<TKey>>, params?: Partial<T>,): void;
}

export interface IActionCreators<TValue, TKey extends keyof SelectList> extends ActionCreatorsMapObject {
	request: Request<TValue, TKey>;
	loadMoreItems: (
		count?: number
	) => void;
	update: (item: Partial<EntityType<TKey>>) => void;
	refresh: Request<TValue, TKey>;
	resetStore: () => void; // u need to pass the name of store that will not be reset,
													// example: getActionCreators('crematories', ...) - store of 'crematories' will not reset
													// or pass '' - and reset all stores
}

export interface SelectRequestParams<TValue> {
	count: number;
	preselect: TValue | Array<TValue>;
}

export interface ActionCreatorProps<TKey extends keyof SelectList> {
	endpoint: string,
	key?: string,
	equal?: FilterComparator<TKey>
}

function isParamsObject<TValue, T>(params: Partial<T> | TValue | Array<TValue> | undefined): params is Partial<T> {
	return typeof params === 'object' && !Array.isArray(params);
}

function getParams<
	TValue,
	T extends SelectRequestParams<TValue>
>(paramsOrPreselect?: TValue | Array<TValue> | Partial<T>, count?: number): Partial<T> {
	if (isParamsObject<TValue, T>(paramsOrPreselect)) {
		return {
			...paramsOrPreselect,
			count: paramsOrPreselect?.count ?? 10,
		};
	}

	return {
		preselect: paramsOrPreselect,
		count: count ?? 10,
	} as Partial<T>;
}

let series: number = 0;

export function getActionCreators<
	TValue,
	TKey extends keyof SelectList
>(
	store: TKey,
	{
		endpoint,
		key = GeneralKey,
		equal,
	}: ActionCreatorProps<TKey>,
): IActionCreators<TValue, TKey> {
	const fail = (dispatch: Dispatch<Action>, error: string) => (
		dispatch({
			type: TypeKeys.RECEIVEERROR,
			store,
			key,
			payload: { error },
		})
	);

	return {
		request: <T extends SelectRequestParams<TValue>>(
			filters: Nullable<FilterType<TKey>>,
			other?: TValue | Array<TValue> | Partial<T>,
			count?: number,
		) =>
			(dispatch: Dispatch<Action>, getStore: GetState) => {
				const state: RecordType<TKey> = getSelectItemState<TKey>(getStore().selects[store], key);

				if (state.filters === null && state.items.length) return;
				if (equal && equal(state.filters as Nullable<FilterType<TKey>>, filters)) return;

				const params: Partial<T> = getParams(other, count);
				const requestId = series++;
				const newFilters = { ...filters };
				if ((newFilters as WithName)?.name !== undefined) {
					delete (newFilters as Partial<WithName>)?.name;
				}

				const { preselect, ...otherParams } = params;

				dispatch({
					type: TypeKeys.REQUESTITEMS,
					store,
					key,
					payload: {
						filters: newFilters,
						preselect: params?.preselect,
						requestId,
						params: otherParams,
					},
				});

				request<List<EntityType<TKey>>>(endpoint, {
					...otherParams,
					preselect,
					filters: newFilters,

					count: params.count ?? 20,
					offset: 0,
				})
					.then((items: List<EntityType<TKey>>) => {
						dispatch({
							type: TypeKeys.RECEIVEITEMS,
							store,
							key,
							payload: { items: items.list, total: items.count, requestId },
						});
					})
					.catch((error: string) => fail(dispatch, error));
			},
		loadMoreItems: (count?: number) => {
			return (dispatch: Dispatch<Action>, getStore: GetState) => {
				const root: StoreType<TKey> = getStore().selects[store];
				const state = getSelectItemState(root, key);

				if (state.isLoading) return;
				if (state.pagination.total === state.items.length) return;

				dispatch({ type: TypeKeys.REQUESTMOREITEMS, store });

				request<List<EntityType<TKey>>>(endpoint, {
					...state.params,
					count,
					offset: state.items.length,
					filters: state.filters,
				})
					.then((items: List<EntityType<TKey>>) => {
						dispatch({
							type: TypeKeys.RECEIVEMOREITEMS,
							store,
							key,
							payload: { items: items.list, total: items.count },
						});
					})
					.catch((error: string) => fail(dispatch, error));
			};
		},
		update: (item: Partial<EntityType<TKey>>) => {
			return (dispatch: Dispatch<Action>) => {
				dispatch({
					type: TypeKeys.UPDATEITEM,
					store,
					key,
					payload: item,
				});
			};
		},
		refresh: <T extends SelectRequestParams<TValue>>(
			filters: Nullable<FilterType<TKey>>,
			other?: TValue | Array<TValue> | Partial<T>,
			count?: number,
		) => (dispatch: Dispatch<Action>) => {
			const params: Partial<T> = getParams(other, count);

			const newFilters = { ...filters };
			if ((newFilters as WithName)?.name) {
				delete (newFilters as Partial<WithName>)?.name;
			}

			dispatch({
				type: TypeKeys.REQUESTITEMS,
				store,
				key,
				payload: { filters: newFilters, preselect: params?.preselect },
			});
			request<List<EntityType<TKey>>>(endpoint, {
				...params,
				filters: newFilters,

				count: params?.count ?? 20,
				offset: 0,
			})
				.then((items: List<EntityType<TKey>>) => {
					dispatch({
						type: TypeKeys.RECEIVEITEMS,
						store,
						key,
						payload: {
							items: items.list,
							total: items.count,
						},
					});
				})
				.catch((error: string) => fail(dispatch, error));
		},
		resetStore: () => {
			return (dispatch: Dispatch<Action>) => {
				dispatch({ type: TypeKeys.RESETSTORE, store });
			};
		},
	};
}
