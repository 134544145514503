import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '@app/store';
import { CrematoryListInfo } from '@app/objects/Crematory';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { selectListRequest } from '@app/store/SelectList/SelectListRequests';
import { RoleManager } from '@app/services/RoleManager';

export function useCrematory(id?: number): CrematoryListInfo | undefined {
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const currentCrematoryId = user?.role && RoleManager.for(user?.role).isAdmin() ? id : activeCrematoryId;
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories.items);
	const factory = React.useMemo(() => bindActionCreators(getActionCreators('crematories', selectListRequest.crematories), dispatch), [dispatch]);
	React.useEffect(() => {
		if (!crematories) factory.request(selectListRequest.crematories.defaultFilters);
	}, [crematories]);

	return React.useMemo(() => crematories.find((item) => item.id === currentCrematoryId), [crematories, currentCrematoryId]);
}
