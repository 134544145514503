import { Nullable } from '@common/typescript/objects/Nullable';

import { Price } from '@app/objects/Price';
import {
	getSubTree,
	StoreEntry,
	StoreEntryNode,
} from '@app/objects/StoreEntry';

interface BriefPrice extends Pick<Price, 'value' | 'extra'> {
	price: Price;
}

interface PriceData {
	entry: Nullable<StoreEntry>;
	selection: Nullable<StoreEntryNode>;
	prices: Array<Price>;
	count: number;
}

/**
 * Calculate current price based on user's selection
 * @param data
 */
export function calculate(data: PriceData): Nullable<BriefPrice> {
	const tree = getSubTree(data.entry, data.selection);
	if (tree === null) return null;

	const reference = data.prices.some((price: Price) => price.clinicId !== null)
		? data.prices.filter((price: Price) => price.clinicId !== null)
		: data.prices;

	const list: Array<BriefPrice> = [];
	const stack: Array<StoreEntryNode> = [tree];
	while (stack.length) {
		const node = stack.pop()!;
		const price = reference.find((price: Price) => price.inventoryItemId === node.itemId);

		if (price) {
			const extra = Math.max(0, data.count - price.batchCount) * price.value;
			list.push({
				value: price.batchPrice + extra,
				extra,
				price,
			});
		}

		stack.push(...node.children);
	}

	if (!list.length) return null;

	return list.reduce((max: BriefPrice, cur: BriefPrice) => {
		if (max.value < cur.value) return cur;

		return max;
	});
}
