import * as React from 'react';

interface UploadFilePlaceholderProps {
	icon?: React.ReactNode;
	text: string;
	caption?: string;
}

export const UploadFilePlaceholder: React.FC<UploadFilePlaceholderProps> = (props: UploadFilePlaceholderProps) => (
	<div className="upload-file__placeholder">
		<div className="upload-file__placeholder__text">
			{props.icon} {props.text}
		</div>
		<div className="upload-file__placeholder__caption">
			{props.caption}
		</div>
	</div>
);
