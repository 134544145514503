import { Dispatch, Reducer } from 'redux';

import { Nullable } from '@common/typescript/objects/Nullable';

import { request } from '@app/components/Api';
import { AppThunkAction, ApplicationState } from '@app/store/index';
import { CrematoryFeatures } from '@app/objects/CrematoryFeatures';

export interface CrematoryFeaturesState<T = CrematoryFeatures> {
	isLoading: boolean;
	item: Nullable<T>;
	error: Nullable<string>;
}

const defaultCrematoryFeaturesState: CrematoryFeaturesState = {
	isLoading: false,
	item: null,
	error: null,
};

export enum ActionKeys {
	REQUESTCREMATORYFEATURES = 'REQUESTCREMATORYPREFERENCES',
	RECEIVECREMATORYFEATURES = 'RECEIVECREMATORYFEATURES',
	REQUESTCREMATORYFEATURESERROR = 'REQUESTCREMATORYFEATURESERROR',
	UPDATECREMATORYFEATURES = 'UPDATECREMATORYFEATURES',
}

interface RequestCrematoryFeaturesAction {
	type: ActionKeys.REQUESTCREMATORYFEATURES;
}

interface ReceiveCrematoryFeaturesAction {
	type: ActionKeys.RECEIVECREMATORYFEATURES;
	item: Nullable<CrematoryFeatures>;
}

interface RequestCrematoryFeaturesErrorAction {
	type: ActionKeys.REQUESTCREMATORYFEATURESERROR;
	error: Nullable<string>;
}

interface UpdateCrematoryFeaturesAction {
	type: ActionKeys.UPDATECREMATORYFEATURES;
	item: Nullable<CrematoryFeatures>;
}

type KnownCrematoryFeaturesActions = RequestCrematoryFeaturesAction
	| ReceiveCrematoryFeaturesAction
	| UpdateCrematoryFeaturesAction
	| RequestCrematoryFeaturesErrorAction;

export interface CrematoryFeaturesActionCreators {
	request: (id?: number) => AppThunkAction<KnownCrematoryFeaturesActions>;
	update: (item: Partial<CrematoryFeatures>) => (dispatch: Dispatch, getStore: GetState) => void;
}

type GetState = () => ApplicationState;
export function getCrematoryFeaturesActionCreators() {
	return {
		request: (id?: number): AppThunkAction<KnownCrematoryFeaturesActions> => (dispatch, getStore: GetState) => {
			const state = getStore().crematoryFeatures.item;
			if (id && state?.crematoryId === id) return;

			dispatch({
				type: ActionKeys.REQUESTCREMATORYFEATURES,
			});

			request<CrematoryFeatures>('getCrematoryFeatures', { crematoryId: id })
				.then((data: CrematoryFeatures) => {
					dispatch({
						type: ActionKeys.RECEIVECREMATORYFEATURES,
						item: data,
					});
				})
				.catch((error: string) => {
					dispatch({
						type: ActionKeys.REQUESTCREMATORYFEATURESERROR,
						error,
					});
				});
		},
		update: (item: Partial<CrematoryFeatures>) => (dispatch, getStore: GetState) => {
			const state = getStore().crematoryFeatures.item;

			if (!state) return;

			dispatch({
				type: ActionKeys.UPDATECREMATORYFEATURES,
				item: { ...state, ...item },
			});
		},
	};
}

function getState(state: CrematoryFeaturesState, item: Nullable<CrematoryFeatures>) {
	return {
		...state,
		isLoading: false,
		item,
	};
}
export function getCrematoryFeaturesReducer(): Reducer<CrematoryFeaturesState, KnownCrematoryFeaturesActions> {
	return (state: CrematoryFeaturesState = defaultCrematoryFeaturesState, action: KnownCrematoryFeaturesActions) => {
		switch (action.type) {
		case ActionKeys.REQUESTCREMATORYFEATURES:
			return {
				...state,
				isLoading: true,
				item: null,
				error: null,
			};

		case ActionKeys.RECEIVECREMATORYFEATURES:
			return {
				...state,
				isLoading: false,
				item: action.item,
			};

		case ActionKeys.UPDATECREMATORYFEATURES:
			return getState(state, action.item);

		case ActionKeys.REQUESTCREMATORYFEATURESERROR:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};

		default:
			return state;
		}
	};
}
