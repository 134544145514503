import * as Yup from 'yup';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';

export const phoneRegexp = /(\(([0-9]{3})\)\s([0-9]{3})[-]([0-9]{2,4})|\+([0-9]{11}))/;

export const formattedPhoneRegexp = /^\+[1-9]+ \([1-9]\d{2}\) \d\d\d-\d\d\d\d$/;

export const simpleStringValidator = Yup.string().required();
export const simpleNumberValidator = Yup.number().required();
export const positiveNumberValidator = Yup.number().positive('Required field');
export const notNullValidator = Yup.mixed().test('is-not-null', 'Required field!', value => value !== null);
export const emailValidator = Yup.string().email().required();
export const optionalEmailValidator = Yup.string().email().nullable().notRequired();
export const dateValidator = Yup.number().required().nullable();
export const phoneRequiredValidator = Yup.string().matches(phoneRegexp, 'Invalid phone number').required();
export const phoneValidator = Yup.string().test('is-valid', 'Invalid phone number', value =>
	!value || phoneRegexp.test(value));

export const formattedPhoneValidator = Yup.string().test('is-formatted-valid', 'Invalid phone number', value =>
	!value || formattedPhoneRegexp.test(value)
);
export const alphaDigitPasswordValidator = Yup.string().matches(/^([0-9a-zA-Z])+$/, 'Password should only contains digits and latin letters');

export const nonEmptyArray = (message: string) => Yup.array()
	.test(
		'Non-empty array',
		message,
		(value: null | undefined | Array<unknown>) => (value || []).some((value: unknown) => !(value as WithDeleted).deleted),
	);

/* Factories */
export function getSimpleStringValidator(field: string) {
	return Yup.string().required(`${field} is required`);
}

export function getSimpleNumberValidator(field: string) {
	return Yup.number().required(`${field} is required`);
}

export function getPositiveNumberValidator(field: string) {
	return Yup.number().positive(`${field} should be positive`);
}

export function getEmailValidator(field: string) {
	return Yup.string().email(`${field} has incorrect email format`).required(`${field} is required`);
}

export function getPhoneValidator(field: string) {
	return Yup.string().test('is-valid', `${field} has incorrect phone format`, value => !value || phoneRegexp.test(value));
}
