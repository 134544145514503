// eslint-disable-next-line max-classes-per-file
import * as React from 'react';

import { Action, Dispatch } from 'redux';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';

import {
	PageProps as BasePageProps,
	ExtendableItemEditor as BaseExtendableItemEditor,
	ItemEditorDefaultState,
	dispatchToProps as baseDispatchToProps,
	Actions as BaseActions,
} from '@common/react/components/Pages/ItemEditor';
import { WithId } from '@common/typescript/objects/WithId';
import { member } from '@common/react/utils/decorators';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store';

export type PageProps<T extends WithId> = BasePageProps<T, User, ApplicationState>;
export type ItemEditorActions<T extends WithId> = BaseActions<T, User, ApplicationState>;

export abstract class ExtendableItemEditor<
	T extends WithId,
	TPropsExtension = never,
	TEditorState extends ItemEditorDefaultState = ItemEditorDefaultState,
	TValues = Record<string, never>,
> extends BaseExtendableItemEditor<T, User, ApplicationState, TPropsExtension, TEditorState, TValues> {
	@member
	buttons():JSX.Element {
		return (
			<div className="text-center form-group">
				<Space>
					<Button
						onClick={this.handlerBack}
					>
						Cancel
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						loading={this.state.isLoading}
					>
						Save
					</Button>
				</Space>
			</div>
		);
	}
}

export abstract class ItemEditor<T extends WithId> extends ExtendableItemEditor<T> {}

export type Actions<T extends WithId> = BaseActions<T, User, ApplicationState>;

export function dispatchToProps<T extends WithId>(dispatch: Dispatch<Action>): Actions<T> {
	return baseDispatchToProps<T, User, ApplicationState>(dispatch);
}
