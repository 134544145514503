import * as React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import Menu from 'antd/lib/menu';

import { UserComponent } from '@app/components/UI/Header/UserComponent';
import { ApplicationState } from '@app/store';
import { PanelMenu } from '@app/components/UI/Menu';
import { menuList as menus } from '@app/components/UI/SidePanel/MenuItems';
import { useModal } from '@app/hooks/Editors/useModal';
import { BugReportModal } from '@app/components/UI/Header/BugReportModal';
import { getMenuItems } from '@app/components/UI/Header/MenuActionsDropdown';
import { UserRole } from '@app/objects/User';
import { ClinicHeaderContacts } from '@app/components/UI/Header/HeaderRoleDetails/ClinicInfoHeader';
import { CrematoryListInfo } from '@app/objects/Crematory';
import { VersionPanel } from '@app/components/UI/SidePanel/VersionPanel';

import '@app/scss/ui/side-panel.scss';

export enum PanelPosition {
	Left = 0,
	Right = 1,
}

interface SidePanelProps {
	position?: PanelPosition;

	panelOpened: boolean;
	togglePanel: (panelOpened?: boolean) => void;
}

const rootId = 'side-panel-overflow';
export const SidePanel: React.FC<SidePanelProps> = (props: SidePanelProps) => {
	const login = useSelector((state: ApplicationState) => state.login);
	const user = login.user;
	const history = useHistory();
	const modal = useModal(false);
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories);
	let activeCrematory = crematories.items.find((item: CrematoryListInfo) => item.id === user?.activeCrematoryId);

	if (crematories.items.length === 1 && user?.activeCrematoryId) activeCrematory = crematories.items[0];

	const onOverflowClicked = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
		const target = event.target as Element & HTMLElement;

		if (target.id === rootId) {
			props.togglePanel(false);
		}
	}, [props.togglePanel]);

	if (user === null) return null;

	return (
		<>
			<CSSTransition
				in={props.panelOpened}
				timeout={500}
				classNames="side-panel-overflow fade-inout fade-inout"
				unmountOnExit
				mountOnEnter
			>
				<div
					id={rootId}
					onClick={onOverflowClicked}
				>
					<div
						className={clsx(
							'side-panel',
							props.position === PanelPosition.Right
								? 'side-panel_right'
								: 'side-panel_left',
						)}
					>
						<Menu
							mode="inline"
							items={[
								{
									key: 'menu',
									label: <UserComponent />,
									children: getMenuItems(history, login.transmuted, modal.open, props.togglePanel),
								},
							]}
						/>
						<div className="side-panel__inner">
							<PanelMenu
								items={menus[user.role]}
								toggle={props.togglePanel}
							/>
						</div>

						{user.role === UserRole.ClinicUser && <ClinicHeaderContacts activeCrematory={activeCrematory} />}
						<VersionPanel display={user?.role === UserRole.Admin} />
					</div>
				</div>
			</CSSTransition>
			<BugReportModal modal={modal} />
		</>
	);
};
