import React from 'react';
import Space from 'antd/lib/space';
import * as Yup from 'yup';
import {
	Form,
	Formik,
} from 'formik';

import { Pet } from '@app/objects/Pet';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { useRequest } from '@app/hooks/useRequest';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { FormikField } from '@app/components/Forms/FormikField';

interface FormValues {
	id: number;
	internalIdNum?: string;
}

const validationSchema = Yup.object().shape({
	internalIdNum: Yup.string().required('The Internal ID is required'),
});

interface InternalIdFormProps {
	item: Pet;
	onChange: (item: Partial<Pet>, shouldClose?: boolean, params?: PromoteParams) => void;
	onClose: () => void;
}

export const InternalIdForm: React.FC<InternalIdFormProps> = (props: InternalIdFormProps) => {
	const item = props.item;
	const request = useRequest<Pet, Partial<FormValues>>('updatePet', undefined, { requestOnMount: false });

	return (
		<Formik
			initialValues={{
				id: item.id,
				internalId: item.internalIdNum,
			}}
			onSubmit={(values: FormValues, { setSubmitting }) => {
				request.reload({
					id: item.id,
					internalIdNum: values.internalIdNum,
				})
					.then((item: Pet | void) => {
						if (!item) return;

						props.onChange({
							id: item.id,
							internalIdNum: values.internalIdNum,
						});
					})
					.finally(() => setSubmitting(false));
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			{() => (
				<Form>
					<StatusModalController width={400} />

					<Space direction="vertical" size="middle">
						<FormikField fieldName="internalIdNum" title="Internal ID" />

						<ModalButtons onClose={props.onClose} />
					</Space>
				</Form>
			)}
		</Formik>
	);
};
