import React from 'react';
import { useDispatch } from 'react-redux';

import Modal, { ModalProps } from 'antd/lib/modal';

import { Pet, getDefaultPet } from '@app/objects/Pet';

import { bindItemActionCreators } from '@app/store/Item';
import { PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';
import { ModalContent } from '@app/components/UI/Status/StatusModal/ModalContent';
import { PromoteParams } from '@app/components/UI/Status/StatusChangeComponent';
import { StackProps } from '@app/components/Layout/Stack';

interface StatusModalProps {
	petId: number;

	visible: boolean;
	onChange?: (item: Partial<Pet>, params?: PromoteParams) => void;
	onClose: () => void;

	title?: string;
	code: PromoteResponseCode;
}

const reqParams = {
	withCurrentStatus: true,
	withStatus: true,
	withServices: true,
	withPetBreed: true,
	withPetSpecie: true,
	withPetGender: true,
	withCrematory: true,
	withUrns: true,
};

export interface IStatusModalContext {
	modal: {
		width: ModalProps['width'],
	};
	layout?: Omit<StackProps, 'children'>;

	update: (ctx: Omit<IStatusModalContext, 'update'>) => void;
}

const defaultContext: IStatusModalContext = {
	modal: {
		width: 400,
	},
	layout: undefined,
	update: () => { throw new Error('Context setter is not defined'); },
};

export const StatusModalContext = React.createContext<IStatusModalContext>(defaultContext);

export const StatusModal: React.FC<StatusModalProps> = (props: StatusModalProps): React.ReactElement => {
	const dispatch = useDispatch();
	const factory = React.useMemo(() => bindItemActionCreators<Pet>(dispatch), [dispatch]);

	const [content, setContent] = React.useState<Omit<IStatusModalContext, 'update'>>(defaultContext);
	const context: IStatusModalContext = React.useMemo<IStatusModalContext>(() => ({
		...content,
		update: setContent,
	}), [content]);

	React.useEffect(() => {
		if (props.visible) {
			factory.loadItem('petModal', 'getPet', props.petId, getDefaultPet(), reqParams);
		}
	}, [props.petId, props.visible, factory]);

	return (
		<StatusModalContext.Provider value={context}>
			<Modal
				title={props.title ?? 'Pet Info'}
				open={props.visible}
				onCancel={props.onClose}
				width={content.modal.width}
				footer={null}
				destroyOnClose
				centered
			>
				<ModalContent
					code={props.code}
					onChange={props.onChange}
					onClose={props.onClose}
				/>
			</Modal>
		</StatusModalContext.Provider>
	);
};
