import React from 'react';

import { Field, FieldProps } from 'formik';

import { Nullable, Optional } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { ModalFormValues } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import { NumberField } from '@app/components/UI/NumberField/NumberField';

interface ItemFieldProps {
	storeEntry?: Nullable<ModalFormValues>;
	fieldName: string;
	title: string;

	value: Optional<number>;
	onChange: (value: number) => void;

	disabled?: boolean;
}

export const ItemField: React.FC<ItemFieldProps> = (props: ItemFieldProps) => (
	<Field name={props.fieldName}>
		{(fieldProps: FieldProps<PetFormValues>) => (
			<FormikInput
				title={props.title}
				fieldProps={fieldProps}
				containerClassName="col-sm-6"
				render={({ field }: FieldProps<number, PetFormValues>) => (
					<NumberField
						{...field}
						value={props.value}
						onChange={(value: number) => props.onChange(value)}
						min={1}
						disabled={props.disabled}
					/>
				)}
			/>
		)}
	</Field>
);
