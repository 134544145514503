import React from 'react';

import { useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import { PetPrice } from '@app/objects/Pet';

interface ServiceIndexProviderProps {
	clientId: string;
	children: (index: Nullable<number>) => React.ReactElement;
}

export const ServiceIndexProvider: React.FC<ServiceIndexProviderProps> = (props: ServiceIndexProviderProps) => {
	const { values } = useFormikContext<{ services: Array<PetPrice> }>();
	let index: Nullable<number> = values.services.findIndex((item: PetPrice) => item.clientId === props.clientId);
	if (index < 0 || index >= values.services.length) {
		index = null;
	}

	return props.children(index);
};
