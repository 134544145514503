import * as React from 'react';

import Checkbox from 'antd/lib/checkbox';

import { Pet } from '@app/objects/Pet';
import { OldPetHistoryChange } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange';

interface OwnProps {
	item: Pet;
	onChange: (isCheck: boolean, isItems: boolean) => void;
}

export const HistoryChangeList: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		item,
		onChange,
	} = props;
	const [isChecked, setIsChecked] = React.useState<boolean>(false);
	const [isItems, setIsItems] = React.useState<boolean>(false);

	React.useEffect(() => {
		onChange(isChecked, isItems);
	}, [isChecked, isItems]);

	return (
		<>
			<OldPetHistoryChange
				pet={item}
				pending
				onChange={(isItems: boolean) => setIsItems(isItems)}

				fallback={null}
			/>
			{isItems
				? (
					<Checkbox
						checked={isChecked}
						onClick={() => setIsChecked(!isChecked)}
						style={{ fontWeight: 'normal', marginTop: 10 }}
					>
						Confirm changes reviewed
					</Checkbox>
				) : null }
		</>
	);
};
