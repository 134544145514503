import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { StatusOrder } from '@app/objects/StatusOrder';
import { User } from '@app/objects/User';

export enum FunctionsGroup {
	Reports = 'Reports',
	Clinic = 'Clinic Dashboard',
	SystemActions = 'System Actions',
}

export enum StatusFunction {
	PickupReport = 0,
	BatchAction = 1,
	DropOffReport = 2,
	MarkAtClinic = 3,
	MarkAtCrematory = 4,
	MarkDelivered = 5,
	RequireManualChange = 6,
	InvoiceDate = 7,
	CoordinateBatch = 9,
	TurnAroundDelivered = 10,
	TurnAroundPickup = 11,
}

export interface StatusOrderFunction extends WithId {
	statusOrder: Nullable<StatusOrder>;
	statusOrderId: number;
	creator: Nullable<User>;
	creatorId: number;
	function: StatusFunction;
	createdDate: number;
}

/* eslint max-len: ["error", { "ignoreStrings": true }] */
export const statusOrderFunctionDescriptions: Record<StatusFunction, string> = {
	[StatusFunction.PickupReport]: 'Mark pets in this status to be used for pickup report as pet to be picked up.',
	[StatusFunction.BatchAction]: 'Mark pets in this status to be used by Batch Action.',
	[StatusFunction.DropOffReport]: 'Mark pets in this status to be used for pickup report as pet to be dropped off.',
	[StatusFunction.MarkAtClinic]: 'Pets in this status and all subsequent statuses (until Mark At Crematory / Mark Delivered) will be counted as \'At Clinic\' on clinic\'s dashboard.',
	[StatusFunction.MarkAtCrematory]: 'Pets in this status and all subsequent statuses (until Mark At Clinic / Mark Delivered) will be counted as \'At Crematory\' on clinic\'s dashboard.',
	[StatusFunction.MarkDelivered]: 'Pets in this status and all subsequent statuses (until Mark At Clinic / Mark At Crematory) will be counted as \'Delivered\' on clinic\'s dashboard.',
	[StatusFunction.RequireManualChange]: 'Force stop at this status during status change even if no user action is required. This makes difference only when using Status Flow Policy - Allow Skipping.',
	[StatusFunction.InvoiceDate]: 'Use date and time when pet entered current status for invoice calculation. By default invoices use date and time when pet was added to the system.',
	[StatusFunction.CoordinateBatch]: 'Pets in this or any subsequent status can be used for \'Coordinate Batch\'',
	[StatusFunction.TurnAroundDelivered]: 'Mark status to be used as final status for turnaround report (except Pickup delivery type)',
	[StatusFunction.TurnAroundPickup]: 'Mark status to be used as final status for turnaround report (Pickup delivery type)',
};
