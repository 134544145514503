import * as React from 'react';

import Table from 'antd/lib/table';

import { BaseApplicationState } from '@common/react/store';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { ExtendableItemsPage } from '@common/react/components/Pages/ItemsPage';
import { WithId } from '@common/typescript/objects/WithId';
import { request } from '@common/react/components/Api';

interface ModifiedItems<T extends WithId> {
	[key: number]: T | undefined;
}

interface FieldError {
	[key: string]: string;
}

interface Errors {
	[key: number]: FieldError | undefined;
}

export interface EditableItemsPageState<T extends WithId> {
	modifiedItems: ModifiedItems<T>;
	errors: Errors;
}

export abstract class ExtendableEditableItemsPage<
		TEntity extends WithId,
		TPropsExtension,
		TState extends EditableItemsPageState<TEntity> = EditableItemsPageState<TEntity>,
		TUser extends BaseUser = BaseUser,
		TApplicationState extends BaseApplicationState<TUser> = BaseApplicationState<TUser>,
	> extends ExtendableItemsPage<
		TEntity,
		TPropsExtension,
		TState,
		TUser,
		TApplicationState
	> {
	validationSchema: any;

	baseAction = {
		title: '',
		render: (_, record: TEntity) => (
			<div className="text-right table-actions">
				{this.isEdit(record.id)
					? (
						<div>
							<button
								className="btn btn-sm btn-primary"
								type="button"
								title="Save"
								onClick={() => this.handleInputSubmit(record)}
							>
								<i className="fa fa-save" />
							</button>
							<button
								className="btn btn-sm btn-default"
								type="button"
								title="Cancel"
								onClick={() => this.handleInputCancel(record)}
							>
								<i className="fa fa-times" />
							</button>
							{record.id !== -1 && (
								<button
									className="btn btn-sm btn-danger"
									type="button"
									title="Delete"
									onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleDelete(e, record)}
								>
									<i className="fa fa-trash" />
								</button>
							)}
						</div>
					)
					: (
						<div>
							<button
								className="btn btn-sm btn-default"
								type="button"
								title="Edit"
								onClick={() => this.handleInput(record)}
							>
								<i className="fa fa-pencil" />
							</button>
							<button
								className="btn btn-sm btn-danger"
								type="button"
								title="Delete"
								onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleDelete(e, record)}
							>
								<i className="fa fa-trash" />
							</button>
						</div>
					)}
			</div>
		),
	};

	nameColumn = {
		title: 'Name',
		dataIndex: 'name',
		render: (text: string, record: TEntity) => this.getSimpleEditField('name', text, record.id, 'Name'),
	};

	constructor(props: any) {
		super(props);

		this.textInput = React.createRef();

		this.columns = [
			this.idColumn,
			this.nameColumn,
			this.baseAction,
		];

		this.state = {
			modifiedItems: {},
			errors: {},
		} as TState;

		this.handleInput = this.handleInput.bind(this);
		this.handleInputSubmit = this.handleInputSubmit.bind(this);
		this.handleInputCancel = this.handleInputCancel.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.isEdit = this.isEdit.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.getSimpleEditField = this.getSimpleEditField.bind(this);
	}

	componentWillUnmount(): void {
		if (this.state.modifiedItems[-1]) {
			this.props.actions.deleteItem(this.store, -1);
		}
	}

	getSimpleEditField(fieldName: string, value: string, recordId: number, mobileCaption: string = '', controlMarkup?: React.ReactNode): JSX.Element {
		const error = this.getError(recordId, fieldName);

		return this.renderMobileCell(mobileCaption, this.isEdit(recordId)
			? (
				<div className={error ? 'has-error' : ''}>
					<div className="is-relative">
						{controlMarkup || (
							<input
								className="form-control"
								id={`${fieldName}-${recordId}`}
								type="text"
								defaultValue={value}
								onChange={(event) => this.setItemPropValue(recordId, fieldName, event.target.value)}
							/>
						)}
						{error ? <div className="validation-message">{error}</div> : ''}
					</div>
				</div>
			)
			: value,
		true);
	}

	abstract getNewItem(): TEntity;

	handleInput(record) {
		this.setState((prevState) => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[record.id]: {
					...record,
				},
			},
		}));
	}

	transformData(record: TEntity): TEntity {
		return record;
	}

	handleInputSubmit(record) {
		this.setState((prevState) => ({
			errors: {
				...prevState.errors,
				[record.id]: undefined,
			},
		}));

		const modifiedRecord = {
			...record,
			...this.state.modifiedItems[record.id],
		};

		if (this.validationSchema) {
			this.validationSchema.validate(modifiedRecord, { abortEarly: false }).then(() => {
				this.saveItem(this.transformData(modifiedRecord));
			}).catch((err) => {
				const errors = {};
				for (let i = 0; i < err.inner.length; i++) {
					errors[err.inner[i].path] = err.inner[i].errors[0];
				}

				this.setState((prevState) => ({
					errors: {
						...prevState.errors,
						[record.id]: errors,
					},
				}));
			});
		} else {
			this.saveItem(modifiedRecord);
		}
	}

	saveItem(item: TEntity) {
		request(this.type, item).then((response: any) => {
			this.setState((prevState) => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[item.id]: undefined,
				},
			}));

			if (item.id === -1) {
				this.props.actions.refreshPages(this.store, this.path, { page: this.currentPage, count: this.count, ...this.additionalParams });
			} else {
				this.props.actions.updateItem(this.store, response);
			}
		});
	}

	handleInputCancel(record) {
		this.setState((prevState) => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[record.id]: undefined,
			},
			errors: {
				...prevState.errors,
				[record.id]: undefined,
			},
		}));

		if (record.id === -1) {
			this.props.actions.deleteItem(this.store, -1);
		}
	}

	getError(id: number, propName: string): string | undefined {
		const itemErrors = this.state.errors[id];

		return itemErrors && itemErrors[propName];
	}

	handleAdd() {
		const newId = -1;
		if (!this.isEdit(newId)) {
			const newItem = this.getNewItem();

			this.setState((prevState) => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[newId]: newItem as TEntity,
				},
			}));

			this.props.actions.addItem(this.store, newItem);
		}
	}

	isEdit(id: number): boolean {
		return typeof this.state.modifiedItems[id] !== 'undefined';
	}

	setItemPropValue(id: number, propName: string, value: any) {
		this.setState((prevState) => ({
			modifiedItems: {
				...prevState.modifiedItems,
				[id]: {
					...prevState.modifiedItems[id],
					[propName]: value,
				} as TEntity,
			},
		}));

		if (this.state.errors[id]) {
			this.setState((prevState) => ({
				errors: {
					...prevState.errors,
					[id]: {
						...prevState.errors[id],
						[propName]: undefined,
					},
				},
			}));
		}
	}

	setItemPropValuePromisified(id: number, propName: string, value: any) {
		const promise = new Promise<void>((resolve) => {
			this.setState((prevState) => ({
				modifiedItems: {
					...prevState.modifiedItems,
					[id]: {
						...prevState.modifiedItems[id],
						[propName]: value,
					} as TEntity,
				},
			}), () => {
				resolve();
			});
		});
		promise.then(() => {
			if (this.state.errors[id]) {
				this.setState((prevState) => ({
					errors: {
						...prevState.errors,
						[id]: {
							...prevState.errors[id],
							[propName]: undefined,
						},
					},
				}));
			}
		});

		return promise;
	}

	handleTableChange(pagination, filters, sorter) {
		this.setState({
			modifiedItems: [],
		});

		super.handleTableChange(pagination, filters, sorter);
	}

	renderTable(): JSX.Element {
		const { items, pagination, isLoading } = this.props.items;

		return (
			<Table
				columns={this.columns}
				dataSource={items.length > this.count ? items.slice(0, this.count) : items}
				pagination={pagination}
				loading={isLoading}
				onChange={this.handleTableChange}
				childrenColumnName="child"
				rowKey={this.rowKey}
				className={this.tableClassName}
			/>
		);
	}
}

export abstract class EditableItemsPage<
	T extends WithId,
	TState extends EditableItemsPageState<T> = EditableItemsPageState<T>,
	TUser extends BaseUser = BaseUser,
	TApplicationState extends BaseApplicationState<TUser> = BaseApplicationState<TUser>,
> extends ExtendableEditableItemsPage<T, Record<string, never>, TState, TUser, TApplicationState> {

}
