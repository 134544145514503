import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { ApplicationState } from '@app/store';
import { useCrematory } from '@app/hooks/useCrematory';
import { RoleManager } from '@app/services/RoleManager';

interface TimeViewProps {
	time: number;
	format?: string;
}

export const TimeView: React.FC<TimeViewProps> = ({ time, format }: TimeViewProps) => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory();
	const currentFormat = user && RoleManager.for(user.role).isAdmin() ? user.localeUserPreferences?.timeFormat : crematory?.timeFormat;

	return <span>{dayjs(time).format(format ?? currentFormat)}</span>;
};
