import * as React from 'react';
import { useSelector } from 'react-redux';

import { Nullable } from '@common/typescript/objects/Nullable';
import TabsWithUrl, { TabMode } from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';
import { getParams, loadable, loadableDelay } from '@common/react/loadable/loadableSettings';

import '@app/scss/ui/blocks/tabs.scss';
import { ApplicationState } from '@app/store';
import { Permission, activePermission } from '@app/objects/Permission';
import { User } from '@app/objects/User';

const ClinicsSearchTab = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicsPage" */ '@app/components/Pages/Clinics/ClinicList')), getParams('Clinics'));
export const CrematoryInvoicesTab = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CrematoryInvoicesTab" */
			'@app/components/Pages/Clinics/ClinicTabs/Invoices/Invoices'
		)),
	getParams('CrematoryInvoices'),
);
export const RoutesTab = loadable(() =>
	loadableDelay(import('@app/components/Pages/Clinics/ClinicTabs/Routes/Routes')), getParams('CrematoryRoutes'));

const getItems = (user: Nullable<User>, activeCrematoryId?: number) => {
	const items = [
		{
			key: 'clinic-list',
			label: 'Clinics',
			children: <ClinicsSearchTab />,
		},
	];

	if (activePermission(user, Permission.ManageInvoices)) {
		items.push({
			key: 'invoice-list',
			label: 'Invoices',
			children: <CrematoryInvoicesTab crematoryId={activeCrematoryId} />,
		});
	}

	items.push({
		key: 'routes',
		label: 'Routes',
		children: <RoutesTab />,
	});

	return items;
};

export const CrematoryClinics = (): JSX.Element => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const activeCrematoryId = user?.activeCrematoryId;

	return (
		<TabsWithUrl
			defaultKey="clinic-list"
			tabsUrlKey="tab"
			className="subheader"
			mode={TabMode.Location}
			items={getItems(user, activeCrematoryId)}
		/>
	);
};
