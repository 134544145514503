import React from 'react';

import * as Yup from 'yup';
import {
	Form,
	Formik,
} from 'formik';

import Alert from 'antd/lib/alert';
import Space from 'antd/lib/space';
import { Col, Row } from 'antd/lib/grid';

import { Pet } from '@app/objects/Pet';
import { ValueDisplay } from '@app/components/UI/ValueDisplay/ValueDisplay';
import { useRequest } from '@app/hooks/useRequest';
import { ModalButtons } from '@app/components/UI/Status/StatusModal/ModalButtons';
import { StatusModalController } from '@app/components/UI/Status/StatusModal/StatusModalController';
import { PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';
import { toUpperCase, toWords } from '@app/components/Utils/Utils';
import { FormikField } from '@app/components/Forms/FormikField';

const weightValidation = Yup.number().positive('Should be positive').required('Required');
const getValidationSchema = (code: PromoteResponseCode) => Yup.object().shape({
	actualWeight: Yup.number().when('id', {
		is: () => code === PromoteResponseCode.MissingActualWeight,
		then: () => weightValidation,
	}),
	ashesWeight: Yup.number().when('id', {
		is: () => code === PromoteResponseCode.MissingAshesWeight,
		then: () => weightValidation,
	}),
});

interface FormValues {
	id: number;
	actualWeight?: number;
	ashesWeight?: number;
}

interface WeightFormProps {
	code: PromoteResponseCode;
	item: Pet;
	onChange: (item: Partial<Pet>) => void;
	onClose: () => void;
}

export const WeightForm: React.FC<WeightFormProps> = (props: WeightFormProps) => {
	const { item, code } = props;
	const request = useRequest<Pet, FormValues>('updatePet', undefined, { requestOnMount: false });
	const fieldName: keyof FormValues = code === PromoteResponseCode.MissingActualWeight ? 'actualWeight' : 'ashesWeight';
	const title = toWords(toUpperCase(fieldName));

	return (
		<Formik
			initialValues={{ id: item.id, [fieldName]: item[fieldName] }}
			onSubmit={(values, { setSubmitting }) => {
				if (values[fieldName] !== item[fieldName]) {
					request.reload(values)
						.then(() => {
							props.onChange(values);
						})
						.finally(() => setSubmitting(false));
				} else {
					setSubmitting(false);
				}
			}}
			validationSchema={getValidationSchema(code)}
			enableReinitialize
		>
			{() => (
				<Form>
					<StatusModalController width={400} />

					<Space direction="vertical">
						<Row gutter={[30, 16]}>
							<Col xs={24} sm={24} md={12}>
								<ValueDisplay
									title="Reported Weight"
									value={item.reportedWeight.toFixed(1)}
								/>
							</Col>
							<Col xs={24} sm={24} md={12}>
								<FormikField
									fieldName={fieldName}
									title={`${title}*`}
									inputProps={{
										type: 'number',
										className: 'form-control-sm',
										step: 0.1,
										min: 0,
									}}
								/>
							</Col>
						</Row>

						<Alert
							type="warning"
							message={`${title} is missing`}
							closable
							onClose={(event: React.MouseEvent) => event.stopPropagation()}
						/>
						{
							request.error ? (
								<Alert
									type="error"
									message={request.error}
									closable
									onClose={(event: React.MouseEvent) => event.stopPropagation()}
								/>
							) : null
						}
					</Space>

					<ModalButtons onClose={props.onClose} />
				</Form>
			)}
		</Formik>
	);
};
