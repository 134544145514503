import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'antd/lib/select';

import { DeliveryType, DeliveryTypeName } from '@app/objects/Pet';
import { Value } from '@app/components/UI/Filters/FilterHook';
import { Price } from '@app/objects/Price';

const deliveryTypeKey = 'delivery-type';

interface DeliveryTypeSelectProps {
	value: Value | undefined;

	prices?: Array<Price>;

	onChange: (value: Value) => void;
	onDeselect?: (fieldName: string, type: string, id?: number) => void;

	disabled?: boolean;
	withFieldName?: boolean;
	multiple?: boolean;

	className?: string;
	selectClassName?: (value: string | number | undefined, length: number) => string;
}

export const DeliveryTypeSelect: React.FC<DeliveryTypeSelectProps> = (props: DeliveryTypeSelectProps) => {
	const { t } = useTranslation();
	const [query, setQuery] = React.useState<string>('');

	const translator = React.useCallback((key: string) => t(`filters.${key}`), []);

	const deliveryTypeValues = Object.keys(DeliveryType)
		.sort((a, b) => (a >= b ? 1 : -1))
		.map((key) => {
			const name = t(`enums.${deliveryTypeKey}.${DeliveryTypeName[key]}`);
			const price = props.prices?.find((i) => i.deliveryType === DeliveryType[key])?.value;
			const priceLabel = price ? `$${price.toFixed(2)}` : 'free';

			return {
				key: DeliveryType[key],
				value: props.prices ? `${name}  (${priceLabel})` : name,
			};
		});

	return (
		<div className={props.className}>
			{props.withFieldName && <span className="field-name">{translator(deliveryTypeKey)}</span>}
			<span className="ant-select-selection__placeholder">{translator(deliveryTypeKey)}</span>
			<Select
				className={props.selectClassName ? props.selectClassName(props.value, query.length) : ''}
				showSearch
				showArrow
				showAction={['focus', 'click']}
				optionFilterProp="children"
				placeholder={translator(deliveryTypeKey)}
				mode={props.multiple ? 'multiple' : undefined}
				value={props.value === -1 ? undefined : props.value}
				onChange={props.onChange}
				onSearch={(query: string) => setQuery(query)}
				onDeselect={(value: number) => props.onDeselect && props.onDeselect('deliveryType', 'id', value)}
				disabled={props.disabled}
				onSelect={() => setQuery('')}
				onBlur={() => setQuery('')}
			>
				<Select.Option value={-1} key={0} disabled>
					{translator(deliveryTypeKey)}
				</Select.Option>
				{
					deliveryTypeValues.map(({ key, value }) => (
						<Select.Option value={key} key={key}>
							{value}
						</Select.Option>
					))
				}
			</Select>
		</div>
	);
};
