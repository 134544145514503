import { useSelector } from 'react-redux';

import { ApplicationState } from '@app/store';

export const useUserLocalPreferences = () => {
	const preferences = useSelector((state: ApplicationState) => state.login.user?.localeUserPreferences);
	const dateFormat = preferences?.dateFormat;
	const timeFormat = preferences?.timeFormat;
	const format = `${dateFormat} ${timeFormat}`;

	return { dateFormat, timeFormat, format };
};
