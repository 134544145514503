import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import clsx from 'clsx';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import CommonContext from '@common/react/components/ServiceContainer';
import SignalR from '@common/react/components/SignalR/SignalR';
import { member } from '@common/react/utils/decorators';
import '@common/react/yupLocaleSettings';

import { services } from '@app/components/ServiceContainer';
import { Header } from '@app/components/UI/Header/Header';

import { Title } from '@app/components/UI/Header/Title';
import { PanelPosition, SidePanel } from '@app/components/UI/SidePanel/SidePanel';
import { BodyLayout } from '@app/components/UI/BodyLayout/BodyLayout';
import { ApplicationState } from '@app/store';
import { LoginState } from '@app/store/Login';

import { UserRole } from '@app/objects/User';
import { ClinicHeader } from '@app/components/UI/Header/ClinicHeader';
import { Footer } from '@app/components/UI/Footer/Footer';

import 'bootstrap/dist/css/bootstrap.css';
import '@app/scss/style.scss';

type PageProps = RouteComponentProps;

interface PageState {
	panelOpened: boolean;
}

interface ReduxProps {
	login: LoginState;
}

const outerPages = ['/', '/login', '/recover', '/confirm', '/registration', '/clinics', '/crematories'];

class LayoutInner extends React.Component<PageProps & ReduxProps & WithTranslation, PageState> {
	public state: PageState = {
		panelOpened: false,
	};

	componentDidMount() {
		this.setState({
			panelOpened: false,
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			if (this.isInnerPage()) {
				const container = document.querySelector<HTMLElement>('.inner-page-wrapper__inner');

				if (container) {
					container.scrollTo(0, 0);
				}
			} else {
				window.scrollTo(0, 0);
			}
		}
	}

	@member
	togglePanel(state?: boolean) {
		this.setState((s) => ({ panelOpened: state === undefined ? !s.panelOpened : state }));
	}

	isInnerPage(): boolean {
		return outerPages.indexOf(this.props.location.pathname) === -1;
	}

	@member
	public innerContent(): React.ReactNode {
		const userRole = this.props.login.user?.role;
		const isClinic = userRole === UserRole.ClinicUser || userRole === UserRole.ClinicManager;

		if (!this.props.tReady) return false;

		return (
			<div className={clsx('app-container', isClinic && 'app-container--clinic')}>
				<Title title="Wings" />
				{
					!isClinic
						? (
							<Header
								togglePanel={this.togglePanel}
							/>
						)
						: (
							<ClinicHeader
								togglePanel={this.togglePanel}
							/>
						)
				}

				<SidePanel
					position={PanelPosition.Right}
					panelOpened={this.state.panelOpened}
					togglePanel={this.togglePanel}
				/>

				<BodyLayout key={this.props.login.user?.activeCrematoryId}>
					{this.props.children}
				</BodyLayout>

				{isClinic && <Footer />}
			</div>
		);
	}

	@member
	public outerContent(): React.ReactNode {
		const userRole = this.props.login.user?.role;
		const isClinic = userRole === UserRole.ClinicUser || userRole === UserRole.ClinicManager;

		return (
			<div className={clsx('app-container', isClinic && 'app-container--clinic')}>
				<div className="outer-page-container">
					{this.props.tReady ? this.props.children : null}
				</div>
			</div>
		);
	}

	public render() {
		const isInnerPage = this.isInnerPage();

		return (
			<CommonContext.Provider value={services}>
				<DndProvider backend={HTML5Backend}>
					<SignalR />
					{ isInnerPage ? this.innerContent() : this.outerContent() }
				</DndProvider>
			</CommonContext.Provider>
		);
	}
}

export const Layout = withRouter(connect<ReduxProps, RouteComponentProps, PageProps, ApplicationState>((state: ApplicationState) => ({
	login: state.login,
}))(withTranslation()(LayoutInner)));
