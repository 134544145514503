// eslint-disable-next-line max-classes-per-file
import { Nullable } from '@common/typescript/objects/Nullable';

import {
	PriceKind,
	DiscountType,
} from '@app/objects/Price';
import { PetPrice } from '@app/objects/Pet';

export interface IDiscountService {
	applySingle: (value: number, kind: PriceKind) => number;
	applyTotal: (services: number, urns: number, products: number) => [number, number, number];
	get: () => Nullable<PetPrice>;
}

function isMissing<T>(value: T): boolean {
	return value === undefined || value === null;
}

class DummyDiscount implements IDiscountService {
	public applySingle(value: number): number {
		return value;
	}

	public applyTotal(services: number, urns: number, products: number): [number, number, number] {
		return [services, urns, products];
	}

	public get(): Nullable<PetPrice> {
		return null;
	}
}

class AbsoluteDiscount implements IDiscountService {
	private readonly _source: PetPrice;

	private get discount(): number {
		return this._source.price?.value ?? 0;
	}

	public constructor(from: PetPrice) {
		this._source = from;
	}

	public applySingle(value: number, kind: PriceKind): number {
		if (!this._source.price) return value;
		if (isMissing(this._source.price.applyTo)) return value;
		if (this._source.price.applyTo !== kind) return value;

		const discount = Math.min(value, this.discount);
		this._source.value += discount;

		return value - discount;
	}

	public applyTotal(services: number, urns: number, products: number): [number, number, number] {
		if (!this._source.price) return [services, urns, products];
		if (!isMissing(this._source.price.applyTo)) return [services, urns, products];

		const discount = Math.min(services, this.discount);
		this._source.value += discount;

		return [services - discount, urns, products];
	}

	public get(): Nullable<PetPrice> {
		return this._source;
	}
}

class RelativeDiscount implements IDiscountService {
	private readonly _source: PetPrice;

	private get discount(): number {
		if (typeof this._source.price?.value !== 'number') return 0;

		return this._source.price.value / 100.0;
	}

	public constructor(from: PetPrice) {
		this._source = from;
	}

	public applySingle(value: number, kind: PriceKind): number {
		if (!this._source.price) return value;
		if (isMissing(this._source.price.applyTo)) return value;
		if (this._source.price?.applyTo !== kind) return value;

		const discount = this.discount * value;
		this._source.value += discount;

		return value - discount;
	}

	public applyTotal(services: number, urns: number, products: number): [number, number, number] {
		if (!this._source.price) return [services, urns, products];
		if (!isMissing(this._source.price.applyTo)) return [services, urns, products];

		const fraction = this.discount;
		const servicesDiscount = services * fraction;
		const urnsDiscount = urns * fraction;
		const productsDiscount = products * fraction;
		this._source.value += servicesDiscount + productsDiscount;

		return [services - servicesDiscount, urns - urnsDiscount, products - productsDiscount];
	}

	public get(): Nullable<PetPrice> {
		return this._source;
	}
}

export class DiscountService {
	public static from(discount: Nullable<PetPrice>): IDiscountService {
		if (!discount?.price) return new DummyDiscount();
		if (discount.price.unit === DiscountType.Value) return new AbsoluteDiscount(discount);
		if (discount.price.unit === DiscountType.Percentage) return new RelativeDiscount(discount);

		throw new Error('Unknown price unit type');
	}
}
