/**
 * Get order suffix based on number
 * (st, nd, rd, th)
 * @param order
 */
export function getOrderSuffix(order: number): string {
	const digit = order % 10;

	if (digit === 1) return 'st';
	if (digit === 2) return 'nd';
	if (digit === 3) return 'rd';

	return 'th';
}
