import * as React from 'react';

import List from 'antd/lib/list';

import { PetPrice } from '@app/objects/Pet';

interface OwnProps {
	urns: Array<PetPrice>
}

function getUrnDescription(item: PetPrice): string {
	return `Requested ${item.count} urn${item.count > 1 ? 's' : ''} at the total of $${item.value.toFixed(2)}`;
}

export const UrnSelectionPreview: React.FC<OwnProps> = (props: OwnProps) => (
	<List
		itemLayout="horizontal"
		dataSource={props.urns}
		header={<b>Selected Urns: </b>}
		renderItem={(item) => (
			<List.Item>
				<List.Item.Meta
					title={item.node?.fullName ?? 'Unknown Urn'}
					description={getUrnDescription(item)}
				/>
			</List.Item>
		)}
	/>
);
