import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { User } from '@app/objects/User';

export enum TypeOfCrematory {
	Both = 0,
	Aquamation = 1,
	Fire = 2,
}

export enum DemoRequestStatus {
	Requested = 0,
	Scheduled = 1,
	Cancelled = 2,
	Completed = 3,
}

export interface DemoRequestNote extends WithId {
	text: string;
	time: number;
	user: Nullable<User>;
	userId: number;
}

export interface IDemoRequest extends WithId {
	name: string;
	email: string;
	phone: string;
	crematory: string;
	petsCount: number;
	clinicsCount: number;
	contactTime: string;
	notes: string;
	time: number;
	typeOfCrematory: number;
	status: DemoRequestStatus;
	demoRequestNotes: Array<DemoRequestNote>;
	country: string;
	state: string;
}
