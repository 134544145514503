import { Services } from '@common/react/components/ServiceContainer';

import { request } from '@app/components/Api';
import { alertMessage, MessageType } from '@app/utilities/alert';

export const services: Services = {
	messages: {
		info: (msg: string): void => alertMessage(MessageType.info, msg),
		error: (msg: string): void => alertMessage(MessageType.info, msg),
		success: (msg: string): void => alertMessage(MessageType.info, msg),
	},
	request,
};
