import { Action, Reducer } from 'redux';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import { equal } from '@common/typescript/Utils';

import { AppThunkAction, ApplicationState } from '@app/store';
import { request } from '@app/components/Api';
import { StatusOrder } from '@app/objects/StatusOrder';

type Dispatch = (action: KnownPetStatusTriggerActions) => void;
export interface PetStatusTriggerState<T extends StatusOrder> {
	isLoading: boolean;
	items: List<T>;
	pagination: {
		total: number;
		current: number;
		offset: number;
		pageSize?: number;
	};
	type: string;
	params: BaseParams;
	error: Nullable<string>;
}

const defaultPetStatusTrigger = {
	count: 0,
	execution: 0,
	offset: 0,
	list: [],
};

function getDefaultPetStatusTriggerState<T extends StatusOrder>(): PetStatusTriggerState<T> {
	return {
		isLoading: false,
		items: defaultPetStatusTrigger,
		pagination: {
			total: 0,
			current: 0,
			offset: 0,
			pageSize: 0,
		},
		type: '',
		params: {},
		error: null,
	};
}

export enum TypeKeys {
	REQUESTPETSTATUSTRIGGER = 'REQUESTPETSTATUSTRIGGER',
	RECEIVEPETSTATUSTRIGGER = 'RECEIVEPETSTATUSTRIGGER',
	REQUESTPETSTATUSTRIGGERERROR = 'REQUESTPETSTATUSTRIGGERERROR',
}

interface RequestPetStatusTriggerAction {
	type: TypeKeys.REQUESTPETSTATUSTRIGGER;
}

interface ReceivePetStatusTriggerAction {
	type: TypeKeys.RECEIVEPETSTATUSTRIGGER;
	items: List<StatusOrder>;
}

interface RequestPetStatusTriggerErrorAction {
	type: TypeKeys.REQUESTPETSTATUSTRIGGERERROR;
	error: string;
}

type KnownPetStatusTriggerActions = RequestPetStatusTriggerAction
	| ReceivePetStatusTriggerAction
	| RequestPetStatusTriggerErrorAction

function loadPage(
	dispatch: Dispatch,
	getState: () => ApplicationState,
	params: BaseParams,
) {
	const fetchTask = request<List<StatusOrder>>('statusOrderList', params, getState())
		.then((data: List<StatusOrder>) => {
			dispatch({
				type: TypeKeys.RECEIVEPETSTATUSTRIGGER,
				items: data,
			});

			return data;
		})
		.catch((error: string) => {
			dispatch({
				type: TypeKeys.REQUESTPETSTATUSTRIGGERERROR,
				error,
			});
		});

	dispatch({ type: TypeKeys.REQUESTPETSTATUSTRIGGER });

	return fetchTask;
}

const currentCount = 30;
const baseParams = { count: currentCount, offset: 0 };
export function gePetStatusTriggerActionCreators() {
	return {
		request: (
			params?: BaseParams,
		): AppThunkAction<KnownPetStatusTriggerActions> => (dispatch, getState) => {
			const storeState = getState().statusOrders;

			if (!equal(storeState.params, params)) {
				return loadPage(dispatch, getState, params ?? baseParams);
			}

			return Promise.resolve(storeState.items);
		},
	};
}

export function getPetStatusTriggerReducer<T extends StatusOrder>(): Reducer<PetStatusTriggerState<T>> {
	return (
		state: PetStatusTriggerState<T> | undefined,
		incomingAction: Action,
	) => {
		const result = state ?? getDefaultPetStatusTriggerState();
		const action = incomingAction as KnownPetStatusTriggerActions;

		switch (action.type) {
		case TypeKeys.REQUESTPETSTATUSTRIGGER: {
			return { ...result, isLoading: true };
		}
		case TypeKeys.RECEIVEPETSTATUSTRIGGER: {
			return {
				...result,
				isLoading: false,
				items: action.items as List<T>,
			};
		}
		case TypeKeys.REQUESTPETSTATUSTRIGGERERROR:
			return { ...result, isLoading: false, error: action.error };

		default:
			return result;
		}
	};
}
