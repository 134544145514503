import { ActionCreatorsMapObject, Reducer } from 'redux';

import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store/index';
import { Lang } from '@common/typescript/objects/Lang';

export interface LoginState<TUser extends BaseUser> {
	isLoading: boolean;
	session: string;
	user: TUser | null;
	message: string;
	transmuted: boolean;
	debug: boolean;
	lang: Lang;
	userAgent: string;
}

function getDefaultState<TUser extends BaseUser>(): LoginState<TUser> {
	return {
		isLoading: false,
		session: '',
		user: null,
		message: '',
		transmuted: false,
		debug: false,
		lang: Lang.En,
		userAgent: '',
	};
}

export enum TypeKeys {
	REQUESTLOGIN = 'REQUEST_LOGIN',
	RECEIVELOGIN = 'RECEIVE_LOGIN',
	REQUESTLOGOFF = 'REQUEST_LOGOFF',
	RECEIVELOGOFF = 'RECEIVE_LOGOFF',
	SETSESSION = 'SET_SESSION',
	UPDATEUSER = 'UPDATE_USER',
	CLEARSTATE = 'CLEAR_STATE',
	SETLANG = 'SET_LANG',
}

interface RequestLoginAction {
	type: TypeKeys.REQUESTLOGIN;
}

interface ReceiveLoginAction<TUser extends BaseUser> {
	type: TypeKeys.RECEIVELOGIN;
	user: TUser | null;
	session: string;
	message: string;
	transmuted: boolean;
	debug: boolean;
	lang: Lang;
	userAgent: string;
}

interface RequestLogoffAction {
	type: TypeKeys.REQUESTLOGOFF;
}

interface ReceiveLogoffAction {
	type: TypeKeys.RECEIVELOGOFF;
	session: string;
}

interface SetSessionAction {
	type: TypeKeys.SETSESSION;
	session: string;
}

interface SetLangAction {
	type: TypeKeys.SETLANG;
	lang: Lang;
}

interface UpdateUserAction<TUser extends BaseUser> {
	type: TypeKeys.UPDATEUSER;
	data: Partial<TUser>;
}

interface ClearStateAction {
	type: TypeKeys.CLEARSTATE;
}

export type KnownUserAction<TUser extends BaseUser> =
	RequestLoginAction |
	ReceiveLoginAction<TUser> |
	RequestLogoffAction |
	ReceiveLogoffAction |
	SetSessionAction |
	UpdateUserAction<TUser> |
	ClearStateAction |
	SetLangAction;

export interface LoginActionCreators<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> extends ActionCreatorsMapObject<
	BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>
> {
	login: (login: string, password: string) => BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>;
	logoff: (clearState?: boolean, callback?: () => void) => BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>;
	updateUser: (data: Partial<TUser>) => BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>;
	setUserAndSession: (user: TUser, session: string) => BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>;
	setLang: (lang: Lang) => BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState>;
}

export function getActionCreators<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(): LoginActionCreators<TUser, TApplicationState> {
	return {
		login: (login: string, password: string): BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			if (!getState().login.isLoading) {
				request<any, TUser, TApplicationState>('auth', {
					login,
					password,
					path: '/login',
				}).then((data) => {
					if (data.initObject) {
						dispatch({
							type: TypeKeys.RECEIVELOGIN,
							user: data.initObject.user,
							session: data.initObject.guid,
							message: '',
							transmuted: data.initObject.transmuted,
							debug: data.initObject.debug,
							lang: data.initObject.lang,
							userAgent: data.initObject.userAgent,
						});
					}
				}).catch((data) => {
					dispatch({
						type: TypeKeys.RECEIVELOGIN,
						user: null,
						session: getState().login.session,
						message: data,
						transmuted: false,
						debug: false,
						lang: Lang.En,
						userAgent: '',
					});
				});

				dispatch({ type: TypeKeys.REQUESTLOGIN });
			}
		},
		logoff: (
			clearState?: boolean,
			callback?: () => void,
		): BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState> => (dispatch, getState) => {
			if (!getState().login.isLoading) {
				request<any, TUser, TApplicationState>('logoff', {}).then((data) => {
					if (data.updatedSession) {
						dispatch({ type: TypeKeys.RECEIVELOGOFF, session: data.updatedSession.guid });
					}

					if (callback) callback();

					if (clearState) {
						dispatch({ type: TypeKeys.CLEARSTATE });
					}
				});

				dispatch({ type: TypeKeys.REQUESTLOGOFF });
			}
		},
		updateUser: (data: Partial<TUser>): BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState> => (dispatch) => {
			dispatch({ type: TypeKeys.UPDATEUSER, data });
		},
		setUserAndSession: (user: TUser, session: string): BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState> =>
			(dispatch, getState) => {
				const state = getState().login;
				dispatch({
					type: TypeKeys.RECEIVELOGIN,
					user,
					session,
					message: '',
					transmuted: false,
					debug: state.debug || false,
					lang: state.lang,
					userAgent: state.userAgent,
				});
			},
		setLang: (lang: Lang): BaseAppThunkAction<KnownUserAction<TUser>, TUser, TApplicationState> => (dispatch) => {
			request('language', { lang }).then(() => {
				dispatch({ type: TypeKeys.SETLANG, lang });
			});
		},
	};
}

export function getReducer<TUser extends BaseUser>(): Reducer<LoginState<TUser>, KnownUserAction<TUser>> {
	return (state: LoginState<TUser> = getDefaultState<TUser>(), action: KnownUserAction<TUser>) => {
		switch (action.type) {
		case TypeKeys.REQUESTLOGIN:
		case TypeKeys.REQUESTLOGOFF:
			return {
				...state,
				isLoading: true,
			};

		case TypeKeys.RECEIVELOGIN:
			return {
				...state,
				isLoading: false,
				user: action.user,
				session: action.session,
				message: action.message,
				transmuted: action.transmuted,
				debug: action.debug,
				lang: action.lang,
				userAgent: action.userAgent,
			};

		case TypeKeys.RECEIVELOGOFF:
			return {
				...state,
				isLoading: false,
				user: null,
				session: action.session,
				transmuted: false,
			};

		case TypeKeys.SETSESSION:
			return {
				...state,
				session: action.session,
			};

		case TypeKeys.SETLANG:
			return {
				...state,
				lang: action.lang,
			};

		case TypeKeys.CLEARSTATE:
			return {
				...state,
				user: null,
				isLoading: false,
				message: '',
				session: '',
				transmuted: false,
			};

		case TypeKeys.UPDATEUSER:
			return {
				...state,
				user: {
					...(state.user),
					...action.data,
				} as TUser,
			};

		default:
			return state;
		}
	};
}
