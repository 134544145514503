import * as React from 'react';

interface TrackChangeProps<T> {
	track: T;
	onChange: (value: T) => void;
	ignoreMount?: boolean;
}

export const TrackChange = <
	T,
>(props: TrackChangeProps<T>): React.ReactElement => {
	const count = React.useRef(-1);

	React.useEffect(() => {
		count.current++;
		if (props.ignoreMount && count.current === 0) return;

		props.onChange(props.track);
	}, [props.track]);

	return <></>;
};

(TrackChange as React.FC<TrackChangeProps<unknown>>).defaultProps = {
	ignoreMount: true,
};
