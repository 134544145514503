import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, OptionType } from '@common/react/components/Forms/Autocomplete/Autocomplete';

import { addressService } from '@app/services/AddressService';
import { PostalCode } from '@app/objects/PostalCode';

interface ComponentProps {
	value: string;
	onSelect: (value: string, item?: PostalCode) => void;
	onChange?: (value: string) => void;
	disabled?: boolean;
	country: string;
}

function toOption(item: PostalCode): OptionType {
	return {
		id: item.id,
		value: item.id.toString(10),
		label: item.zip,
	};
}

export const PostalCodeAutocomplete: React.FC<ComponentProps> = (props: ComponentProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'selects.postal-code-autocomplete.placeholder' });
	const details = addressService.provide(props.country);

	return (
		<Autocomplete<PostalCode>
			type="zipCodeList"
			params={{ count: 1000, country: props.country }}

			value={props.value}
			onSelect={props.onSelect}
			onChange={props.onChange}
			toOption={toOption}

			disabled={!details.withMail || props.disabled}
			placeholder={t(details.mailKey)}
		/>
	);
};
