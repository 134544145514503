import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { InventoryItem } from '@app/objects/Inventory';
import { User } from '@app/objects/User';

export interface InventoryAdjustment extends WithId {
	inventoryItem: Nullable<InventoryItem>;
	inventoryItemId: number;
	adjustmentCode: Nullable<IAdjustmentCode>;
	adjustmentCodeId: number;
	time: number;
	user: Nullable<User>;
	userId: number;
	value: number;
	note: string;
}

export enum AdjustmentAction {
	Addition = 0,
	Subtraction = 1,
}

export interface IAdjustmentCode extends WithId {
	name: string;
	description: string;
	crematoryId: number;
	action: AdjustmentAction;
}

export enum InvoiceAdjustmentType {
	Product = 0,
	Service = 1,
}

export interface InvoiceAdjustment extends WithId {
	clientId: string;
	name: string;
	description: string;
	value: number;
	time: Nullable<number>;
	user: Nullable<User>;
	userId: Nullable<number>;
	adjustmentType: InvoiceAdjustmentType;
	deleted: boolean;
}

export interface DefaultAdjustment extends WithId {
	clinicId: number,
	adjustmentType: InvoiceAdjustmentType,
	name: string,
	description: string,
	value: number,
	valueType: AdjustmentValueType,
}

export enum AdjustmentValueType {
	Absolute = 0,
	Relative = 1,
}
