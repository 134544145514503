import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import { Pet } from '@app/objects/Pet';
import { DiffAction, DiffChange, PetDiffs } from '@app/objects/History';
import { renderChanges } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange';

interface PetDiffListProps {
	item: Nullable<PetDiffs>;
}

export const PetDiffList: React.FC<PetDiffListProps> = (props: PetDiffListProps) => {
	if (!props.item) return null;

	let item: React.ReactNode = null;
	const wrapperClassName = 'status-history__item-user history-item__user';
	switch (props.item.diffAction) {
	case DiffAction.Added:
		item = (
			<li
				key={`${props.item.id}-added`}
				className={wrapperClassName}
			>
				<div>
					Pet
				</div>
			</li>
		);
		break;

	case DiffAction.Deleted:
		item = (
			<li
				key={`${props.item.id}-removed`}
				className={wrapperClassName}
			>
				<div>
					Pet
				</div>
			</li>
		);
		break;

	case DiffAction.Updated:
		item = (
			<>
				{
					props.item.changes.map((item: DiffChange<Pet>) => (
						<li
							key={item.diffRecordId}
							className={wrapperClassName}
						>
							<div>
								{renderChanges(item.key, item.before, item.after)}
							</div>
						</li>
					))
				}
			</>
		);
		break;

	default:
		item = (
			<li
				key={`${props.item.id}-unknown`}
				className={wrapperClassName}
			>
				<div>
					Pet
				</div>
			</li>
		);
	}

	return (
		<div className="clearfix list-block" style={{ fontWeight: 600 }}>
			<div className="pull-left">
				<ul className="simple-list">
					{item}
				</ul>
			</div>
			<div className="status-history__item-user history-item__user pull-right">
				{DiffAction[props.item.diffAction]}
			</div>
		</div>
	);
};
