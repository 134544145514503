import { TFunction } from 'react-i18next';

import { WithId } from '@common/typescript/objects/WithId';
import { WithFile } from '@common/typescript/objects/WithFile';

import { CrematoryMachine } from '@app/objects/Crematory';
import { InventoryItem } from '@app/objects/Inventory';
import { User } from '@app/objects/User';
import { BatchInventoryUsage } from '@app/objects/InventoryUsage/BatchInventoryUsage';

export enum BatchActionType {
	Both = 0,
	Communal = 1,
	Private = 2,
	Semiprivate = 3,
}

export interface BatchPet extends WithId {
	name: string;
	actualWeight: number;
	reportedWeight: number;
	position: number | string;
}

export interface BatchChemical extends WithId {
	chemical: InventoryItem | null;
	chemicalId: number;

	suggestedValue: number;
	realValue: number;
}

export interface BatchAction extends WithId {
	creator: User | null;
	creatorId: number;
	createdDate: number;
	creationNotes: string;

	finisher: User | null;
	finisherId: number | null;
	finishedDate: number;
	finishNotes: string;

	machine: CrematoryMachine | null;
	machineId: number;

	totalWeight: number;
	inventoryUsage: Array<BatchInventoryUsage>;

	name?: string;
	actualWeight?: number;
	reportedWeight?: number;
	position?: number | string;

	files: Array<WithFile<BatchAction>>;
	batchActionType: BatchActionType;
}

export function getBatchTypeName(batchType: BatchActionType, t: TFunction): string {
	let type: string = '';

	switch (batchType) {
	case BatchActionType.Both:
		return t('enums.batch-type.both');

	case BatchActionType.Private:
		type = t('enums.batch-type.private');
		break;

	case BatchActionType.Communal:
		type = t('enums.batch-type.communal');
		break;
	case BatchActionType.Semiprivate:
		type = t('enums.batch-type.semiprivate');
		break;

	default:
		return 'Unknown Batch Type';
	}

	return type;
}
