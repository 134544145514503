import * as React from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import { LogoutOutlined } from '@ant-design/icons';
import { bindActionCreators, Dispatch } from 'redux';

import { LoginActionCreators as BaseLoginActionCreators, LoginState as BaseLoginState, getActionCreators } from '@common/react/store/Login';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

interface OwnProps {
	render?: (logout: (event: React.MouseEvent) => void) => JSX.Element;
	clearState?: boolean;
}

interface ReduxProps {
	actions: BaseLoginActionCreators<BaseUser, BaseApplicationState<BaseUser>>;
}

type LoginState = BaseLoginState<BaseUser>;

type LogoutProps =
	LoginState
	& ReduxProps
	& OwnProps;

class LogoutInner extends React.Component<LogoutProps, Record<string, unknown>> {
	constructor(props: LogoutProps) {
		super(props);

		this.logout = this.logout.bind(this);
	}

	shouldComponentUpdate(nextProps: LogoutProps): boolean {
		return nextProps.user !== this.props.user;
	}

	logout(event: React.MouseEvent) {
		event.preventDefault();
		this.props.actions.logoff(this.props.clearState);
	}

	public render() {
		const { render } = this.props;

		return render ? render(this.logout) : (
			<Button
				type="text"
				onClick={this.logout}
				className="logout-component"
				icon={<LogoutOutlined />}
				size="small"
			>
				Logout
			</Button>
		);
	}
}

export const Logout = connect<LoginState, ReduxProps, OwnProps, BaseApplicationState<BaseUser>>(
	(state: BaseApplicationState<BaseUser>) => state.login,
	(dispatch: Dispatch) => ({
		actions: bindActionCreators(getActionCreators(), dispatch),
	}),
)(LogoutInner);
