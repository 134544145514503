import * as React from 'react';
import {
	useDispatch,
} from 'react-redux';

import Pagination from 'antd/lib/pagination';

import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { Nullable } from '@common/typescript/objects/Nullable';

import { usePagination } from '@app/hooks/PaginationManager/PaginationManager';
import { useRemoteData } from '@app/hooks/RemoteDataProvider/RemoteDataProvider';

import { Pet } from '@app/objects/Pet';
import { DiffAction, UpdateTransaction } from '@app/objects/History';

import { formatUserName } from '@app/utilities/functionUtils';
import '@app/scss/ui/lists.scss';

import { bindItemActionCreators } from '@app/store/Item';
import { crematoryStoreName, getCrematoryEndpoint } from '@app/components/Pages/Crematories/CrematoryApi';
import { Crematory, getDefaultCrematory } from '@app/objects/Crematory';
import { PetDiffList } from '@app/components/Pages/PetEditor/OldPetEditorTabs/PetHistoryChangeComponents/PetDiffs';
import { PetPriceDiffList } from '@app/components/Pages/PetEditor/OldPetEditorTabs/PetHistoryChangeComponents/PriceDiffs';
import { PetUrnDiffList } from '@app/components/Pages/PetEditor/OldPetEditorTabs/PetHistoryChangeComponents/UrnDiffs';
import { DateView } from '@app/components/UI/Views/DateFormat/DateView';
import { TimeView } from '@app/components/UI/Views/TimeView/TimeView';

const abortedError = 'signal is aborted without reason';

interface OwnProps {
	pet: Pet;
	pending?: boolean;
	onChange?: (isItem: boolean) => void;
	setError?: (value: string | null) => void;

	fallback?: Nullable<React.ReactElement>
	filter?: Array<DiffAction | undefined>;
}

function isUpdate(item: UpdateTransaction, filter: Array<DiffAction | undefined>): boolean {
	const action = item.petDiff?.diffAction;

	return filter.includes(action);
}

const renderUserName = (item: UpdateTransaction) => formatUserName(item.user);
export const renderChanges = (key: string, initialValue: string, updateValue: string) => `${key}: ${initialValue} => ${updateValue}`;

const getDiffList = (item: UpdateTransaction) => (
	<>
		<PetDiffList item={item.petDiff} />
		<PetPriceDiffList
			list={item.petPriceDiffs}
		/>
		<PetUrnDiffList list={item.petUrnDiffs} />
	</>
);

interface UpdateTransactionListFilters {
	from?: Nullable<number>;
	to?: Nullable<number>;
	petId: number;
	unconfirmedChanges?: boolean;
}

interface UpdateTransactionListMessage {
	withUser?: boolean;
	withPetDiffs?: boolean;
	withPet?: boolean;
	withPetPriceDiffs?: boolean;
	withPetPrice?: boolean;
	withPetUrnDiffs?: boolean;
	withPetUrn?: boolean;
	filters?: Nullable<UpdateTransactionListFilters>;
}

const additionalParams: Omit<UpdateTransactionListMessage, 'filters'> = {
	withUser: true,
	withPetDiffs: true,
	withPetPrice: true,
	withPetPriceDiffs: true,
	withPetUrnDiffs: true,
	withPetUrn: true,
};

const styles = { border: '2px solid #FFD700' };
const defaultItem: Crematory = getDefaultCrematory();

export const OldPetHistoryChange: React.FC<OwnProps> = (props: OwnProps) => {
	const { pet, pending } = props;
	const dispatch = useDispatch();
	const factory = React.useMemo(() => bindItemActionCreators(dispatch), [dispatch]);

	React.useEffect(() => {
		factory.loadItem(crematoryStoreName, getCrematoryEndpoint, pet.crematoryId, defaultItem, {
			id: pet.crematoryId,
		});
	}, [pet.crematoryId]);

	const params: UpdateTransactionListMessage = React.useMemo(() => ({
		...additionalParams,
		filters: {
			petId: pet.id,
			unconfirmedChanges: pending,
		},
	}), [pet, pending]);

	const request = useRemoteData<UpdateTransaction, UpdateTransactionListMessage>({
		endpoint: 'updateTransactionList',
		shouldRefetch: false,
		usePagination: () => usePagination({ perPage: 5 }),
	}, params);

	React.useEffect(() => {
		request.fetch(params);
	}, [params]);

	const items: Array<UpdateTransaction> = React.useMemo(() => {
		if (props.filter) return request.items.filter((item: UpdateTransaction) => isUpdate(item, props.filter!));

		return request.items;
	}, [request.items, props.filter]);

	React.useEffect(() => {
		props.onChange?.(items.length > 0);
	}, [items]);

	React.useEffect(() => {
		props.setError?.(request.error);
	}, [request.error]);

	if (request.loading) return <Loading />;
	if (!request.loading && request.error && request.error !== abortedError) return <AlertMessage message={request.error} />;
	if (!request.items || items.length === 0) return props.fallback ?? null;

	return (
		<>
			<ul className="status-history history-list">
				{items.map((item: UpdateTransaction) => (
					<li
						key={item.id}
						className="status-history__item history-item"
						style={pet.currentStatus?.time && item.time > pet.currentStatus?.time ? styles : undefined}
					>
						<div className="status-history__item-user history-item__user">
							{renderUserName(item)}
						</div>
						<div className="status-history__item-date history-item__date">
							<DateView date={item.time} /> <TimeView time={item.time} />
						</div>
						{getDiffList(item)}
					</li>
				))}
			</ul>
			<Pagination
				total={request.pagination.total}
				hideOnSinglePage
				pageSize={request.pagination.perPage}
				onChange={(value: number) => request.onPageChange(value - 1)}
				current={request.pagination.page + 1}
				style={{ textAlign: 'center' }}
				size="small"
			/>
		</>
	);
};

OldPetHistoryChange.defaultProps = {
	fallback: <div className="text-center"><i>History is empty</i></div>,
};
