import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dropdown from 'antd/lib/dropdown';

import clsx from 'clsx';
import Button from 'antd/lib/button';
import {
	ArrowLeftOutlined,
	BellOutlined,
	DownOutlined,
	UpOutlined,
	UserOutlined,
} from '@ant-design/icons';

import { transmute } from '@common/react/objects/BaseUser';

import { Logout } from '@app/components/UI/Buttons/Logout';

import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';
import { BugReportModal } from '@app/components/UI/Header/BugReportModal';
import { useModal } from '@app/hooks/Editors/useModal';

interface MenuActionDropdownProps {
	transmuted: boolean;
	isMenuOpen: boolean;
	setMenuOpen: (value: boolean) => void;
	className?: string;
}

export const getMenuItems = (
	history,
	transmuted: boolean,
	modalOpen: () => void,
	onChangeModal: (val: boolean) => void,
) => {
	const items = [
		{
			key: 'profile',
			label: (
				<Button
					type="text"
					size="small"
					ghost
					onClick={() => history.replace('/profile')}
					icon={<UserOutlined />}
				>
					Profile
				</Button>
			),
		},
		{
			key: 'report',
			label: (
				<Button
					type="text"
					size="small"
					onClick={() => {
						modalOpen();
						onChangeModal(false);
					}}
					icon={<BellOutlined style={{ color: 'red' }} />}
				>
					Report a problem
				</Button>
			),
		},
		{
			key: 'logout',
			label: (
				<Logout clearState />
			),
		},
	];

	if (transmuted) {
		items.unshift({
			key: 'back',
			label: (
				<Button
					type="text"
					size="small"
					title="Login Back"
					onClick={() => transmute(() => {
						history.push('/user-list');
						window.location.reload();
					})}
					icon={<ArrowLeftOutlined />}
				>
					Back
				</Button>
			),
		});
	}

	return items;
};

export const MenuActionDropdown: React.FC<MenuActionDropdownProps> = ({
	transmuted, isMenuOpen, setMenuOpen, className,
}) => {
	const modal = useModal(false);
	const history = useHistory();
	const userRole = useSelector((state: ApplicationState) => state.login.user?.role);

	return (
		<>
			<Dropdown
				trigger={['click']}
				open={isMenuOpen}
				onOpenChange={setMenuOpen}
				overlayClassName={clsx(
					'site-header__dropdown',
					(userRole === UserRole.ClinicUser || userRole === UserRole.ClinicManager) && 'site-header__dropdown--clinic',
				)}
				menu={{ items: getMenuItems(history, transmuted, modal.open, setMenuOpen) }}
			>
				<Button
					type="text"
					className={className}
					onClick={() => setMenuOpen(!isMenuOpen)}
					icon={isMenuOpen ? <UpOutlined /> : <DownOutlined style={{ fontSize: 16 }} />}
				/>
			</Dropdown>
			<BugReportModal modal={modal} />
		</>
	);
};
