import { ActionCreatorsMapObject, Reducer } from 'redux';

import { WithId } from '@common/typescript/objects/WithId';
import { request } from '@common/react/components/Api';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseParams } from '@common/react/objects/BaseParams';

import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store';

export interface ItemState<T extends WithId> {
	isLoading: boolean;
	id: number | null;
	itemPathOrId: string | number | null;
	item: T | null;
}

const defaultItemState = {
	isLoading: false,
	id: null,
	itemPathOrId: null,
	item: null,
};

export enum TypeKeys {
	REQUESTITEM = 'REQUESTITEM',
	RECEIVEITEM = 'RECEIVEITEM',
	REMOVEITEM = 'REMOVEITEM',
}

interface RequestItemAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.REQUESTITEM;
	storageName: (keyof TApplicationState) | null;
	itemPathOrId: string | number;
}

interface ReceiveItemAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.RECEIVEITEM;
	storageName: (keyof TApplicationState) | null;
	item: T | null;
}

interface RemoveItemAction<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.REMOVEITEM;
	storageName: (keyof TApplicationState) | null;
}

export type KnownPageAction<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> = RequestItemAction<T, TUser, TApplicationState>
	| ReceiveItemAction<T, TUser, TApplicationState>
	| RemoveItemAction<TUser, TApplicationState>;

type MergeFunction<T extends WithId> = (oldItem: T, newItem: Partial<T>) => T;

export interface IActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> extends ActionCreatorsMapObject<BaseAppThunkAction<KnownPageAction<T, TUser, TApplicationState>, TUser, TApplicationState, Promise<T> | void>> {
	loadItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		endpoint: string,
		itemPathOrId: string | number,
		defaultItem: TEntity,
		additionalParams?: BaseParams,
		customCheck?: (storeState: ItemState<TEntity & {_type: string}>) => boolean
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState, Promise<TEntity>>;
	updateItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		data: Partial<TEntity>,
		checkProp?: keyof TEntity,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	mergeItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		data: Partial<TEntity>,
		merge: MergeFunction<TEntity>,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	removeItem: <TEntity extends WithId = T>(type: keyof TApplicationState) =>
		BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
}

export interface IMappedActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> extends ActionCreatorsMapObject<Promise<T> | void> {
	loadItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		endpoint: string,
		itemPathOrId: string | number,
		defaultItem: TEntity,
		additionalParams?: BaseParams,
		customCheck?: (storeState: ItemState<TEntity & {_type: string}>) => boolean
	) => Promise<TEntity>;
	updateItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		data: Partial<TEntity>,
		checkProp?: keyof TEntity,
	) => void;
	mergeItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		data: Partial<TEntity>,
		merge: MergeFunction<TEntity>,
	) => void;
	removeItem: <TEntity extends WithId = T>(type: keyof TApplicationState) => void;
}

// TODO: I think we'd better use StoreName as Generic param for getActionCreators and getReducer; This way we won't need to pass it everytime
//  Also should think of ways to check that state[store] is ItemState<T>;

export function getActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(): IActionCreators<T, TUser, TApplicationState> {
	type ReturnType<
		TEntity extends WithId = T,
		TResult = void,
	> = BaseAppThunkAction<
		KnownPageAction<TEntity, TUser, TApplicationState>,
		TUser,
		TApplicationState,
		TResult
	>;

	// It is easier to read this way rather than when everything is {loadItem: (params): ReturnType => (dispatch, getState) => {functionBody()}}
	// eslint-disable-next-line sonarjs/cognitive-complexity
	function loadItem<TEntity extends WithId = T>(
		store: keyof TApplicationState,
		endpoint: string,
		itemPathOrId: string | number,
		defaultItem: TEntity,
		additionalParams: BaseParams = {},
		customCheck?: (storeState: ItemState<TEntity & {_type: string}>) => boolean,
	): ReturnType<TEntity, Promise<TEntity>> {
		return (dispatch, getState): Promise<TEntity> => {
			// TODO: Not that good. Should think of better solution.
			//  Problem here is we check that store is a field in TApplicationState, but we don't validate that it's of type ItemState<T>
			const storeState = getState()[store] as unknown as ItemState<TEntity & {_type: string}>;
			const isNumber = Number.isFinite(itemPathOrId);

			const conditional = customCheck
				? customCheck(storeState)
				: (isNumber && storeState.id !== +itemPathOrId)
				|| (!isNumber && storeState.itemPathOrId !== itemPathOrId)
				// This shit is not obvious at all. Why should we call Store same way as Type?
				// eslint-disable-next-line no-underscore-dangle
				|| (storeState.item && storeState.item._type && storeState.item._type.toLowerCase() !== (store as string).toLowerCase());

			if (conditional) {
				if (+itemPathOrId > 0 || (!isNumber && itemPathOrId !== '')) {
					const params = isNumber
						? { id: +itemPathOrId, ...additionalParams }
						: { path: itemPathOrId, ...additionalParams };

					// TODO: should use dependency injection and request injected by ClientApp instead of unknown -> (T | null) conversion
					const fetchTask = request(
						endpoint,
						params,
						getState(),
					).then((data: unknown) => {
						const item = data as (TEntity | null);

						dispatch({
							type: TypeKeys.RECEIVEITEM,
							storageName: store,
							item,
						});

						return item ?? defaultItem;
					});

					dispatch({ type: TypeKeys.REQUESTITEM, storageName: store, itemPathOrId });

					return fetchTask;
				}

				dispatch({ type: TypeKeys.RECEIVEITEM, storageName: store, item: defaultItem || {} });

				return Promise.resolve(defaultItem);
			}

			return Promise.resolve(storeState.item as TEntity);
		};
	}

	function updateItem<
		TEntity extends WithId = T
	>(store: keyof TApplicationState, data: Partial<TEntity>, checkProp?: keyof TEntity): ReturnType<TEntity> {
		return (dispatch, getState) => {
			// Same think as above
			const storeState = getState()[store] as unknown as ItemState<TEntity>;
			const item = storeState.item;

			// Can't update item that is null
			if (item === null) return;
			// Do not update if action is intended for other item
			const id = (data as WithId).id;
			if (id && id !== item.id) return;

			if (!checkProp || (checkProp && item && data[checkProp] === item[checkProp])) {
				dispatch({
					type: TypeKeys.RECEIVEITEM,
					storageName: store,
					item: { ...item, ...data },
				});
			}
		};
	}

	function mergeItem<TEntity extends WithId = T>(
		store: keyof TApplicationState,
		data: Partial<TEntity>,
		merge: MergeFunction<TEntity>,
	) {
		return (dispatch, getState) => {
			const state = getState()[store] as unknown as ItemState<TEntity>;
			const item = state.item;

			// Can't merge Partial<T> with null
			if (item === null) return;

			const id = (data as WithId).id;
			if (id && id !== item.id) return;

			dispatch({
				type: TypeKeys.RECEIVEITEM,
				storageName: store,
				item: merge(item, data),
			});
		};
	}

	function removeItem<TEntity extends WithId = T>(store: keyof TApplicationState): ReturnType<TEntity> {
		return (dispatch) => {
			dispatch({ type: TypeKeys.REMOVEITEM, storageName: store });
		};
	}

	return {
		loadItem,
		updateItem,
		mergeItem,
		removeItem,
	};
}

type T = Pick<{a: string, b: string}, 'a'>

export function getReducer<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(storageName: keyof TApplicationState): Reducer<ItemState<T>, KnownPageAction<T, TUser, TApplicationState>> {
	return (state: ItemState<T> = defaultItemState, action: KnownPageAction<T, TUser, TApplicationState>) => {
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
			case TypeKeys.REQUESTITEM:
				return {
					isLoading: true,
					item: state.item,
					id: Number(action.itemPathOrId),
					itemPathOrId: action.itemPathOrId,
				};

			case TypeKeys.RECEIVEITEM:
				return {
					isLoading: false,
					item: action.item,
					id: typeof action.item?.id !== 'undefined' ? action.item.id : state.id,
					itemPathOrId: null,
				};

			case TypeKeys.REMOVEITEM:
				return {
					isLoading: false, item: null, id: null, itemPathOrId: null,
				};

			default:
				return state;
			}
		}

		return state;
	};
}
