import * as React from 'react';

import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import { DefaultOptionType } from 'antd/es/select';
import { LoadingOutlined } from '@ant-design/icons';

import { request } from '@common/react/components/Api';
import { Rows } from '@common/typescript/objects/List';
import { debounce } from '@common/typescript/Utils';
import { WithId } from '@common/typescript/objects/WithId';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { isUndefined } from '@common/react/utils/guards';

export interface OptionType extends DefaultOptionType {
	id: WithId['id'];
}

interface AutocompleteState<T extends WithId> {
	items: Array<T>;
	options: Array<OptionType>;
	value: string;
	isLoading: boolean;
}

export interface AutocompleteProps<T extends WithId> extends Pick<AutoCompleteProps, 'children'>{
	type: string;
	params?: BaseParams;
	paramName?: string;

	value: string;
	onSelect: (value: string, item?: T) => void;
	onChange?: (value: string) => void;

	toOption: (item: T) => OptionType;

	min?: number;
	isClear?: boolean;
	antdProps?: AutoCompleteProps;
	loadOnFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onExtraRender?: (state: AutocompleteState<T>, props: AutocompleteProps<T>) => void;
	loaderMarkup?: React.ReactElement;
	updateAfterSelect?: boolean;
	transformValue?: (value: string) => string;
	shouldSelectMatch?: boolean;
}

export class Autocomplete<T extends WithId> extends React.Component<AutocompleteProps<T>, AutocompleteState<T>> {
	public static defaultProps: Partial<AutocompleteProps<WithId>> = {
		loaderMarkup: <LoadingOutlined />,
		paramName: 'text',
		shouldSelectMatch: true,
	};

	public state: AutocompleteState<T> = {
		items: [],
		options: [],
		isLoading: false,
		value: '',
	};

	constructor(props: AutocompleteProps<T>) {
		super(props);

		this.loadItems = debounce(this.loadItems.bind(this), 300);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.selectMatchingOption = this.selectMatchingOption.bind(this);
	}

	componentDidMount(): void {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	public componentDidUpdate(prevProps: Readonly<AutocompleteProps<T>>) {
		if (this.props.value === prevProps.value) return;

		this.setState({
			value: this.props.value,
		});
	}

	shouldComponentUpdate(nextProps: AutocompleteProps<T>, nextState: AutocompleteState<T>): boolean {
		return nextProps.value !== this.props.value
			|| nextProps.disabled !== this.props.disabled
			|| nextProps.placeholder !== this.props.placeholder
			|| nextState.value !== this.state.value
			|| nextState.options !== this.state.options
			|| nextState.isLoading !== this.state.isLoading;
	}

	onChange = (value: string) => {
		this.setState({ value });

		this.props.onChange?.(value);
	};

	onSelect = (value: string, option: OptionType) => {
		this.props.onSelect?.(value, this.state.items.find((item: T) => item.id === option.id));

		if (this.props.isClear) {
			setTimeout(() => {
				this.setState({
					value: '',
				});
			}, 0);
		} else {
			setTimeout(() => {
				this.setState({
					options: [],
				});
			}, 0);
		}

		if (this.props.updateAfterSelect) {
			setTimeout(() => this.loadItems(this.state.value), 0);
		}
	};

	loadItems(value: string) {
		const {
			type, params, paramName, transformValue, shouldSelectMatch,
		} = this.props;

		this.setState({ isLoading: true });

		const newParams = {
			...params,
			[paramName!]: transformValue ? transformValue(value) : value,
		};

		request<Rows<T>, BaseUser, BaseApplicationState<BaseUser>>(type, newParams)
			.then((response: Rows<T>) => {
				const items = response.list;
				const options = items.map(this.props.toOption);

				this.setState({
					isLoading: false,
					options,
					items,
				});

				if (shouldSelectMatch) {
					this.selectMatchingOption(options, value);
				}
			})
			.catch(() => {
				this.setState({
					isLoading: false,
					options: [],
				});
			});
	}

	selectMatchingOption(items: Array<OptionType>, value: string): void {
		if (items.length !== 1 || !value) return;

		const item: OptionType = this.state.options[0];
		if (item.value === value) {
			this.setState({ value: item.value });
			this.onSelect(item.key, item);
		}
	}

	onSearchHandler(value: string) {
		const { min = 3, loadOnFocus } = this.props;

		if (value.length >= min || loadOnFocus) {
			this.loadItems(value);
		} else {
			this.setState({ options: [] });
		}
	}

	onFocus = () => {
		if (this.state.value !== '') return;

		this.onSearchHandler('');
	};

	render(): React.ReactElement {
		const {
			disabled, onExtraRender, loadOnFocus,
			loaderMarkup, placeholder, antdProps, children,
		} = this.props;

		return (
			<>
				<div className={`autocomplete-component ${disabled ? 'autocomplete-component_disabled' : ''}`}>
					{this.state.isLoading && <div className="autocomplete-component__loader">{loaderMarkup}</div>}
					<AutoComplete<string, OptionType>
						allowClear
						// eslint-disable-next-line
						// @ts-ignore
						options={this.state.options}
						onChange={this.onChange}
						onSelect={this.onSelect}
						onFocus={loadOnFocus ? this.onFocus : undefined}
						onSearch={this.onSearchHandler}

						value={this.state.value}
						disabled={disabled}
						placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
						{...antdProps}
					>
						{children}
					</AutoComplete>
				</div>
				{onExtraRender && onExtraRender(this.state, this.props)}
			</>
		);
	}
}
