import * as React from 'react';
import {Link, LinkProps, withRouter} from 'react-router-dom';

import * as H from 'history';
import {RouteComponentProps} from 'react-router';

type Props = LinkProps & RouteComponentProps<{}>;

const LinkWithPrevLocation: React.FC<Props> = ({to, children, location, match, staticContext, history, ...rest}) => {
	let descriptor : H.LocationDescriptor;
	const state = { prevPath: `${location.pathname}${location.search}${location.hash}`};
	
	if (typeof to === 'string') {
		descriptor = {
			pathname: to, 
			state
		};
	} else {
		descriptor = {
			...to,
			state
		};
	}
	
	return <Link to={descriptor} {...rest}>{children}</Link>;
};

export default withRouter(LinkWithPrevLocation);