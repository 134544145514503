import * as React from 'react';

import { List } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { PaginationDataEnvelope, PaginationProps } from '@app/hooks/PaginationManager/PaginationProps';
import { request } from '@app/components/Api';

export interface RemoteDataConfig<T> {
	endpoint: string;
	shouldRefetch?: boolean | ((oldData?: T, newData?: T) => boolean);

	usePagination: () => PaginationDataEnvelope;
}

export interface RemoteDataEnvelope<T extends WithId, TData = Record<string, unknown>> {
	fetch: (data?: TData | undefined) => void;
	items: Array<T>;
	loading: boolean;
	error: string | null;
	pagination: PaginationProps;
	onPageChange: (page: number) => void;
}

export function useRemoteData<
	TResult extends WithId,
	TData = Record<string, unknown>,
>(config: RemoteDataConfig<TData>, data?: TData): RemoteDataEnvelope<TResult, TData> {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [items, setItems] = React.useState<Array<TResult>>(() => []);
	const [error, setError] = React.useState<string | null>(() => null);
	const { pagination, onPageChange, update } = config.usePagination();
	const controller = React.useRef<Nullable<AbortController>>(null);

	const oldPage = React.useRef<number>(pagination.page);
	const oldData = React.useRef<TData | undefined>(undefined);

	const cancel = () => {
		controller.current?.abort();
	};

	React.useEffect(() => cancel, []);

	const fetch = (requestData: TData | undefined = data) => {
		setLoading(true);
		setError(null);

		cancel();
		const abortController = new AbortController();
		controller.current = abortController;

		request<List<TResult>>(config.endpoint, {
			...requestData,
			count: pagination.perPage,
			offset: pagination.page * pagination.perPage,
		}, undefined, abortController.signal)
			.then((items: List<TResult>) => {
				setItems(items.list);
				update(items);
			})
			.catch((error: string) => {
				if (abortController.signal.aborted) return;

				setError(error);
			})
			.finally(() => {
				if (abortController.signal.aborted) return;

				setLoading(false);
			});
	};

	React.useEffect(() => {
		if (config.shouldRefetch === true || (typeof config.shouldRefetch === 'function' && config.shouldRefetch(oldData.current, data))) {
			fetch();
		} else if (pagination.page !== oldPage.current) {
			oldPage.current = pagination.page;
			fetch();
		}

		oldData.current = data;
	}, [data, pagination.page]);

	return {
		fetch,
		items,
		loading: Boolean(loading),
		error,
		pagination,
		onPageChange: (value: number) => {
			onPageChange(value);
		},
	};
}
