import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Space from 'antd/lib/space';

import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';

interface SectionContainerProps {
	titleLocalizationKey: string;
	children: React.ReactElement | Array<React.ReactElement>;
}

export const SectionContainer: React.FC<SectionContainerProps> = (props: SectionContainerProps) => {
	const { t } = useTranslation();
	const key = iKey(props.titleLocalizationKey);
	const title = t(key);

	return (
		<div className="section-container">
			<div className="section-container__title form-title">
				<h4>{title}</h4>
			</div>
			<Space className="section-container__content" style={{ display: 'flex' }} direction="vertical">
				{props.children}
			</Space>
		</div>
	);
};
