import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import Dropdown from 'antd/lib/dropdown';
import { FilePdfOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import { getDeliveryTypeName, getServiceTypeName } from '@app/objects/Pet';

import { ResourceButton } from '@app/components/UI/Buttons/ResourceButton';
import { PriceTagDisplay } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Displays/PriceTagDisplay';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { PdfField } from '@app/components/Pages/Clinics/ClinicTabs/Invoices/InvoiceEditor/InvoiceEditorComponents/FormFields';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { List } from '@app/components/UI/List/List';
import { RoutePaths } from '@app/utilities/RoutePathVars';

import '@app/scss/ui/blocks/header-affix.scss';

interface PetEditorHeaderProps {
	calculatedPrice: Nullable<IPriceStack>;
	onRecalculate: () => void;
}

const InvoicePrint = ({ petId }) => {
	const { t } = useTranslation();
	const [excludeFree, setExcludeFree] = React.useState<boolean>(true);

	if (petId < 0) return null;

	return (
		<Dropdown.Button
			className="invoice-dropdown--pet"
			menu={{
				items: [
					{
						key: 'excludeFree',
						label: (
							<PdfField
								isChecked={excludeFree}
								onChange={setExcludeFree}
								title="Exclude Free Services"
							/>
						),
					},
				],
			}}
			placement="bottomRight"
		>
			<a
				href={`/generate-pet-receipt?petId=${petId}&excludeFree=${excludeFree}`}
				download
				target="_blank"
				rel="noreferrer"
				style={{ display: 'flex' }}
			>
				<FilePdfOutlined />
				<span className="icon-separator btn--add-with-word">{t(iKey('pet-tag.button-invoice-text'))}</span>
			</a>
		</Dropdown.Button>
	);
};

export const PetEditorHeader: React.FC<PetEditorHeaderProps> = (props: PetEditorHeaderProps) => {
	const { t } = useTranslation();
	const { values } = useFormikContext<PetFormValues>();

	return (
		<List.Header className="header-affix">
			<List.Header.Title backPath={generatePath(RoutePaths.pets, { tab: 'pet-list' })}>
				{values.id > 0
					? `${t(iKey('actions.edit-pet'))} #${values.id}`
					: t(iKey('actions.add-pet'))}
			</List.Header.Title>
			<List.Header.Subtitle>
				{`${values.name}, ${values.gender?.name ?? ' - '}, ${values.petSpecie?.name ?? ' - '}, ${values.petBreed?.name ?? ' - '}, 
				${getServiceTypeName(values.serviceType, t)}, ${getDeliveryTypeName(values.deliveryType)}`}
			</List.Header.Subtitle>
			<List.Header.Actions>
				{
					props.calculatedPrice
						? (
							<PriceTagDisplay
								value={props.calculatedPrice}
								onRecalculate={props.onRecalculate}
								formValues={values}
							/>
						) : null
				}

				<InvoicePrint petId={values.id} />
				{values.id > 0 && (
					<ResourceButton
						type="primary"
						danger
						size="middle"
						url={`/get-pet-tag?petId=${values.id}`}
						icon={<FilePdfOutlined />}
					>
						{t(iKey('pet-tag.button-text'))}
					</ResourceButton>
				)}
			</List.Header.Actions>
		</List.Header>
	);
};
