import { UIEvent } from 'react';

import dummyAvatar from '@common/react/images/avatar.jpg';

interface WithAvatar {
	avatar: string;
}

export function dateToLocaleCustom(t?: number, format: string = 'MM/dd/yyyy'):string {
	if (!t) return '';

	const date = new Date(t);
	const year = date.getFullYear();
	const month = (`0${date.getMonth() + 1}`).slice(-2);
	const day = (`0${date.getDate()}`).slice(-2);

	return format.replace('MM', month)
		.replace('dd', day)
		.replace('yyyy', year.toFixed());
}

export function dateTimeToLocaleCustom(t?: number):string {
	if (!t) return '';

	return `${dateToLocaleCustom(t)} ${new Date(t).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
}

export function dateToStringCustom(t?: number):string {
	if (!t) return '';

	return dateToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
}

export function dateTimeToStringCustom(t?: number):string {
	if (!t) return '';

	return dateTimeToLocaleCustom(t + (new Date(t)).getTimezoneOffset() * 60 * 1000);
}

export const getAvatar: (user: WithAvatar | null, dummyImage?: string) => string = (user, dummyImage) => {
	return `${ (user && user.avatar) ? user.avatar : dummyImage || dummyAvatar}`;
};

export function enumNameToTitle(name: string):string {
	return name.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
}

// [Obsolete] This was required for antd <= 3.11.x - see antd faq for getPopupContainer Selects
export const getPopupContainer = (triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement;

/**
 * A factory for scroll to bottom event handler
 * This factory is supposed to generate handlers that fetch more items when user scrolls to bottom of the list
 * @param {() => void} fetchMore - a function to fetch more items to list
 * @param {boolean} shouldFetch - whether to enable load more or not
 *
 * @returns {(event: React.UIEvent<HTMLElement>) => void} Event handler that fetches more items when user scrolls to bottom
 */
export const getLoadableScrollHandler = (fetchMore: () => void, shouldFetch: boolean) => {
	const refetchHeight = 0.85;

	return (event: UIEvent<HTMLElement>) => {
		const {
			scrollHeight,
			scrollTop,
			clientHeight,
		} = event.currentTarget.children[0];

		if (shouldFetch && ((scrollTop + clientHeight) / scrollHeight >= refetchHeight)) fetchMore();
	};
};

export const replaceSearchUrl = (search: string, key: string, value: string, removeEmpty = true): string => {
	const remove = removeEmpty && ['', 'null', 'undefined', '-1', '[]'].indexOf(value) !== -1;

	if (search.indexOf(`${key}=`) !== -1) {
		return search.replace(new RegExp(`(&)*(${key}=)[^\\&]*(&)*`), (a, b, c, d) => {
			return remove ? (b && d ? '&' : '') : `${(b ? b : '')}${key}=${value}${(d ? d : '')}`;
		});
	}

	return `${search ? `${search}` : ''}${remove ? '' : `${(search.indexOf('?') === -1 ? '?' : (search.slice(-1) === '?' ? '' : '&'))}${key}=${value}`}`;
};
