import { Nullable } from '@common/typescript/objects/Nullable';

import { Pet, PetPrice } from '@app/objects/Pet';

export interface FormValues {
	services: Array<PetPrice>;
}

export interface ExtraProps {
	id: number;
	completedCount: number;
}

export interface ExtraArray {
	changed: Array<ExtraProps>;
	unchanged: Array<PetPrice>;
}

export const getNewServicesArray = (services: Array<PetPrice>, values: FormValues): ExtraArray => {
	const changedArray = values.services.map((item: PetPrice) => ({ id: item.id, completedCount: item.completedCount, nodeId: item.nodeId }));
	const changed: Array<ExtraProps> = [];
	const unchanged: Array<PetPrice> = [];

	services.forEach((item: PetPrice) => {
		const changedItem = changedArray.find((q) => q.id === item.id);
		if (changedItem.completedCount !== item.completedCount) {
			changed.push(changedItem);
		} else {
			unchanged.push(item);
		}
	});

	return { changed, unchanged };
};

export function getCurrentServices(pet: Pet): Array<PetPrice> {
	const status = pet.currentStatus;
	const services = pet.services?.filter((service: PetPrice) => service.price?.specialServiceId) || [];
	const currentIds = status?.specialServicesIds ?? [];

	return currentIds.map((id: number) => services.find((service: PetPrice) => service.price?.specialServiceId === id))
		.filter((service: Nullable<PetPrice>) => Boolean(service));
}

export function restorePrices(original: Array<PetPrice>, updated: Array<PetPrice>): Array<PetPrice> {
	updated.forEach((q: PetPrice) => {
		const value = q;
		const item = original.find((i: PetPrice) => i.id === q.id);
		value.price = item.price;
	});

	return updated;
}
