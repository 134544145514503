import * as React from 'react';
import clsx from 'clsx';

import { stringToColor } from '@common/react/utils/color';
import noImage from '@common/react/images/no_image.jpg';

import '@common/react/scss/components/image.scss';

interface OwnProps {
	src: string;
	height?: number | string;
	width?: number | string;
	alt?: string;
	title?: string;
	fallback?: string;
	className?: string;
}

function getAcronym(value: string) {
	const result: Array<string> = [];

	value.split(' ').forEach((item, index) => {
		if (index < 3 && item) result.push(item[0]);
	});

	return result.join('');
}

export const ImageComponent: React.FC<OwnProps> = (props: OwnProps) => {
	const {
		src, alt, title, fallback, className, height, width,
	} = props;
	const [path, setPath] = React.useState<string>(src);
	const [fallbackName, setFallbackName] = React.useState<string | undefined>(undefined);

	const onError = React.useCallback(() => {
		const name = alt ?? title;

		if (path !== fallback && fallback) {
			setPath(fallback);
		} else if (name) {
			setFallbackName(getAcronym(name));
		} else {
			setPath(noImage);
		}
	}, [fallback, alt, title]);

	React.useEffect(() => {
		if (src) {
			setPath(src);

			if (fallbackName) setFallbackName(undefined);
		} else { onError(); }
	}, [src]);

	React.useEffect(() => {
		const name = alt ?? title;

		if (fallbackName && name) setFallbackName(getAcronym(name));
	}, [alt, title]);

	if (fallbackName) {
		return (
			<div
				className={clsx('string-fallback', className)}
				title={title}
				style={{
					width,
					height,
					backgroundColor: stringToColor(fallbackName),
				}}
			>
				<span>{fallbackName}</span>
			</div>
		);
	}

	return (
		<img
			className={className}
			src={path}
			alt={alt}
			title={title}
			onErrorCapture={onError}
			height={height}
			width={width}
		/>
	);
};
