import * as React from 'react';

import {HandleChangeEventElement} from '@common/react/components/Pages/ItemsPage';

interface ComponentProps {
	onChange?: (evt: HandleChangeEventElement) => void;
	onSubmit?: (evt: HandleChangeEventElement) => void;
	name: string;
	className?: string;
	placeholder?: string;
	withoutForm?: boolean;
	buttonType?: 'submit' | 'button' | 'reset';
	submitOnClear?: boolean;
	containerClassName?: string;
	defaultValue?: string;
}

interface ComponentState {
	value: string;
}

export default class SimpleSearchInput extends React.Component<ComponentProps, ComponentState> {
	private buttonRef = React.createRef<HTMLButtonElement>();
	
	public static defaultProps: Partial<ComponentProps> = {
		className: 'form-control',
		placeholder: 'Search',
		withoutForm: false,
		buttonType: 'submit',
		submitOnClear: true
	};

	constructor(props: ComponentProps) {
		super(props);
		
		this.state = {
			value: this.props.defaultValue || ''
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.clearField = this.clearField.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onClick = this.onClick.bind(this);
	}
	
	shouldComponentUpdate(nextProps: ComponentProps, nextState: ComponentState) {
		return nextState.value !== this.state.value;
	}
	
	handleChange(evt: React.FormEvent<HTMLInputElement>): void {
		this.setState({
			value: evt.currentTarget.value
		});

		this.props.onChange && this.props.onChange(evt);
	}
	
	onKeyPress(evt: React.KeyboardEvent<HTMLInputElement>): void {
		if (evt && evt.key === 'Enter') {
			this.props.onSubmit && this.props.onSubmit({
				currentTarget: {
					name: this.props.name,
					value: this.state.value
				}
			});
		}
	}
	
	onClick(): void {
		this.props.onSubmit && this.props.onSubmit({
			currentTarget: {
				name: this.props.name,
				value: this.state.value
			}
		});
	}
	
	clearField(): void {
		const fakeTarget = {
			currentTarget: {
				name: this.props.name,
				value: ''
			}
		};
		
		this.setState({
			value: ''
		});

		this.props.onChange && this.props.onChange(fakeTarget);
		
		if (this.props.submitOnClear) {
			if (this.props.onSubmit && this.props.withoutForm) {
				this.props.onSubmit(fakeTarget);
			} else if (this.buttonRef.current) {
				this.buttonRef.current.click();
			}
		}
	}
	
	render() {
		const {withoutForm, className, placeholder, name, buttonType, containerClassName = ''} = this.props;
		const value = this.state.value;
		
		return <div className={`simple-search-component input-group ${containerClassName}`}>
			<input 
				type="text"
				className={className}
				placeholder={placeholder}
				name={name}
				onChange={this.handleChange}
				value={value}
				onKeyPress={withoutForm ? this.onKeyPress : undefined}
			/>
			{value !== '' && <i className="fa fa-times simple-search-component__clear" onClick={this.clearField}/>}
			<div className="input-group-btn">
				<button 
					type={buttonType} 
					onClick={withoutForm ? this.onClick : undefined} 
					className="btn btn-sm btn-primary"
					ref={this.buttonRef}
				>
					<i className="fa fa-search"/>
				</button>
			</div>
		</div>;
	}
}