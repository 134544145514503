import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'antd/lib/select';
import { StarFilled, StarTwoTone } from '@ant-design/icons';
import clsx from 'clsx';

import { ErrorMessageComponent } from '@common/react/components/Messages/MessageComponent';
import { List } from '@common/typescript/objects/List';

import { DataProvider, LoadedData } from '@app/components/Utils/DataProvider';
import { useRequest } from '@app/hooks/useRequest';
import { PetSpecie } from '@app/objects/Pet';

import '@app/scss/ui/select-filter.scss';

interface OwnProps {
	crematoryId?: number;

	onChange: (id: number) => void;
	value: number | undefined;
	withFieldName?: boolean
	className?: string;
	onDeselect?: (fieldName: string, type: string, id?: number) => void;
	selectClassName?: (value: Array<number> | number | undefined, length: number) => string;
}

function getFavs(list: Array<PetSpecie>, query: string): Array<PetSpecie> {
	const text = query.toLowerCase();
	if (query) {
		return list.sort((a, b) => {
			const aStarts = Number(a.name.toLowerCase().startsWith(text));
			const bStarts = Number(b.name.toLowerCase().startsWith(text));

			// If they both start OR neither starts with query
			if (aStarts + bStarts !== 1) return a.name > b.name ? 1 : -1;

			return bStarts - aStarts;
		});
	}

	return list.sort((a, b) => (a.name > b.name ? 1 : -1));
}

const localeKey = 'pet-specie';
export const PetSpecieSelect = (props: OwnProps): JSX.Element => {
	const {
		onChange, value, withFieldName, className, onDeselect, selectClassName,
	} = props;
	const { t } = useTranslation('translation', { keyPrefix: 'filters' });
	const [query, setQuery] = React.useState<string>('');
	const [favItems, setFavItems] = React.useState<Array<PetSpecie>>([]);

	const params = React.useMemo(() => ({
		crematoryId: props.crematoryId,
		search: query,
	}), [query, props.crematoryId]);

	const favourites = useRequest<List<PetSpecie>>('favouritePetSpeciesList');
	const addFavourites = useRequest('addFavouritePetSpecies', {}, { requestOnMount: false });
	const deleteFavourites = useRequest('deleteFavouritePetSpecies', {}, { requestOnMount: false });

	React.useEffect(() => setFavItems(favourites.item ? [...favourites.item.list] : []), [favourites.item]);

	return (
		<div className={className}>
			{withFieldName && <span className="field-name">{t(localeKey)}</span>}
			<span className="ant-select-selection__placeholder">{t(localeKey)}</span>
			<DataProvider endpoint="petSpecieList" filterParams={params}>
				{
					(data: LoadedData<PetSpecie>) => (
						<ErrorMessageComponent message={data.error || favourites.error || addFavourites.error || deleteFavourites.error}>
							<Select
								className={clsx('pet-specie-fav_select', selectClassName && selectClassName(value, query.length))}
								showSearch
								showArrow
								showAction={['focus', 'click']}
								placeholder={t(localeKey)}
								optionFilterProp="children"
								filterOption={() => true}
								onSearch={(query: string) => setQuery(query)}
								onChange={(id: number) => onChange(id)}
								onSelect={() => setQuery('')}
								onBlur={() => setQuery('')}
								value={value === -1 ? undefined : value}
								loading={data.isLoading || favourites.loading || addFavourites.loading || deleteFavourites.loading}
								onDeselect={(value: number) => onDeselect && onDeselect('petBreed', 'id', value)}
							>
								{
									favItems.length
									&& (
										<Select.OptGroup label="Favourites">
											{
												getFavs(favItems, query)
													.map((item: PetSpecie) => (
														<Select.Option key={item.id} value={item.id} title={item.name} className="hover-container">
															<StarFilled
																className="hover-item"
																style={{ color: '#ff9f1a' }}
																onClick={(event: React.MouseEvent<HTMLElement>) => {
																	event.stopPropagation();
																	deleteFavourites.reload({ petSpeciesIds: [item.id] });
																	setFavItems(favItems.filter((q: PetSpecie) => q.id !== item.id));
																}}
															/>
															<span className="icon-margin">{item.name}</span>
														</Select.Option>
													))
											}
										</Select.OptGroup>
									)
								}
								<Select.OptGroup label="Species">
									{
										data.items.list && data.items.list
											.filter((item: PetSpecie) => favItems.findIndex((q: PetSpecie) => q.id === item.id) === -1)
											.map((item: PetSpecie) => (
												<Select.Option key={item.id} value={item.id} title={item.name} className="hover-container">
													{
														favItems.length < 5
														&& (
															<StarTwoTone
																className="hover-item"
																twoToneColor="#ff9f1a"
																onClick={(event: React.MouseEvent<HTMLElement>) => {
																	event.stopPropagation();
																	addFavourites.reload({ petSpeciesIds: [item.id] });
																	setFavItems([...favItems, item]);
																}}
															/>
														)
													}
													<span className="icon-margin">{item.name}</span>
												</Select.Option>
											))
									}
								</Select.OptGroup>
							</Select>
						</ErrorMessageComponent>
					)
				}
			</DataProvider>
		</div>
	);
};
