import { WithId } from '@common/typescript/objects/WithId';

export enum EmailType {
	Clinic = 1,
	Coordinate = 2
}

export enum MailStatus {
	Unknown = 0,
	Success = 1,
	Fail = 2,
}

export interface PetEmailLogView extends WithId {
	dateTime: number,
	status: MailStatus,
	type: EmailType,
}
