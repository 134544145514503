import { createContext } from 'react';

interface NotificationMessage {
	error: (msg: string) => void;
	success: (msg: string) => void;
	info: (msg: string) => void;
}

export interface Services {
	request: <T>(endpoint: string, data?: any) => Promise<T>;
	messages: NotificationMessage;
}

function fail(msg: string) {
	throw msg;
}

const CommonContext = createContext<Services>({
	messages: {
		error: (msg: string) => fail(`No error function; Params: ${msg}`),
		info: (msg: string) => fail(`No info function; Params: ${msg}`),
		success: (msg: string) => fail(`No success function; Params: ${msg}`),
	},
	request: (endpoint: string, data?: any) => {
		fail(`No request function; Params: ${endpoint}; ${data}`);

		return Promise.reject('Mock request function');
	},
});

export default CommonContext;
