import * as React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ClockCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { PetStatusButton, PromoteDirection } from '@app/components/UI/Status/PetStatusButton';
import { DateView } from '@app/components/UI/Views/DateFormat/DateView';
import { TimeView } from '@app/components/UI/Views/TimeView/TimeView';

interface PetInfo extends WithId {
	id: number;

	status: string;
	date?: Nullable<number>;
}

interface StatusComponentProps {
	pet: PetInfo;
	onClick: (direction: PromoteDirection) => void;
	loading?: PromoteDirection;

	withControls?: boolean;
	withNext?: boolean;
	withPrevious?: boolean;

	className?: string;
	style?: React.CSSProperties;
	disabled?: boolean;

	action?: React.ReactNode;
}

interface LabelTextProps {
	status: string;
	date?: Nullable<number>;

	action?: React.ReactNode;
}

interface StatusButtonProps {
	onClick: (direction: PromoteDirection) => void;
	loading?: PromoteDirection;
	disabled?: boolean;
	translator: TFunction;
}

const LabelText: React.FC<LabelTextProps> = (props: LabelTextProps) => (
	<div className="status-label__text">
		<div className="status-label__text-inner">{props.status} {props.action}</div>
		{
			props.date
				? (
					<div className="status-label__time">
						<ClockCircleOutlined />
						<DateView date={props.date} /> <TimeView time={props.date} />
					</div>
				) : null
		}
	</div>
);

const NextStatusButton: React.FC<StatusButtonProps> = (props: StatusButtonProps) => (
	<PetStatusButton
		direction={PromoteDirection.PD_NEXT}
		onClick={(direction) => props.onClick(direction)}
		loading={props.loading === PromoteDirection.PD_NEXT}
		disabled={props.disabled || props.loading === PromoteDirection.PD_PREVIOUS}
	>
		<span>
			<span className="status-label__button-right">{props.translator('next')}</span>
			<RightOutlined style={{ fontSize: '12px' }} />
		</span>
	</PetStatusButton>
);

const PreviousStatusButton: React.FC<StatusButtonProps> = (props: StatusButtonProps) => (
	<PetStatusButton
		direction={PromoteDirection.PD_PREVIOUS}
		onClick={(direction) => props.onClick(direction)}
		loading={props.loading === PromoteDirection.PD_PREVIOUS}
		disabled={props.disabled || props.loading === PromoteDirection.PD_NEXT}
	>
		<span>
			<LeftOutlined style={{ fontSize: '12px' }} />
			<span className="status-label__button-left">{props.translator('previous')}</span>
		</span>
	</PetStatusButton>
);

export const StatusComponent: React.FC<StatusComponentProps> = (props: StatusComponentProps): React.ReactElement => {
	const { t } = useTranslation('translation', { keyPrefix: 'control' });

	return (
		<div className={clsx('status-label', props.className)} style={props.style}>
			<LabelText
				status={props.pet.status}
				date={props.pet.date}
				action={props.action}
			/>
			{
				props.withControls ? (
					<div className="status-label__buttons">
						{
							props.withPrevious
								? (
									<PreviousStatusButton
										onClick={(direction) => props.onClick(direction)}
										disabled={props.disabled}
										loading={props.loading}
										translator={t}
									/>
								) : null
						}
						{
							props.withNext
								? (
									<NextStatusButton
										onClick={(direction) => props.onClick(direction)}
										disabled={props.disabled}
										loading={props.loading}
										translator={t}
									/>
								) : null
						}
					</div>

				) : null
			}
		</div>
	);
};
